import { isPlatformBrowser, NgIf, NgFor } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'sostereo-services';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { PlayerService } from 'src/app/angular/shared/services/player.service';
import { NgxSliderModule } from 'ngx-slider-v2';
import { FormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';

@Component({
  selector: 'app-settings-sidebar',
  templateUrl: './settings-sidebar.component.html',
  styleUrls: ['./settings-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UiSwitchModule, FormsModule, NgFor, NgxSliderModule],
})
export class SettingsSidebarComponent implements OnInit, OnDestroy {
  @Input() public settingList: any[];
  @Input() public isSearching: boolean;
  @Output() public sendSearchEvent = new EventEmitter<void>();
  public playerAutoplay = true;
  public playerTierColumn = false;
  public tagReport: boolean = this.commonService.tagReport.getValue();
  public searchScore: boolean = this.commonService.searchScore.getValue();
  public showSysAdmin: boolean = false;
  private searchProfile$: Subscription;
  isBrowser: boolean;

  constructor(
    public commonService: CommonService,
    public authenticationService: AuthenticationService,
    private router: Router,
    private playerService: PlayerService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.getUserSettings();
  }

  ngOnInit(): void {
    this.setValueByProfile();
    this.searchProfile$ = this.playerService.searchProfile.subscribe((profile) => {
      this.setValueByProfile(profile);
    });
  }

  private getUserSettings() {
    const sessionSettings = this.authenticationService.getUserSessionSettings();
    if (
      sessionSettings &&
      sessionSettings.hasOwnProperty('playerWidgetConfig') &&
      sessionSettings.playerWidgetConfig.hasOwnProperty('autopause')
    ) {
      this.playerAutoplay = !sessionSettings.playerWidgetConfig.autopause;
    }
    if (sessionSettings && sessionSettings.hasOwnProperty('playerListConfig')) {
      this.playerTierColumn = !sessionSettings.playerListConfig.hideTierColum;
    }
  }

  setValueByProfile(profileSelected?: any) {
    const profile = profileSelected || this.playerService.searchProfile.getValue();
    const slugs = Object.keys(profile?.configuration || {});
    if (slugs.length > 0 || profile?.label === 'Relevance') {
      this.settingList?.forEach((settingSet) => {
        settingSet.items.forEach((setting) => {
          if (slugs.includes(setting.slug)) {
            setting.value = profile.configuration[setting.slug];
          } else {
            setting.value = setting.defaultValue;
          }
        });
      });
      this.changeDetectorRef.detectChanges();
    }
  }

  public setSettingUser(value: boolean, type: 'playerWidgetConfig' | 'playerListConfig') {
    let sessionSettings = this.authenticationService.getUserSessionSettings();
    const element = type === 'playerWidgetConfig' ? 'autopause' : 'hideTierColum';
    if (sessionSettings) {
      if (!sessionSettings[type]) {
        sessionSettings[type] = {};
      }
    } else {
      sessionSettings = {};
      sessionSettings[type] = {};
    }
    sessionSettings[type][element] = !value;
    this.authenticationService.setUserSessionSettings(JSON.stringify(sessionSettings));
  }

  public sendSearch(changeProfile = true) {
    if (this.isSearching && changeProfile) {
      this.setProfile();
    }
    this.sendSearchEvent.emit();
  }

  public multipleInputs(settingSet: any, setting: any) {
    settingSet.items
      ?.filter((settingItem) => settingItem.slug === setting.slug && settingItem.id !== setting.id)
      .forEach((settingItem) => (settingItem.value = settingItem.defaultValue));

    this.sendSearch();
  }

  private getTagMatchOption() {
    return (this.settingList.find((s) => s.category === 'Random').items as any).find(
      (i) => i.slug === 'minMatch',
    );
  }

  public onTagMatchSliderChange() {
    const minMatchItem = this.getTagMatchOption();
    minMatchItem.userInput = true;
    this.sendSearch(false);
  }

  public showCategory(settingSet: any) {
    return settingSet?.items.some((setting) => this.showSysAdminOptions(setting));
  }

  optionWithAction(value: boolean, option) {
    if (option === 'tagReport') {
      this.commonService.tagReport.next(value);
      this.tagReport = value;
    } else if (option === 'searchScore') {
      this.commonService.searchScore.next(value);
      this.searchScore = value;
    }
    this.router.navigate([], { queryParamsHandling: 'merge' });
  }

  public showSysAdminOptions(setting: any) {
    if (setting.hasOwnProperty('sysAdmin') && setting.sysAdmin) {
      return this.showSysAdmin;
    }
    if (setting.hasOwnProperty('sysAdmin') && !setting.sysAdmin && this.showSysAdmin) {
      return false;
    }
    if (setting.allowedScopes) {
      return this.commonService.isAllowed(setting.allowedScopes);
    }
    return true;
  }

  gatAdvancedValues() {
    return this.settingList?.reduce((values, settingSet) => {
      const items = settingSet.items.filter((setting) => setting.value !== setting.defaultValue);
      return {
        ...values,
        ...items.reduce((category, item) => ({ ...values, [item.slug]: item.value }), {}),
      };
    }, {});
  }

  public setProfile() {
    let settings = this.authenticationService.getUserSessionSettings();
    settings = {
      ...(settings || {}),
      profile: {
        label: 'Advanced',
        value: 'advanced',
        show: this.commonService.isAllowed(['ListTiers']),
        configuration: this.gatAdvancedValues(),
      },
    };
    this.authenticationService.setUserSessionSettings(JSON.stringify(settings));
  }

  ngOnDestroy(): void {
    this.searchProfile$.unsubscribe();
  }
}
