<ng-template #catalogOwnerCommunicationModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div
      id="catalogOwnerCommunicationModal"
      class="modal-body sos-generic-modal-container sos-scrollbar-none"
    >
      <div class="w-full">
        <div class="clearfix mb-5">
          <h3>New artist / Catalog owner communication</h3>
          <div *ngIf="!noInquiries">
            <h6 class="mt-8 mb-2">Inquiry Type</h6>
            <select
              [(ngModel)]="inquiryType"
              (change)="onSelectChange()"
              (click)="getCurrentInquiryIdx(inquiryType)"
            >
              <ng-container *ngFor="let option of inquiriesTypes">
                <option [ngValue]="option" *ngIf="option?.visible">
                  {{ option.name }}
                </option>
              </ng-container>
            </select>
          </div>
          <div *ngIf="noInquiries">
            You've already sent all the inquiries related to this playlist's songs
          </div>
        </div>
      </div>
      <div *ngIf="inquiryType?.name">
        <p
          *ngIf="targetType === 'songs' && !playlistData?._id"
          class="target-type-warning-message"
          style="font-weight: 200"
        >
          <span
            class="fa fa-exclamation-triangle"
            style="color: var(--primary); font-size: 15px"
          ></span>
          This inquiry will be sent for this song individually. If you want to do it from a
          playlist, go to Playlist view > Options > Inquiry types.
        </p>
        <div class="mt-5">
          <p *ngIf="!uniqueSong.name && !uniqueSong.fileName">
            The {{ inquiryType?.name }} inquiry will be sent to all the songs in the playlist
          </p>
          <p *ngIf="uniqueSong.name || uniqueSong.fileName">
            The {{ inquiryType?.name }} inquiry will be sent to the contacts in the
            <span class="font-semibold">
              {{ uniqueSong.name || uniqueSong.fileName }}
              {{
                uniqueSong.version !== 'instrumental' && uniqueSong.version !== 'original'
                  ? '(' + uniqueSong.version + ')'
                  : ''
              }}
              {{ uniqueSong.hasOwnProperty('vocals') && !uniqueSong.vocals ? '(Inst)' : '' }}</span
            >
            song
          </p>
        </div>
        <h3 class="mt-5 mb-2">Details</h3>
        <form *ngIf="!loading" [formGroup]="inquiryForm" (ngSubmit)="onSubmit()">
          <div *ngFor="let option of inquiryType.fields">
            <ng-container>
              <div class="form-group">
                <label
                  for="{{ option.name }}"
                  style="display: block"
                  class="block"
                  [ngClass]="{ 'mb-2 text-2xl lg:text-xl': option.name === 'Project Details' }"
                  >{{ option.name }}{{ option.required ? '*' : '' }}</label
                >
                <div *ngIf="option.type === 'array'">
                  <div
                    *ngFor="let arrayItem of option.options"
                    class="form-group"
                    [ngClass]="{ 'format-currency-input': arrayItem.isCurrency }"
                  >
                    <ng-container>
                      <label *ngIf="arrayItem.isExclusivity"> Exclusivity </label>
                      <select
                        *ngIf="arrayItem.isExclusivity"
                        (change)="onExclusivitySelect($event)"
                        class="w-full"
                      >
                        <option value="yes">Yes</option>
                        <option selected value="no">No</option>
                      </select>
                      <label
                        *ngIf="arrayItem.show || (arrayItem.isExclusivity && arrayItem.show)"
                        [ngStyle]="{ 'margin-top': arrayItem.isExclusivity && '20px' }"
                        class="flex items-center"
                      >
                        {{ arrayItem.name }}{{ arrayItem.required ? '*' : '' }}
                        @if (arrayItem.tooltip) {
                        <span
                          class="material-icons text-dark !text-[15px] ml-[5px]"
                          [attr.data-icon]="'info'"
                          [matTooltip]="arrayItem.tooltip"
                          matTooltipClass="bg-tooltip"
                          [matTooltipPosition]="'right'"
                        ></span>
                        }
                      </label>
                      <div [ngClass]="{ 'currency-row': arrayItem.isCurrency }">
                        <div
                          *ngIf="arrayItem.isCurrency"
                          class="flex items-center justify-center currency-box"
                        >
                          {{ selectedCurrency.symbol }}
                        </div>
                        <input
                          *ngIf="arrayItem.type === 'input' && arrayItem.show"
                          class="form-control sos-standard-input"
                          [ngClass]="{ 'sos-total-input': arrayItem.isTotal }"
                          formControlName="{{ arrayItem.slug }}"
                          name="{{ arrayItem.slug }}"
                          (keydown)="
                            arrayItem.isNumber ? isNumberValidation($event) : '';
                            arrayItem.isCurrency ? preventNonNumericCharacters($event) : '';
                            arrayItem.slug === 'commissionPercentage'
                              ? preventDotOrComma($event)
                              : ''
                          "
                          type="{{
                            arrayItem.isDate ? 'date' : arrayItem.isNumber ? 'number' : 'text'
                          }}"
                          step="{{ arrayItem.isNumber ? '0.1' : '1' }}"
                          [(ngModel)]="arrayItem.value"
                          (input)="
                            arrayItem.isCurrency && formatCurrency($event);
                            arrayItem.slug === 'commissionPercentage' && restrictMaxValue($event)
                          "
                          (change)="
                            (inquiryType.slug === 'exclusivity' &&
                              arrayItem.slug === 'exclusivity') ||
                            (inquiryType.slug === 'placement' &&
                              ['brand', 'company', 'networkOrStudio', 'refNumber'].includes(
                                arrayItem.slug
                              ))
                              ? messageUpdate(
                                  inquiryType.slug,
                                  inquiryType.slug === 'placement' ? arrayItem.slug : null
                                )
                              : ''
                          "
                          (wheel)="arrayItem.isNumber ? disableScroll($event) : ''"
                          min="{{ arrayItem.isNumber && '0' }}"
                        />
                        <select *ngIf="arrayItem.isTotal" (change)="onCurrencyChange($event)">
                          <option
                            value="{{ currency.slug }}"
                            *ngFor="let currency of currencyArray"
                          >
                            {{ currency.slug }}
                          </option>
                        </select>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <input
                  *ngIf="option.type === 'input'"
                  class="form-control sos-standard-input"
                  formControlName="{{ option.slug }}"
                  name="{{ option.slug }}"
                  type="text"
                  [(ngModel)]="option.value"
                />
                <input
                  *ngIf="option.type === 'date'"
                  class="form-control sos-standard-input"
                  formControlName="{{ option.slug }}"
                  name="{{ option.slug }}"
                  type="datetime-local"
                  min="{{ option.slug === 'deadline' ? deadlineMin : '' }}"
                  [(ngModel)]="option.value"
                />
                <input
                  *ngIf="option.type === 'number'"
                  class="form-control sos-standard-input"
                  formControlName="{{ option.slug }}"
                  name="{{ option.slug }}"
                  type="number"
                  [(ngModel)]="option.value"
                />
                <textarea
                  *ngIf="option.type === 'textArea'"
                  class="form-control sos-standard-textarea sos-scrollbar-none"
                  formControlName="{{ option.slug }}"
                  name="{{ option.slug }}"
                  rows="5"
                  [(ngModel)]="option.value"
                ></textarea>
                <select
                  *ngIf="option.type === 'select'"
                  formControlName="{{ option.slug }}"
                  name="{{ option.slug }}"
                  [(ngModel)]="option.value"
                  (change)="onValueChange($event, option.slug)"
                >
                  <option [ngValue]="undefined" selected disabled>Select {{ option.name }}</option>
                  <option
                    value="{{ ddOption.slug }}"
                    *ngFor="let ddOption of option.dropdownOptions"
                  >
                    {{ ddOption.name }}
                  </option>
                </select>
              </div>
            </ng-container>
          </div>
          <div class="w-full flex justify-center">
            <app-btn typeBtn="submit" [disabled]="loadingRequest || inquiryForm?.invalid">
              Send @if (loadingRequest) {
              <i class="spin medium"></i>
              }
            </app-btn>
          </div>
        </form>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</ng-template>
