<ng-template #termsModal>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <button type="button" class="close" (click)="hideModal()"><span class="sr-only">Close</span><span
            class="ss-icon-close"></span></button>
        <h4 class="modal-title" id="loginModal">{{subdomain}} Terms</h4>
      </div>
    </div>
    <div class="row text-left mt-8">
      <div class="row col-md-12 col-lg-12 col-sm-12 pl-10 pb-7 mt-0 text-black" *ngIf="agreement">
        <ng-container *ngFor="let matrix of agreement.priceMatrix">
          <table class="factors-table" style="width: 100%">
            <thead>
            <tr>
              <th class="text-left pt-1 pb-2.5 pl-1 pr-1">
                Usage
              </th>
              <th *ngFor="let term of matrix.terms" class="text-right pt-1 pb-2.5 pl-1 pr-1"
                  style="font-weight: 600">
                {{term}}
              </th>
            </tr>
            </thead>
            <tbody>
            <ng-container>
              <tr *ngFor="let usage of matrix.usages">
                <td class="text-left pt-1 pb-1 pl-1 pr-1">
                  <b style="font-weight: 600">{{usage.label}}</b>
                </td>
                <td *ngFor="let data of usage.data" class="text-right pt-1 pb-1 pl-1 pr-1">
                  {{data.type === 'number' ? (data.value | currency:'USD':'symbol':'0.0') : data.value}}
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
