export class PlayerWidgetTrackModel {
  tabId?: string;
  trackIndex?: number;
  trackId: string;
  url: string;
  waveformUrl?: string;
  name: string;
  artists?: any[];
  version?: string;
  versions?: any[];
  fileType?: string;
  comments?: any[];
  showNewComment?: boolean;
  local?: boolean; // To store comments locally instead of make request one by one
  objectData?: any;
  hideOptions?: any;
  rating?: any;
  segmentsGroups?: any;
  lyric?: any;
  paging?: any;
}
