<ng-template #createPlaylistModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="sos-generic-modal-container sos-scrollbar-none">
      <div class="modal-body">
        <div class="row"></div>
        <div class="row text-left mt-0">
          <div class="col-md-12">
            <form [formGroup]="playlistForm">
              <div class="mt-5" [ngClass]="{ notEmpty: playlistForm.controls['name'].value }">
                <label for="inputPlaylistName">Name:</label>
                <input
                  data-hj-allow
                  type="text"
                  class="sos-standard-input"
                  formControlName="name"
                  id="inputPlaylistName"
                  placeholder="Name (Required)"
                  (keydown)="onKeydown($event)"
                />
              </div>
              <div
                class="form-group mt-5"
                [ngClass]="{ notEmpty: playlistForm.controls['description'].value }"
              >
                <label for="inputPlaylistDescription">Description:</label>
                <textarea
                  class="sos-standard-textarea sos-scrollbar-none"
                  formControlName="description"
                  id="inputPlaylistDescription"
                  rows="3"
                  (keydown)="onKeydown($event)"
                  placeholder="Description"
                ></textarea>
              </div>
              <div
                *ngIf="displayPrivacy"
                class=""
                [ngClass]="{ notEmpty: playlistForm.controls['privacy'].value }"
              >
                <label for="privacy">Privacy: </label>
                <select id="privacy" name="privacy" formControlName="privacy" class="w-full">
                  <option value="OnlyMe">Only me</option>
                  <option value="TeamView">Team View (Read-Only)</option>
                  <option value="TeamEdit">Team Edit (Edit by Team)</option>
                </select>
              </div>
              <div class="mt-5">
                <div class="w-full flex justify-end">
                  <app-btn [disabled]="loading || playlistForm.invalid" (click)="createPlaylist()">
                    Create @if (loading) {
                    <i class="spin medium loader-margin"></i>
                    }
                  </app-btn>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
