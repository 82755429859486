import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { HideExtensionPipe } from '../../../../shared/pipes/hide-extension.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgIf, NgClass, NgStyle, NgFor } from '@angular/common';
import { BtnComponent } from 'src/app/components/btn/btn.component';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgStyle, TooltipModule, NgFor, HideExtensionPipe, BtnComponent],
})
export class GenericModalComponent implements OnInit {
  @Output() modalClosed = new EventEmitter();
  dataInfo: any = {};
  closeEmit = false;
  textCopied: boolean;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private clipBoardService: ClipboardService,
  ) {}

  ngOnInit() {
    this.textCopied = false;
  }

  copyToClipboard(text: string) {
    this.clipBoardService.copy(text);
    this.textCopied = true;
    const btn = document.getElementById('copyBtn');
    this.highlightButton(btn);
    setTimeout(() => {
      this.textCopied = false;
    }, 3000);
  }

  highlightButton(btn) {
    this.renderer.addClass(btn, 'highlight');
    setTimeout(() => {
      this.renderer.addClass(btn, 'highlight-fade');
    }, 2000);
    setTimeout(() => {
      this.renderer.removeClass(btn, 'highlight');
      this.renderer.removeClass(btn, 'highlight-fade');
    }, 4000);
  }

  getBtnAction(btn) {
    if (btn.status === true) {
      this.modalClosed.emit({ status: btn.status, data: btn.data });
      this.closeEmit = true;
    }
    this.hideModal();
  }

  public hideModal() {
    if (this.dataInfo.redirectUrl) {
      this.router.navigateByUrl(this.dataInfo.redirectUrl);
    }
    if (!this.closeEmit) {
      this.modalClosed.emit();
    }
  }
}
