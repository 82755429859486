import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
  selector: 'app-shape-images',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './shape-images.component.html',
  styleUrl: './shape-images.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShapeImagesComponent {
  image = input<string>();
  shape = input.required<
    | 'full'
    | 'double-right'
    | 'double-top'
    | 'left-round'
    | 'middle-left'
    | 'middle-right'
    | 'right-round'
    | 'round'
    | 'sound-wave-s'
  >();

  minWidth = input<number>(0);
  minHeight = input<number>(0);
  shapeColor = input<'primary' | 'light' | 'dark' | 'secondary' | 'accent' | 'default' | 'none'>();
  aspectRatio = input<string>('auto');
  additionalClasses = input<string>('');
  background = input<string>('transparent');

  shapeColorFilter = computed(() => this.getShapeColorFilter(this.shapeColor()));

  getShapeColorFilter(
    color: 'primary' | 'light' | 'dark' | 'secondary' | 'accent' | 'default' | 'none',
  ): string {
    switch (color) {
      case 'light':
        return `brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(2072%) hue-rotate(187deg)
        brightness(104%) contrast(74%)`;
      case 'dark':
        return `brightness(0) saturate(100%) invert(58%) sepia(11%) saturate(158%) hue-rotate(139deg)
        brightness(94%) contrast(88%)`;
      case 'secondary':
        return `brightness(0) saturate(100%) invert(68%) sepia(99%) saturate(3464%) hue-rotate(167deg)
        brightness(100%) contrast(98%)`;
      case 'accent':
        return `brightness(0) saturate(100%) invert(97%) sepia(8%) saturate(5580%) hue-rotate(344deg)
        brightness(104%) contrast(104%)`;
      case 'default':
        return `brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7481%) hue-rotate(104deg) brightness(109%) contrast(105%)`;
      default:
        return 'none';
    }
  }
}
