import { LabelType } from 'ngx-slider-v2';

export const suggestionsExamples = [
  { label: 'Pop', slug: 'pop' },
  { label: 'Rock', slug: 'rock' },
  { label: 'Electronic', slug: 'electronic' },
  { label: 'R&B', slug: 'R&B' },
];

export const getBudgetSliderSettings = (currency) => ({
  floor: 500,
  step: 500,
  ceil: 50000,
  vertical: true,
  translate: (value: number, label: LabelType): string => {
    return (label === LabelType.Low && value === 50000) || label === LabelType.Ceil
      ? 'Any Budget'
      : currency.transform(value, 'USD', 'symbol', '0.0');
  },
  logScale: true,
  showTicks: false,
  showSelectionBar: true,
  disabled: true,
  animate: false,
  selectionBarGradient: {
    from: '#25eefe',
    to: '#d2fc63',
  },
});

export const getStatsSliderSettings = (commonService) => ({
  floor: 10000,
  step: 10000,
  ceil: 1000000,
  vertical: true,
  translate: (value: number, label: LabelType): string => {
    if (label === LabelType.Ceil || (label === LabelType.High && value === 1000000)) {
      return 'Unlimited';
    }
    return commonService.addLetterToLargeNumber(value);
  },
  logScale: true,
  showTicks: false,
  disabled: false,
  animate: false,
  showSelectionBar: true,
  selectionBarGradient: {
    from: '#25eefe',
    to: '#d2fc63',
  },
});

export const getBpmSliderSettings = (commonService) => ({
  floor: 0,
  step: 10,
  ceil: 200,
  // vertical: true,
  translate: (value: number, label: LabelType): string => {
    if (label === LabelType.Ceil || (label === LabelType.High && value === 200)) {
      return 'Unlimited';
    }
    return commonService.addLetterToLargeNumber(value);
  },
  showTicks: false,
  disabled: false,
  animate: false,
  showSelectionBar: true,
  selectionBarGradient: {
    from: '#25eefe',
    to: '#d2fc63',
  },
});

export const prefixes = {
  vocal: {
    'vocal-influence': { label: 'Vocal Influence', removed: false },
    'vocal-sonority': { label: 'Vocal', removed: false },
    'themes-uses': { label: 'Themes & Uses: ', removed: false },
  },
  rhythm: {
    'rhythm-programmed': { label: ' (Programmed)', removed: false },
    'rhythm-played': { label: ' (Organic)', removed: false },
    'rhythm-influence': { label: ' Rhythms', removed: false },
  },
  harmony: {
    'harmony-influence': { label: 'Harmonic Influence', removed: false },
    'harmony-texture': { label: ' Harmonic Texture', removed: false },
  },
  bpm: {
    bpm: { label: 'Bpm ', removed: false },
  },
};

export const dropDownSettings = {
  text: 'Select Owners',
  enableSearchFilter: true,
  enableFilterSelectAll: false,
  enableCheckAll: false,
};

export const htmlTags = {
  oneStop: {
    hover: false,
    selected: false,
    negative: false,
  },
  covers: {
    hover: false,
    selected: false,
    negative: false,
  },
  hasRhythm: {
    hover: false,
    selected: false,
    negative: false,
  },
};

export const multiSelect = {
  labels: {
    data: [],
  },
  releaseStatus: {
    data: [
      { id: 'released-without-date', name: 'Released Without Date' },
      { id: 'released-with-date', name: 'Released With Date' },
      { id: 'released-for-synch', name: 'Released For Synch' },
      { id: 'unreleased', name: 'Unreleased' },
    ],
  },
};
