import { Inject, Injectable } from '@angular/core';
import { SostereoServicesModule } from '../sostereo-services.module';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import {
  ResponseGetTransaction,
  TransactionModel,
  SongItemModel,
} from '../../models/transaction.model';

@Injectable({
  providedIn: SostereoServicesModule,
})
export class TransactionsService {
  private apiHost = this.environment.apiHost;
  private apiVersion = this.environment.apiVersion;
  private corePath = this.environment.corePath;
  public transactionDraftSubject = new Subject();

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  query(params?: any): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/transactions`;
    return this.http.get<any>(url, { params: params });
  }

  countTransactions(): Observable<any> {
    const url = `${this.apiHost}/api/${this.apiVersion}/transatcions/parent/countTransactions`;
    return this.http.post<any>(url, {});
  }

  post(transaction: TransactionModel): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/transactions`;
    return this.http.post<any>(url, transaction);
  }

  update(id: string, transaction: any): Observable<ResponseGetTransaction> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/transactions/${id}`;
    return this.http.put<ResponseGetTransaction>(url, transaction);
  }

  /**
   * @deprecated since 11-12-22
   * @param id
   * @returns
   */
  uploadToken(id: string): Observable<any> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/transactions/${id}/upload-token`;
    return this.http.post(url, {});
  }

  getbyId(id: string): Observable<ResponseGetTransaction> {
    const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/transactions/${id}`;
    return this.http.get<ResponseGetTransaction>(url);
  }

  downloadDocuments(id: string, projectName: string, body?): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.apiHost}/${this.corePath}/${this.apiVersion}/transactions/${id}/zip`;
      this.http.post(url, body || {}, { responseType: 'blob' }).subscribe(
        (res) => {
          const url = window.URL.createObjectURL(res);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.target = '_blank';
          a.href = url;
          a.download = `${projectName}.zip`;
          a.click();
          a.remove();
          resolve(true);
        },
        (err) => {
          console.log(err);
          reject(false);
        },
      );
    });
  }

  getTransactionDraft(): TransactionModel {
    const transaction = localStorage.getItem('SOSTEREO.transaction');
    return transaction ? JSON.parse(transaction) : null;
  }

  setTransactionDraft(transaction: TransactionModel) {
    localStorage.setItem('SOSTEREO.transaction', JSON.stringify(transaction));
    this.transactionDraftSubject.next(transaction);
  }

  removeTransactionDraft(): void {
    if (localStorage.getItem('SOSTEREO.transaction')) {
      localStorage.removeItem('SOSTEREO.transaction');
      this.transactionDraftSubject.next(null);
    }
  }

  getTotalBySong(item: SongItemModel) {
    let reduce;
    try {
      reduce = item.license
        .map((item) => item.amount.total)
        .reduce((a, b) => parseFloat(a) + parseFloat(b));
    } catch (error) {
      reduce = 'Quote Pending';
    }
    return reduce;
  }

  getAmountTotalByTransaction(songs: SongItemModel[]): any {
    let result = '';
    let acc = 0;
    songs.forEach((item) => {
      const value = this.getTotalBySong(item);
      if (isNaN(value)) {
        result = 'Quote Pending';
        return;
      } else {
        acc += parseFloat(value);
      }
    });
    return result != '' ? result : acc;
  }
}
