import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-field-table',
  templateUrl: './field-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, NgClass],
})
export class FieldTableComponent {
  @Input() col: any;
  @Input() data: any;
  @Input() tabID: string;
  @Output() rowAction = new EventEmitter();

  getRowAction(tableId, col, rowData, rowIndex?, option?) {
    this.rowAction.emit({ tableId, col, rowData, rowIndex, option });
  }
}
