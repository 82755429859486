import {
  Component,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  EventEmitter,
  Inject,
} from '@angular/core';
import { licenseRequestConfig } from './config/license-request.config';
import { SearchTagsService } from '../../../../shared/services/search-tags.service';
import { AgreementService, TransactionsService } from 'sostereo-services';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { CustomLicenseRequestModalComponent } from '../custom-license-request-modal/custom-license-request-modal.component';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation } from '../../../../shared/animations/in-out.animation';
import { clone, cloneDeep, isEmpty, remove } from 'lodash-es';
import { CommonService } from '../../../../shared/services/common.service';
import { finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SongStatusModalComponent } from '../song-status-modal/song-status-modal.component';
import { TransactionModel } from 'projects/sostereo-services/src/models/transaction.model';
import { SongRestrictionModalComponent } from '../song-restriction-modal/song-restriction-modal.component';
import { LicenseAssignationModalComponent } from '../license-assignation-modal/license-assignation-modal.component';
import { FormsModule } from '@angular/forms';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import {
  NgIf,
  NgStyle,
  NgFor,
  NgClass,
  UpperCasePipe,
  TitleCasePipe,
  CurrencyPipe,
  KeyValuePipe,
} from '@angular/common';
import { BtnComponent } from 'src/app/components/btn/btn.component';

@Component({
  selector: 'app-license-request-modal',
  templateUrl: './license-request-modal.component.html',
  styleUrls: ['./license-request-modal.component.scss'],
  animations: [trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))])],
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    NgFor,
    ProgressbarModule,
    NgClass,
    FormsModule,
    CustomLicenseRequestModalComponent,
    SongStatusModalComponent,
    SongRestrictionModalComponent,
    LicenseAssignationModalComponent,
    UpperCasePipe,
    TitleCasePipe,
    CurrencyPipe,
    KeyValuePipe,
    BtnComponent,
  ],
})
export class LicenseRequestModalComponent {
  @ViewChild('licenseRequestModal', { static: true })
  private modalTemplate: TemplateRef<any>;

  @ViewChild('customLicenseRequestModalComponent', { static: true })
  private customLicenseRequestModal: CustomLicenseRequestModalComponent;

  @ViewChild('songRestrictionModalComponent', { static: true })
  private songRestrictionModal: SongRestrictionModalComponent;

  @ViewChild('songStatusModalComponent', { static: true })
  private songStatusModal: SongStatusModalComponent;

  @ViewChild('licenseAssignationModalComponent', { static: true })
  licenseAssignationModalComponent: LicenseAssignationModalComponent;

  @Input()
  public trackInput;

  @Input()
  public transactionInput: TransactionModel;

  @Input()
  public editOnly;

  @Input()
  public company;

  @Input()
  public transactionAction;

  @Input()
  public isDraft: Boolean;

  @Output()
  transactionUpdate = new EventEmitter();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onModalClose = new EventEmitter();

  public track;
  public modalRef: BsModalRef;
  public types: any = [];
  public selectedType: any;
  public currentStepIndex = 0;
  public showSteps = false;
  public showLicenseTerms = false;
  public licenseTerms: { [key: string]: any } = null;
  public loadingRequest = false;
  public editSpinActive = false;
  public submitSpinActive = false;
  public loadingLicenseSteps = false;
  // agreement license process vars
  public agreementProcess = false;
  public currentAgreementStepIndex = 0;
  public agreementSteps: any = [];
  public showAgreementLicenseTerms = false;
  public agreementLicenseTerms: any = null;
  public agreement: any = null;
  public subdomain = '';
  public agreementProcessOptions: any = [];
  public showLicenseTermsAlert = false;
  public noAgreementTotal;
  public invalidPrice = false;
  public transactionOld: TransactionModel = null;
  transactionComplete: TransactionModel;
  transactionInputFactors: any;
  private trackAgreement: any;
  public logoPath: any = null;
  public assignationData: any = {};
  private assignationAvailable = false;
  private optionsModal = false;
  private hasWarning = false;

  constructor(
    private modalService: BsModalService,
    private searchTagsService: SearchTagsService,
    private trackingService: TrackingService,
    private agreementService: AgreementService,
    private transactionsService: TransactionsService,
    public commonService: CommonService,
    private toastr: ToastrService,
    private router: Router,
    @Inject('environment') public environment,
  ) {
    if (localStorage.getItem('SOSTEREO.licenseAssignation')) {
      this.assignationData = JSON.parse(localStorage.getItem('SOSTEREO.licenseAssignation'));
    }
  }

  public onRestrictionsAgreed() {
    this.showModalLicense();
  }

  private resetData() {
    this.agreementLicenseTerms = null;
    this.agreementProcess = false;
    this.currentAgreementStepIndex = 0;
    this.agreementSteps = [];
    this.showAgreementLicenseTerms = false;
    this.agreement = null;
    this.subdomain = '';
    this.agreementProcessOptions = [];
    this.loadingLicenseSteps = false;
    this.showLicenseTerms = false;
    this.licenseTerms = null;
    this.selectedType = null;
    this.showSteps = false;
    this.showLicenseTermsAlert = false;
    this.currentStepIndex = 0;
    this.transactionInputFactors = null;
    this.transactionOld = null;
    this.trackAgreement = null;
  }

  public validateRepeatedSongs(): boolean {
    console.log(this.transactionOld, this.track);
    if (this.transactionOld && this.track) {
      const id = this.track._id || this.track.trackId;
      const element = this.transactionOld.items.find((song) => song.id === id);
      return element ? true : false;
    }
    return false;
  }

  public async initModal(): Promise<boolean> {
    this.loadingLicenseSteps = true;
    let startModal = true;
    this.transactionOld = this.transactionsService.getTransactionDraft();
    this.track = cloneDeep(this.trackInput);
    this.transactionComplete = this.transactionInput
      ? cloneDeep(this.transactionInput)
      : this.transactionOld
      ? cloneDeep(this.transactionOld)
      : null;
    if (this.transactionComplete?.licenseType === 'custom') {
      startModal = false;
    }
    if (this.track) {
      if (this.validateRepeatedSongs()) {
        this.toastr.info('The song has already been added to the license');
        startModal = false;
      } else {
        this.track.restrictions = this.commonService.mapRestrictionsToArray(
          this.track?.restrictions,
        );
        let trackCopy = cloneDeep(this.track);
        if (
          !this.commonService.isAllowed(['CreateRestriction', '*']) &&
          trackCopy.restrictions?.length > 0
        ) {
          remove(trackCopy.restrictions, (r: any) => r.hasOwnProperty('visible') && !r.visible);
        }
        if (this.showStatusSong(trackCopy) && this.isAllowed(['UpdateTransactionAll'])) {
          this.songStatusModal.tracks = [trackCopy];
          this.songStatusModal.showModal();
          this.hasWarning = true;
        } else if (
          trackCopy.restriction ||
          (trackCopy.restrictions && !isEmpty(trackCopy.restrictions || []))
        ) {
          this.songRestrictionModal.track = trackCopy;
          this.songRestrictionModal.showModal();
          this.hasWarning = true;
        } else {
          this.showModalLicense();
        }
      }
    } else {
      this.showModalLicense();
    }
    // Clear assignationData if there is no LS variable and the admin didn't set a assignation to the license
    if (!this.assignationAvailable && !localStorage.getItem('SOSTEREO.licenseAssignation')) {
      this.assignationData = {};
      this.customLicenseRequestModal.licenseAssignation = {};
    }
    this.transactionInputFactors = this.transactionInput
      ? await this.mapTransactionToFactors(this.transactionInput)
      : this.transactionOld
      ? await this.mapTransactionToFactors(this.transactionOld)
      : null;
    return startModal;
  }

  public async showModal() {
    this.resetData();
    if (await this.initModal()) {
      // preguntar por el agreement
      if (this.transactionComplete) {
        if (this.transactionComplete.agreement) {
          this.agreementProcessOptions = this.transactionInputFactors.factors;
          this.endAgreementProcess();
          this.setAgreementLicense();
        } else {
          if (!this.hasWarning) {
            this.hideModal();
            if (this.modalRef) {
              this.modalRef.onHidden.subscribe(() => {
                this.customLicenseRequestModal.showModal();
              });
            } else {
              this.customLicenseRequestModal.showModal();
            }
          }
          this.optionsModal = true;
          this.setDefaultLicense();
        }
      } else {
        this.agreement = await this.getAgreementLicense(this.assignationData?.agreement || null);
        if (!isEmpty(this.agreement)) {
          this.setAgreementLicense();
        } else {
          this.optionsModal = true;
          if (!this.hasWarning) {
            this.hideModal();
            if (this.modalRef) {
              this.modalRef.onHidden.subscribe(() => {
                this.customLicenseRequestModal.showModal();
              });
            } else {
              this.customLicenseRequestModal.showModal();
            }
          }
          this.setDefaultLicense();
        }
      }
    } else {
      this.hideModal();
      return;
    }
  }

  public showModalLicense() {
    if (this.transactionComplete?.licenseType === 'custom') {
      this.customLicenseRequestModal.showModal();
    } else {
      if (this.optionsModal) {
        this.hideModal();
        this.customLicenseRequestModal.showModal();
      } else {
        this.modalRef = this.modalService.show(this.modalTemplate, {
          class: 'modal-lg',
          ignoreBackdropClick: true,
          keyboard: false,
        });
      }

      this.trackingService.track(
        'License Request Modal Show',
        {
          action: 'Modal Show',
          kind: 'License Request modal',
        },
        {
          event_action: 'License request modal opened',
          event_type: 'Modal Opened',
        },
      );
    }
  }

  private async setAgreementLicense() {
    this.subdomain = this.commonService.getSubdomain();
    this.trackAgreement = await this.getAgreementSongs();
    if (this.track && (!this.trackAgreement || isEmpty(this.trackAgreement.data))) {
      this.showLicenseTermsAlert = true;
    }
    this.loadingLicenseSteps = false;
    this.agreementProcess = true;
    this.logoPath = `https://img-sostereo.sostereo.com/sostereo-images-${
      this.environment.production ? 'production' : 'stg'
    }/logos/${
      this.transactionInputFactors && this.isAllowed(['*', 'UpdateTransactionAll'])
        ? this.company || 'stereo-brands'
        : this.subdomain
    }'.png`;
    this.agreement.priceMatrix.forEach((pm) => {
      const usagesOptions: any = [];
      pm.usages.forEach((r) => {
        const usageOption: any = {
          parent: 'usage',
          label: r.label,
          data: r.data,
          price: {
            type: 'number',
            value: 0,
          },
        };

        usageOption.data.forEach((dataItem, dItemIntex) => {
          if (dataItem.type === 'number' || dItemIntex === 0) {
            if (this.showLicenseTermsAlert) {
              dataItem = { type: 'no-quote', value: 'Ask For Quote' };
            }
            usageOption.price = dataItem;
            usageOption.term = pm.terms[dItemIntex];
          }
          if (this.transactionInputFactors) {
            usageOption.selected = this.agreementProcessOptions.find((ag: any) => {
              if (ag.label === usageOption.label) {
                ag.data = usageOption.data;
              }
              return ag.label === usageOption.label;
            });
            usageOption.price = this.agreementProcessOptions.find(
              (ag: any) => ag.label === usageOption.label,
            )
              ? this.agreementProcessOptions.find((ag: any) => ag.label === usageOption.label).price
              : usageOption.price;
          }
        });
        usagesOptions.push(usageOption);
      });
      const usageStep = {
        id: 'usage',
        title: 'Usage',
        description: 'What type of project are you working on?',
        options: usagesOptions,
        multiselect: true,
      };
      this.agreementSteps.push(usageStep);
      const termOptions: any = [];
      pm.terms.forEach((term, termIndex) => {
        const termOption: any = {
          parent: 'term',
          label: term,
          columnIndex: termIndex,
        };
        termOptions.push(termOption);
      });
      const termStep = {
        id: 'term',
        title: 'Term',
        description: '',
        options: termOptions,
        multiselect: false,
      };
      this.agreementSteps.push(termStep);
      //console.log('Data this.agreementSteps >>>> ', this.agreementSteps);
    });
    if (this.transactionInputFactors) {
      this.currentAgreementStepIndex = this.agreementSteps.length - 1;
    }
  }

  private setDefaultLicense() {
    this.types = licenseRequestConfig().types;
    this.loadingLicenseSteps = false;
    if (this.transactionInputFactors) {
      this.mapSteps();
      this.licenseTerms = cloneDeep(this.transactionInputFactors.factors[0]);
      if (this.licenseTerms._id) {
        delete this.licenseTerms._id;
      }
    }
    //console.log('Option 2 >>> ', this.types);
  }

  private getAgreementLicense(agreementId?: string): Promise<any> {
    return new Promise((resolve) => {
      if (agreementId) {
        this.agreementService.get(agreementId).subscribe({
          next: (aRes) => {
            //console.log('getAgreementData 2', aRes, this.agreementLicenseTerms);
            resolve(aRes.data);
          },
          error: () => {
            //console.log('getAgreementData 3');
            resolve(null);
          },
        });
      } else {
        if (this.commonService.isAllowed(['GetAgreement'], ['UpdateSearchSettings'])) {
          this.agreementService.me().subscribe({
            next: (aRes) => {
              resolve(aRes.data);
            },
            error: () => {
              resolve(null);
            },
          });
        } else {
          resolve(null);
        }
      }
    });
  }

  private getAgreementSongs(): Promise<any> {
    let songId = null;
    if (this.track) {
      songId = this.track._id || this.track.trackId || this.track.id;
    }
    return new Promise((resolve) => {
      if (this.track) {
        this.agreementService.getAgreementSongs([songId]).subscribe({
          next: (sRes) => {
            //console.log('getAgreementSongs 1');
            resolve(sRes);
          },
          error: () => {
            //console.log('getAgreementSongs 2');
            resolve(null);
          },
        });
      } else {
        //console.log('getAgreementSongs 4');
        resolve(null);
      }
    });
  }

  private mapSteps() {
    this.selectedType = this.types.find((t: any) => {
      return (
        this.transactionInputFactors.factors[0] &&
        this.transactionInputFactors.factors[0].category &&
        this.transactionInputFactors.factors[0].category.label &&
        t.label.toLowerCase() ===
          this.transactionInputFactors.factors[0].category.label.toLowerCase()
      );
    });
    if (this.selectedType) {
      this.selectedType.selected = true;
      this.selectedType.steps.forEach((t) => {
        const option = t.options.find(
          (o) =>
            o.label === this.transactionInputFactors.factors[0][t.slug].label ||
            o.label === this.transactionInputFactors.factors[0][t.slug],
        );
        if (option) {
          option.selected = true;
        }
      });
      this.showLicenseTerms = true;
      this.currentStepIndex = this.selectedType.steps.length - 1;
    } else {
      this.toastr.error('Error to find song', 'Error');
      this.hideModal();
    }
    //console.log('Selected Steps >>> ', this.selectedType);
  }

  public hideModal() {
    this.loadingRequest = false;
    this.submitSpinActive = false;
    this.editSpinActive = false;
    this.optionsModal = false;
    this.hasWarning = false;
    this.types.forEach((t: any) => (t.selected = false));
    this.modalRef?.hide();
    this.onModalClose.emit();
    this.trackingService.track(
      'License Request Modal Hide',
      {
        action: 'Modal Hide',
        kind: 'License Request modal',
      },
      {
        event_action: 'License request modal hidden',
        event_type: 'Modal Hidden',
      },
    );

    // Clear assignationData if there is no licenseAssignation LS variable to keep saving the license to the specific user
    if (!localStorage.getItem('SOSTEREO.licenseAssignation')) {
      this.assignationData = {};
    }
    this.assignationAvailable = false;
  }

  public selectType(type) {
    this.types.forEach((t: any) => (t.selected = t.id === type.id));
    this.selectedType = type;
  }

  public addAgreementOption(step, option) {
    if (option.selected) {
      const addedOption = this.agreementProcessOptions.find((op: any) => op.label === option.label);
      if (addedOption) {
        this.agreementProcessOptions.splice(this.agreementProcessOptions.indexOf(addedOption), 1);
      }
    } else {
      //console.log(option);
      this.agreementProcessOptions.push(option);
    }
    option.selected = !option.selected;
  }

  public onAgreementOptionProcessChange(option, event) {
    const label = event.target.value;
    option.termObj = this.agreementSteps[this.currentAgreementStepIndex].options.find(
      (o) => o.label === label,
    );
    option.price = this.showLicenseTermsAlert
      ? { type: 'no-quote', value: 'Ask For Quote' }
      : option.data[option.termObj.columnIndex];
  }

  public selectOption(option) {
    this.selectedType.steps[this.currentStepIndex].options.forEach(
      (o) => (o.selected = o.label === option.label),
    );
  }

  public nextStep() {
    if (this.selectedType && !this.showSteps) {
      if (this.selectedType.slug === 'custom') {
        this.showCustomProcess();
      } else {
        this.showSteps = true;
      }
    } else {
      this.currentStepIndex++;
    }
  }

  public prevStep() {
    if (this.showLicenseTerms) {
      this.showLicenseTerms = false;
      this.showSteps = true;
    } else {
      if (this.currentStepIndex === 0) {
        this.showSteps = false;
      } else {
        this.currentStepIndex--;
      }
    }
  }

  public endProcess() {
    this.licenseTerms = {
      category: {
        id: this.selectedType.id,
        label: this.selectedType.label,
        title: 'Use',
      },
    };
    const budgetTag: any = this.searchTagsService.selectedTags.find(
      (t: any) => t.category === 'budget' && t.type === 'budgetAmount',
    );
    if (budgetTag) {
      this.licenseTerms.maxBudget = budgetTag.value;
    }
    this.selectedType.steps.forEach((s) => {
      if (s.type && s.type === 'text') {
        this.licenseTerms[s.slug] = s.options.find((o) => o.selected).label;
      } else {
        this.licenseTerms[s.slug] = s.options.find((o) => o.selected);
        this.licenseTerms[s.slug].title = s.title;
      }
    });

    if (!this.agreement || this.showLicenseTermsAlert) {
      this.licenseTerms.amount = {
        total: 'Ask For Quote',
        currency: 'no-quote',
      };
    }
    this.showSteps = false;
    this.showLicenseTerms = true;
  }

  public prevAgreementStep() {
    if (this.showAgreementLicenseTerms) {
      this.showAgreementLicenseTerms = false;
    } else {
      this.currentAgreementStepIndex--;
    }
  }

  public nextAgreementStep() {
    this.currentAgreementStepIndex++;
  }

  public endAgreementProcess() {
    const licenseFactors = cloneDeep(this.agreementProcessOptions);
    let total: any = 0;
    let quotePending = false;
    //console.log(licenseFactors);
    licenseFactors.forEach((o) => {
      if (o.price.type === 'number') {
        total = total + o.price.value;
      } else {
        quotePending = true;
      }
    });
    if (quotePending) {
      total = 'Quote Pending';
    }
    this.agreementLicenseTerms = {
      factors: licenseFactors,
      total: total,
    };
    this.showAgreementLicenseTerms = true;
  }

  public currentStepHasNotOptionSelected() {
    if (this.selectedType.slug === 'custom') {
      return false;
    } else {
      return this.selectedType.steps[this.currentStepIndex].options.every(
        (o) => o.selected === false,
      );
    }
  }

  public showCustomProcess() {
    this.hideModal();
    if (this.modalRef) {
      this.modalRef.onHidden.subscribe(() => {
        this.customLicenseRequestModal.showModal();
      });
    } else {
      this.customLicenseRequestModal.showModal();
    }
  }

  private getLicenseSong(): any[] {
    let licenseAllSongs: any = [];
    let licenseAll: any = [];
    if (this.agreementLicenseTerms) {
      if (this.transactionComplete) {
        //console.log(this.agreementLicenseTerms.factors);
        this.transactionComplete.items.forEach((song) => {
          this.agreementLicenseTerms.factors.forEach((f) => {
            const license = {
              category: {
                label: f.label,
                title: 'Use',
              },
              term: {
                label: f.term,
                title: 'Term',
              },
              amount: {},
            };
            const price = this.getPriceAgreement(this.agreement, license);
            license.amount = song.agreement
              ? { total: price.value, currency: price.type === 'number' ? 'USD' : 'no-quote' }
              : { total: 'Ask For Quote', currency: 'no-quote' };

            licenseAll.push(license);
          });
          licenseAllSongs.push(licenseAll);
          licenseAll = [];
        });
      }
      if (this.track) {
        this.agreementLicenseTerms.factors.forEach((f) => {
          licenseAll.push({
            category: {
              label: f.label,
              title: 'Use',
            },
            term: {
              label: f.term,
              title: 'Term',
            },
            amount: !this.showLicenseTermsAlert
              ? {
                  total: f.price.value,
                  currency: f.price.type === 'number' ? 'USD' : 'no-quote',
                }
              : { total: 'Ask For Quote', currency: 'no-quote' },
          });
        });
        licenseAllSongs.push(licenseAll);
      }
    } else {
      licenseAll.push(this.licenseTerms);
      licenseAllSongs.push(licenseAll);
    }
    return licenseAllSongs;
  }

  private mapTransaction() {
    let licenseAll = this.getLicenseSong();
    let newSong;
    let transaction: any;

    if (this.transactionComplete) {
      transaction = this.transactionComplete;
      transaction.licenseType =
        this.isAllowed(['CreateTransaction:Credit']) && !this.showLicenseTermsAlert
          ? 'credit'
          : 'default';
    } else {
      transaction = {
        description: 'x',
        licenseType:
          this.isAllowed(['CreateTransaction:Credit']) && !this.showLicenseTermsAlert
            ? 'credit'
            : 'default',
        items: [],
        amount: {
          total: this.agreementLicenseTerms
            ? this.agreementLicenseTerms.total
            : this.noAgreementTotal
            ? this.noAgreementTotal.total
            : 'Quote Pending',
          currency:
            this.agreementLicenseTerms && this.agreementLicenseTerms.total === 'Ask For Quote'
              ? 'no-quote'
              : 'USD',
        },
      };
    }

    if (this.track) {
      newSong = {
        artists: this.track.artists,
        name: this.track.name,
        soId: this.track.soId,
        songVersion: this.track.songVersion,
        id: this.track._id || this.track.trackId,
        files: this.track.files || this.track.fileUrls,
        stems: this.track.stems || [],
        restriction: this.track.restriction,
        restrictions: this.commonService.mapRestrictionsToObject(this.track.restrictions),
        explicit: this.track.explicit || false,
        license: [],
      };

      if (this.agreement) {
        transaction.agreement = {
          id: this.agreement._id,
          version: this.agreement.currentVersion,
        };
        if (!this.showLicenseTermsAlert) {
          newSong.agreement = {
            id: this.agreement._id,
            version: this.agreement.currentVersion,
          };
        }
      }
    }
    if (newSong) {
      transaction.items.push(newSong);
    }
    transaction.amount = {
      total: this.agreementLicenseTerms
        ? this.agreementLicenseTerms.total
        : this.noAgreementTotal
        ? this.noAgreementTotal.total
        : 'Quote Pending',
      currency:
        this.agreementLicenseTerms && this.agreementLicenseTerms.total === 'Ask For Quote'
          ? 'no-quote'
          : 'USD',
    };
    transaction.items.forEach((song, index) => {
      song.license = licenseAll.length > 1 ? licenseAll[index] : licenseAll[0];
    });
    //console.log(transaction);
    return transaction;
  }

  public sendAgreementLicenseRequest(goToDraft?: boolean) {
    const transaction = this.mapTransaction();
    this.loadingRequest = true;
    this.submitSpinActive = true;
    this.transactionsService.setTransactionDraft(transaction);
    this.trackingService.track(
      'Transaction Draft Created',
      {
        data: transaction,
      },
      {
        event_action: 'Transaction draft created',
        event_type: 'Transaction Draft',
        event_value: transaction?.amount.total + ' ' + transaction?.amount.currency,
        element_type: transaction?.licenseType,
        element_value: transaction?.description,
      },
    );
    if (
      this.assignationData?.initOwnerUid &&
      !localStorage.getItem('SOSTEREO.licenseAssignation')
    ) {
      localStorage.setItem('SOSTEREO.licenseAssignation', JSON.stringify(this.assignationData));
    }
    if (goToDraft) {
      this.router.navigateByUrl('/transactions/draft');
    } else {
      let waitSeconds = 500;
      this.toastr.clear();
      waitSeconds = 500;

      setTimeout(() => {
        const activeToast = this.toastr.success(
          '<p>Song added successfully</p><a class="textDecoration"> Go to Draft </a>',
          'Continue to license request',
          { enableHtml: true },
        );
        activeToast.onTap.subscribe(() => {
          this.router.navigateByUrl('/transactions/draft');
        });
      }, waitSeconds);
    }
    this.hideModal();
  }

  private updateTransaction(transaction, message) {
    this.transactionsService
      .update(this.transactionInputFactors._id, transaction)
      .pipe(
        finalize(() => {
          this.loadingRequest = false;
          this.editSpinActive = false;
          this.submitSpinActive = false;
        }),
      )
      .subscribe(
        (res) => {
          //console.log('transactionsService Response >>> ', res);
          this.toastr.success(message, 'Success');
          this.hideModal();
          this.transactionUpdate.emit(res.data);
          if (transaction && transaction.action === 'APPROVED') {
            this.trackingService.track(
              'Transaction approved',
              {
                transaction: res?.data,
                status: res?.data ? res?.data?.status : res,
                transactionId: res?.data ? res?.data?._id : this.transactionInputFactors?._id,
              },
              {
                event_action: 'Transaction approved',
                event_type: 'Transaction',
                element_type: 'Transaction',
                element_value: res?.data?.project,
                element_id: res?.data ? res?.data?._id : this.transactionInputFactors?._id,
              },
            );
          }
        },
        (err) => {
          console.log('transactionsService Error >>>', err);
          this.toastr.error(err.error ? err.error.message : err.message, 'Error');
          this.trackingService.track('Error updating transaction', {
            transaction: transaction,
            transactionId: this.transactionInputFactors?._id,
            error: err,
          });
        },
      );
  }

  public updateData() {
    const transaction = this.mapTransaction();
    if (this.isDraft) {
      this.transactionsService.setTransactionDraft(transaction);
      this.hideModal();
      this.transactionUpdate.emit(transaction);
    } else {
      //console.log(transaction.items);
      this.editSpinActive = true;
      this.loadingRequest = true;
      const transactionUpdate = {
        action: 'EDIT',
        items: transaction.items,
      };
      this.updateTransaction(transactionUpdate, 'Data updated');
    }
  }

  public isAllowed(allowed, disallowed?) {
    return this.commonService.isAllowed(allowed, disallowed);
  }

  async mapTransactionToFactors(transaction: any): Promise<any> {
    const transactionRow = transaction;
    const transactionData: any = {
      _id: transactionRow._id,
      factors: [],
    };
    if (transactionRow.agreement) {
      this.agreement = await this.getAgreementLicense(transactionRow.agreement.id);
      this.trackAgreement = await this.getAgreementSongs();
      transactionRow.items[0].license.forEach((lic) => {
        const price =
          (this.track && this.trackAgreement && !isEmpty(this.trackAgreement.data)) || this.editOnly
            ? this.getPriceAgreement(this.agreement, lic)
            : { value: 'Ask For Quote', type: 'no-quote' };
        transactionData.factors.push({
          label: lic.category.label,
          parent: 'usage',
          price: price,
          defaultPrice: this.getPriceAgreement(this.agreement, lic),
          selected: true,
          term: lic.term.label,
        });
      });
      transactionData.total = Number(
        transactionRow.amount.total.toString().replace(',', '').replace('$', ''),
      );
      transactionData.total = isNaN(transactionData.total)
        ? 'Quote Pending'
        : transactionData.total;
    } else {
      transactionData.factors = transactionRow.items[0].license;
    }
    if (transactionRow.items[0].agreement) {
      //pregunta si el 1 elemento tiene agreement
      transactionData.agreement = transactionRow.items[0].agreement;
    }
    // This is to add the songAgreement flag to know if the song has agreement or not if the use does have
    if (
      transaction.items[0].hasOwnProperty('agreement') &&
      transaction.items[0].agreement !== null
    ) {
      transactionData.songAgreement = clone(transaction.items[0].agreement);
    }
    //console.log('transactionData >>> ', transactionData, transactionRow);
    if (transactionData.statusObj) {
      delete transactionData.statusObj;
    }
    //console.log('Transaction Data >>> ', transactionData);

    return transactionData;
  }

  private getPriceAgreement({ priceMatrix }, lic) {
    const termIndex = priceMatrix[0].terms.indexOf(lic.term.label);
    const usageComplete = priceMatrix[0].usages.find((usage) => usage.label === lic.category.label);
    const price = usageComplete?.data[termIndex];
    if (price) {
      return { value: price.value, type: price.type };
    } else {
      return { value: 'Ask For Quote', type: 'no-quote' };
    }
  }

  private showStatusSong(song): boolean {
    return (
      song.restriction ||
      (song.restrictions && !isEmpty(song.restrictions || [])) ||
      !!song.notes ||
      song.tierHasWarning ||
      song.publicAccess === false ||
      song.isOneStop == false ||
      song.releaseStatus === 'unreleased' ||
      song.customizable === 'no' ||
      song.stemsAvailable === 'no'
    );
  }

  public chooseUserUID() {
    this.licenseAssignationModalComponent.showModal();
    this.hideModal();
  }

  getAssignationData($event) {
    this.assignationData = $event;
    this.assignationAvailable = true;
    this.customLicenseRequestModal.licenseAssignation = $event;
    this.showModal();
  }

  assignationCheck($event) {
    console.log('CHECK >>>> ', $event);
    this.getAssignationData($event);
  }
}
