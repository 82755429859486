<ng-template #addToPlaylistModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div
      class="modal-content sos-generic-modal-container sos-scrollbar-none"
      id="addToPlaylistModal"
    >
      <div class="modal-body">
        <div class="row text-left mt-0">
          <div class="col-md-12">
            <h3>Add to Playlist</h3>
            <div *ngIf="!playlistDropDown">
              <br />
              <p>You have no playlists at the moment. Create one</p>
              <br />
              <br />
              <a
                class="btn secondary-link pull-left add-to-pl-btn btn-image mb-0 text-left pl-0"
                (click)="showCreatePlaylistModal()"
                [appAllowedTo]="'CreatePlaylist'"
              >
                <span class="material-icons-outlined" [attr.data-icon]="'add'"></span>New Playlist
              </a>
            </div>
            <div *ngIf="playlistDropDown">
              <div class="mt-5">
                <b
                  >{{ selectedPlaylist?.itemName ? 'Selected Playlist:' : 'Select a playlist' }}
                  <span class="secondary-color">{{ selectedPlaylist?.itemName }}</span></b
                >
                <br />
                <br />
                <label>Your Playlists:</label>
                <input
                  data-hj-allow
                  class="sos-standard-input"
                  type="text"
                  placeholder="Search playlist"
                  (keyup)="onSearch($event)"
                  [(ngModel)]="playlistTyped"
                  (click)="
                    dropDownOpen = playerService.isSmallSize ? !dropDownOpen : true;
                    openOptions($event)
                  "
                  (clickOutside)="clickOutside($event)"
                />
                <div *ngIf="dropDownOpen" class="playlist-wrapper sos-scrollbar-none">
                  <p *ngIf="dropdownLoading" class="info-text">
                    Loading data <i class="spin medium loader-margin ml-1.5"></i>
                  </p>
                  <p *ngIf="noResults && !dropdownLoading" class="info-text">
                    {{
                      emptyPlaylist
                        ? "You don't have any playlists yet, please create one"
                        : 'There are no results'
                    }}
                  </p>
                  <ng-container *ngFor="let category of playlistDropDown | keyvalue">
                    <div
                      *ngIf="category.value.items && category.value.items.length > 0"
                      id="playlistsOptionsContainer"
                    >
                      <p class="category-title">
                        {{ category.value.name }}
                      </p>
                      <div>
                        <p
                          *ngFor="let item of category.value.items"
                          (click)="
                            selectedPlaylist = item;
                            autoSelectedPlaylist = false;
                            dropDownOpen = false
                          "
                          class="mb-0"
                          [ngClass]="{
                            'secondary-color': item.id === 'addPage',
                            fw600: item.id === 'addPage',
                            'selected-playlist': item.id === selectedPlaylist?.id
                          }"
                        >
                          {{ item.itemName }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="mt-5">
                <div class="w-full flex justify-between items-baseline text-right">
                  <a
                    class="btn secondary-link pull-left add-to-pl-btn btn-image mb-0 text-left pl-0 pt-0"
                    (click)="showCreatePlaylistModal()"
                    [appAllowedTo]="'CreatePlaylist'"
                  >
                    <span class="material-icons-outlined" [attr.data-icon]="'add'"></span>New
                    Playlist
                  </a>
                  <app-btn
                    (click)="addSongToPlaylist()"
                    [disabled]="!selectedPlaylist?.id || loadingRequest"
                  >
                    Add @if (loadingRequest) {
                    <i class="spin medium loader-margin"></i>
                    }
                  </app-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-create-playlist-modal
  #createPlaylistModal
  (success)="onCreatePlaylistSuccess($event)"
  (error)="onCreatePlaylistError($event)"
></app-create-playlist-modal>
<app-song-status-modal
  #songStatusModalComponent
  [tracks]="this.tracks"
  (restrictionsAgreed)="showModalPlaylist()"
>
</app-song-status-modal>
