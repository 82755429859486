import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TruncatePipe } from 'src/app/angular/shared/pipes/truncate.pipe';

@Component({
  standalone: true,
  imports: [CommonModule, TruncatePipe],
  selector: 'app-song-restriction-modal',
  templateUrl: './song-restriction-modal.component.html',
  styleUrls: ['./song-restriction-modal.component.scss'],
})
export class SongRestrictionModalComponent implements OnInit {
  @ViewChild('songRestrictionModal', { static: true }) modalTemplate: TemplateRef<any>;
  private modalRef: BsModalRef;
  @Input()
  public track;

  @Output()
  public restrictionsAgreed = new EventEmitter();

  @Input() isLicenseProcess: boolean = true;

  constructor(private modalService: BsModalService) {}

  ngOnInit() {}

  public showModal() {
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
  }

  public agreeRestrictions() {
    this.restrictionsAgreed.emit();
    this.modalRef.hide();
  }

  public hideModal() {
    this.modalRef.hide();
  }
}
