<div class="flex flex-col gap-[2px] mb-1">
  <label class="font-secondary text-[14px] lg:text-base text-primary normal-case"
    >{{ label() }}
    @if (required()) {
    <span>*</span>
    }
  </label>
  <div class="relative flex items-center">
    <textarea
      class="sos-scrollbar-none"
      [rows]="rows()"
      [formControl]="control()"
      [placeholder]="placeholder()"
      class="font-secondary w-full py-[5px] pl-5 rounded-2xl border min-h-[40px]
      text-[16px] placeholder:text-[16px] placeholder:font-secondary pr-5 {{ dynamicStyles() }}"
    ></textarea>
  </div>
  @if(!hideErrors()) {
  <app-chip
    type="error"
    [ngClass]="control().touched && messageError() ? 'opacity-100' : 'opacity-0'"
    >{{ messageError() + '.' }}</app-chip
  >
  }
</div>
