import { transition, trigger, useAnimation } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router, RouterLink } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ArtistService, AuthenticationService } from 'sostereo-services';
import { inOutAnimation, outInAnimation } from '../../shared/animations/in-out.animation';
import { CommonService } from '../../shared/services/common.service';
import { PlaylistService } from '../../shared/services/playlist.service';
import { TrackingService } from '../../shared/services/tracking.service';
import {
  artistsFooterOptions,
  brandsFooterOptions,
  brandsFooterOptionsNoHome,
  footerOptions,
} from './config/mobile-footer.config';
import { BriefSubmissionModalComponent } from '../../modules/modals/components/brief-submission-modal/brief-submission-modal.component';
import { NgFor, NgClass, NgStyle, NgIf } from '@angular/common';
@Component({
  selector: 'app-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.scss'],
  animations: [
    trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))]),
    trigger('outInAnimation', [transition(':leave', useAnimation(outInAnimation))]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, RouterLink, NgClass, NgStyle, NgIf, BriefSubmissionModalComponent],
})
export class MobileFooterComponent implements OnInit, OnDestroy {
  @ViewChild('briefSubmissionModalComponent', { static: true }) briefSubmissionModalComponent;
  private isArtist = this.commonService.isAllowed(['GetArtist'], ['ListArtists', 'UpdateTier']);
  private isLabel = this.commonService.isAllowed(['ListArtists'], ['UpdateTier']);
  private subdomain = this.commonService.getSubdomain();
  private tenantId = this.commonService.tenantId;
  public footerOptions =
    (this.isArtist || this.isLabel) && !this.subdomain
      ? artistsFooterOptions
      : this.subdomain && this.environment.name === 'sostereo'
      ? brandsFooterOptions
      : footerOptions;

  public currentRoute: string;
  playlistsCount: number;
  private getPlaylistsCount: BehaviorSubject<number> = this.commonService.playlistsCount;
  private loggedUser = this.authenticationService.getCurrentUser();
  private getPlaylistsCount$: Subscription;
  public completeProfilePerc = '0%';
  public company: any;

  constructor(
    private trackingService: TrackingService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private playlistService: PlaylistService,
    private artistService: ArtistService,
    @Inject('environment') private environment,
  ) {
    this.routerSubscribe();
    this.setCompany();
  }

  ngOnDestroy(): void {
    this.getPlaylistsCount$?.unsubscribe();
  }

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.commonService.getPlaylistsCount();
    if (this.loggedUser) {
      this.getPlaylistsCount$ = this.getPlaylistsCount.subscribe((count) => {
        console.log(
          'Update playlist count >>> ',
          count,
          this.commonService.firstPlaylist.getValue(),
        );
        this.playlistsCount = count;
        this.cd.detectChanges();
      });
      if (this.isArtist || this.isLabel) {
        this.artistService.me().subscribe((res) => {
          this.completeProfilePerc = `${this.commonService.setCompleteProfilePerc(res.data)}%`;
          this.cd.detectChanges();
        });
      }
    }
  }

  private setCompany() {
    if (this.subdomain) {
      this.commonService.getCompany(this.subdomain, (company) => {
        this.company = company;
        if (company?.theme?.sidebarMenu[0]) {
          this.company = company;
          this.footerOptions[0] = {
            name: company?.theme?.sidebarMenu[0].label,
            icon: company?.theme?.sidebarMenu[0].icon,
            route: null,
          };
          this.cd.detectChanges();
        } else if (company) {
          this.footerOptions = brandsFooterOptionsNoHome;
          this.cd.detectChanges();
        }
      });
    }
  }

  private routerSubscribe() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.currentRoute = event.url;
        this.cd.detectChanges();
      }
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.cd.detectChanges();
        this.router.navigated = false;
      }
    });
  }

  goTo(option) {
    this.trackingService.track(
      'Footer navigation',
      {
        name: option?.name,
        route: option?.route,
      },
      {
        event_action: 'Footer clicked',
        event_type: 'Footer Click',
        event_value: option.name,
      },
    );
    this.currentRoute = this.router.url;
    if (option.name === 'Send Brief') {
      this.briefSubmissionModalComponent.showModal();
      return;
    }
    if (this.company?.theme?.sidebarMenu[0] && this.footerOptions[0].name === option.name) {
      window.location.href = this.company?.theme?.sidebarMenu[0].url;
    }
    const route = [option?.route];
    if (option?.route === '/discover' || option?.route === '/search') {
      this.playlistService.playlistMenuContainer.next({
        display: false,
      });
    }
    this.router.navigate(route);
  }

  setSelectedOption(option) {
    return this.router.url === option.route;
  }
}
