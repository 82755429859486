<button
  [type]="typeBtn()"
  class="flex items-center justify-center gap-[5px] sos-btn-font sos-disabled-btn w-max {{
    btnClasses[classGroup()]
  }} {{ styles() }}"
  [ngClass]="{ '!w-full': width() === 'full' }"
  [disabled]="loading() || disabled()"
  (click)="onClick()"
>
  <ng-content></ng-content>
  @if (loading()) {
  <i class="spin medium loader-margin ml-1.5"></i>
  }
</button>
