<ng-template #copyPlaylistModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="modal-body" *ngIf="!requestSuccess" [@inOutAnimation]>
      <div class="row">
        <div class="col-md-12">
          <div class="clearfix mb-5 text-center">
            <h3 class="modal-title mb-0">{{ modalTitle }}</h3>
            <p class="mt-5">Make a copy of this playlist to your library</p>
          </div>
        </div>
      </div>
      <form [formGroup]="copyPlaylistForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="email">Name </label>
          <input
            type="text"
            formControlName="name"
            class="form-control sos-standard-input"
            id="email"
          />
          <div
            *ngIf="
              copyPlaylistForm.controls['name'].touched && copyPlaylistForm.controls['name'].errors
            "
          >
            <app-inline-errors [errors]="['this field is required']"></app-inline-errors>
          </div>
        </div>
        <div class="form-group" *ngIf="showCopyComments">
          <label>Copy Comments?</label>
          <div class="radio pb-1 pt-1">
            <label>
              <input class="block" type="radio" formControlName="copyComments" value="true" />
              <span> Yes </span>
            </label>
            <label class="mt-2">
              <input class="block" type="radio" formControlName="copyComments" value="false" />
              <span> No </span>
            </label>
          </div>
        </div>
        <div class="clearfix">
          <div class="form-group w-full flex justify-center">
            <app-btn [disabled]="loadingRequest || copyPlaylistForm.invalid" typeBtn="submit">
              Copy @if (loadingRequest) { <i class="spin medium"></i>}
            </app-btn>
          </div>
          <div *ngFor="let alert of alerts">
            <alert
              [type]="alert.type"
              [dismissOnTimeout]="alert.timeout"
              (onClosed)="onAlertClosed(alert)"
              >{{ alert.message }}</alert
            >
          </div>
        </div>
      </form>
    </div>
    <div class="modal-body" *ngIf="requestSuccess" [@inOutAnimation]>
      <div class="row text-center">
        <div class="col-md-12">
          <h4 class="modal-title text-center">
            <span style="font-size: 70px" class="glyphicon glyphicon-ok-circle font-20x"></span>
          </h4>
          <h3>Playlist copied successfully!</h3>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <p>Now you can see it in your playlists</p>
          <br /><br />
        </div>
      </div>
      <div class="flex justify-center w-full">
        <app-btn typeBtn="submit" (click)="hideModal()"> Ok </app-btn>
      </div>
    </div>
  </div>
</ng-template>
