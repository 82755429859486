<div (clickOutside)="openDropdown = false" class="tags-input-dropdown">
  <div class="tag-container rounded-[16px]">
    @for (tag of tags;track $index) {
    <div class="tag rounded-[16px]">
      {{ tag }}
      <span class="tag-close" (click)="removeTag(tag)">x</span>
    </div>
    }
    <input
      type="text"
      class="tag-input text-primary font-secondary text-[14px] lg:text-base normal-case my-[2px]"
      placeholder="{{ placeholder }}"
      (keyup)="onSelectChange($event)"
      (keydown)="preventValues?.length > 0 ? preventValue($event) : ''"
      (keydown.enter)="addTagFromOption({ id: 'custom', name: typedData })"
      [(ngModel)]="typedData"
      (click)="openDropdown = true"
    />
  </div>
  @if (openDropdown) {
  <div class="dropdown-container rounded-[16px] sos-scrollbar-none">
    @if (typedData !== '') {
    <p
      class="options-section new-tag"
      (click)="addTagFromOption({ id: 'custom', name: typedData })"
    >
      Enter custom value <span class="fw600 color-dark">{{ typedData }}</span>
    </p>
    } @for ( option of options; track $index) { @if (option.visible) {
    <p class="options-section" (click)="addTagFromOption(option)">
      {{ option.name }}
    </p>
    } }
    <p class="typing-info">Type exactly what you need</p>
  </div>
  } @if (!hideErrors()) {
  <app-chip
    type="error"
    [ngClass]="touched() && errorMessage() ? 'opacity-100 visible' : 'opacity-0 invisible'"
    >{{ errorMessage() + '.' }}</app-chip
  >
  }
</div>
