<div class="relative" [class]="backgroundColor()">
  <app-main-nav [blueHeaderView]="currentUrl().includes('/who-we-are')" />
  <router-outlet />
  <div
    [ngClass]="{
      'lg:w-[calc(100%-138px)] lg:float-right':
        currentUrl().includes('/discover') || currentUrl().includes('/search'),
    }"
  >
    <app-get-started
      [shapeColor]="shapeColor()"
      [backgroundColor]="backgroundColor()"
    ></app-get-started>
    <app-footer></app-footer>
  </div>
</div>
