import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  HostListener,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { clone } from 'lodash-es';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation, outInAnimation } from '../../animations/in-out.animation';
import { TabsetComponent, TabsetConfig, TabsModule } from 'ngx-bootstrap/tabs';
import { CommonService } from '../../../shared/services/common.service';
import moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown-ivy';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FieldTableComponent } from './field-table/field-table.component';
import { HideExtensionPipe } from '../../pipes/hide-extension.pipe';
import { BtnComponent } from 'src/app/components/btn/btn.component';

declare let $: any;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TabsModule,
    AngularMultiSelectModule,
    InfiniteScrollModule,
    DragDropModule,
    NgxPaginationModule,
    PaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule,
    FieldTableComponent,
    HideExtensionPipe,
    BtnComponent,
  ],
  providers: [TabsetConfig, PaginationConfig],
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))]),
    trigger('outInAnimation', [transition(':leave', useAnimation(outInAnimation))]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() tabs = [];
  @Input() modelFilterDropdown: any;
  /* Tab Object Model:
      tab: {
        tableId: string
        title: string
        settings: {
          paging: {
            limit: number
            page: number
            totalItems: number
            totalPages: number
          }
          sorting: {
              createdAt: 'desc' // initial sorting
          },
          columns: [
            {
              field: string
              placeholder: string
              title: string
              sortable: string
              show: boolean
              icon: string
              filter: {fileName: 'text'}
              dataType: string
              action: string/functionName
              ...
            }
          ]
        }
      }
    */
  @Input() hideHeader: boolean;
  @Input() dragDropComponent: boolean;
  @Input() height: number;
  @Input() completePage: boolean;
  @Input() noMTop: boolean;
  @Input() customPR: string; // custom padding-right
  @Input() minWidth: string;
  @Input() selectedRowKey: boolean;
  @Input() slugOrId: string;
  @Output() tableFilter = new EventEmitter();
  @Output() tableSort = new EventEmitter();
  @Output() tableScroll = new EventEmitter();
  @Output() tableScrollUp = new EventEmitter();
  @Output() tabChange = new EventEmitter();
  @Output() rowSelected = new EventEmitter();
  @Output() rowAction = new EventEmitter();
  @Output() rowRemoved = new EventEmitter();
  @Output() dragStarted = new EventEmitter();
  @Output() droppedRowInTab = new EventEmitter();
  @Output() droppedRow = new EventEmitter();
  @Output() rowInputChange = new EventEmitter();
  @Output() pageChange = new EventEmitter();
  @Output() limitChange = new EventEmitter();
  @Output() bulkSaved = new EventEmitter();
  @Output() bulkCanceled = new EventEmitter();
  @Output() bulkOpened = new EventEmitter();
  @Output() typeaheadChange = new EventEmitter();
  @Output() typeaheadSelect = new EventEmitter();
  @Output() objectsArrayInputChange = new EventEmitter();
  @Output() objectsArrayRowAction = new EventEmitter();
  @Output() rowMouseUp = new EventEmitter();
  @Output() dropListDropped = new EventEmitter();
  @Output() newElement = new EventEmitter();
  @Output() dropdownSelected = new EventEmitter();
  @ViewChild('tablesTabSet', { static: true }) tableTabSet: TabsetComponent;
  public loadingRows = Array(50).fill('loading');
  public tableHeight: number;
  private isDragging: boolean;
  private draggedRow: any;
  private isDragged = false;
  public dropdwonModel = [];
  public dropdownSettings = {
    singleSelection: false,
    text: 'Choose an option',
    enableFilterSelectAll: false,
    enableCheckAll: false,
    badgeShowLimit: 1,
  };

  private selectedElements = { tab: '', index: 0 };

  public dropdownSettingsMinimalist = {
    singleSelection: false,
    text: 'Filter by status',
    enableFilterSelectAll: false,
    enableCheckAll: false,
    badgeShowLimit: 1,
    limitSelection: 1,
  };

  moment: any = moment;

  public isMobile = this.deviceDetectorService.isMobile();
  public isTablet = this.deviceDetectorService.isTablet();
  private routerEvents$: Subscription;

  constructor(
    public commonService: CommonService,
    public deviceDetectorService: DeviceDetectorService,
    public router: Router,
  ) {
    this.isDragging = false;
    this.routerEvents$ = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.slugOrId) {
        this.tabs[0]?.dataTable?.forEach((pl) =>
          pl._id === this.slugOrId || pl.slug === this.slugOrId
            ? (pl.hover = true)
            : delete pl.hover,
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.routerEvents$.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.completePage) {
      this.tableHeight = this.height;
    } else {
      this.tableHeight = (window.innerHeight * this.height) / 100;
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.selectedRowKey) {
      switch (event.key) {
        case 'ArrowUp': // 'ArrowUp'
          this.selectPrevItem();
          break;
        case 'ArrowDown': // 'ArrowDown'
          this.selectNextItem();
          break;
      }
    }
  }

  selectPrevItem() {
    if (this.selectedElements.index > 0) {
      const indexTab = this.tabs.findIndex((tab) => tab.tableId === this.selectedElements.tab);
      const tab = this.tabs[indexTab > -1 ? indexTab : 0];
      const i = this.selectedElements.index - 1;
      const row = document.getElementsByTagName('tr')[i + 1];
      document.getElementsByTagName('tbody')[0].scrollTop =
        (row?.offsetTop || 0) - row.offsetHeight;
      this.onRowSelected(tab, i, tab.dataTable[i]);
    }
  }

  selectNextItem() {
    const indexTab = this.tabs.findIndex((tab) => tab.tableId === this.selectedElements.tab);
    const tab = this.tabs[indexTab > -1 ? indexTab : 0];
    if (this.selectedElements.index < tab.dataTable?.length - 1) {
      const i = this.selectedElements.index + 1;
      const row = document.getElementsByTagName('tr')[i - 1];
      document.getElementsByTagName('tbody')[0].scrollTop = row.offsetTop;
      this.onRowSelected(tab, i, tab.dataTable[i]);
    }
  }

  ngOnInit() {
    if (this.tabs[0]) {
      this.tabs[0].settings?.columns.forEach((col) => {
        if (col.hasOwnProperty('allowed')) {
          col.show = this.commonService.isAllowed(col.allowed, col.disallowed);
        }
      });
      this.tabs[0].settings?.mobileCard?.body.forEach((col) => {
        if (col.hasOwnProperty('allowed')) {
          col.show = this.commonService.isAllowed(col.allowed, col.disallowed);
        }
      });
    }
    if (
      this.slugOrId &&
      this.tabs[0]?.dataTable?.find((pl) => pl._id === this.slugOrId || pl.slug === this.slugOrId)
    ) {
      const selectedPl = this.tabs[0]?.dataTable?.find(
        (pl) => pl._id === this.slugOrId || pl.slug === this.slugOrId,
      );
      selectedPl.hover = true;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.completePage) {
        this.tableHeight = this.height;
      } else {
        this.tableHeight = (window.innerHeight * this.height) / 100;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const height: SimpleChange = changes.height;
    const tabs: SimpleChange = changes.tabs;
    if (height && height.currentValue !== height.previousValue) {
      this.height = height.currentValue;
      setTimeout(() => {
        if (this.completePage) {
          this.tableHeight = this.height;
        } else {
          this.tableHeight = (window.innerHeight * this.height) / 100;
        }
      });
    }
    /* 
      This is when in /spaces/review/:id a user drags a song to another tab
      to prevent that the first tab initializes to the first payload
    */
    if (
      this.isDragged &&
      location.href.includes('/spaces/review/') &&
      tabs &&
      tabs.currentValue !== tabs.previousValue &&
      tabs.previousValue &&
      tabs.previousValue[0]?.title === 'Submissions'
    ) {
      this.isDragged = false;
      this.tabs[0] = tabs.previousValue[0];
    }
  }

  // this is supposed to be used by parent component to enable drag and drop to a tab
  public setDragAndDropTab(tabId) {
    const tabLink = `${tabId}-link`;
    setTimeout(() => {
      const htmlTab = document.getElementById(tabLink);
      if (htmlTab && !htmlTab.getAttribute('dropevent')) {
        htmlTab.setAttribute('dropevent', 'true');
        htmlTab.addEventListener('mouseup', () => {
          if (this.draggedRow) {
            this.isDragged = true;
            const tab = this.tabs.find((t) => t.tableId === tabId);
            this.droppedRowInTab.emit({ tab: tab, tableRow: this.draggedRow });
            this.draggedRow = undefined;
          }
        });
      }
    });
  }

  getRowActionEmit({ tableId, col, rowData, rowIndex, option }) {
    this.getRowAction(tableId, col, rowData, rowIndex, option);
  }

  getRowAction(tableId, col, rowData, rowIndex, option?) {
    const rowEmitted = clone(rowData);
    delete rowEmitted.hover;
    if (col.field === 'remove') {
      this.rowRemoved.emit({ id: tableId, col: col, tableRow: rowEmitted });
    } else if (col.field === 'menu') {
      this.dropdownSelected.emit({ id: tableId, col: col, tableRow: rowEmitted, option });
    } else {
      this.rowAction.emit({
        id: tableId,
        col: col,
        tableRow: rowEmitted,
        rowData: rowEmitted,
        rowIndex: rowIndex,
        option,
      });
    }
  }

  sortByColumn(tab, colField, orientation) {
    this.tableSort.emit({ tableId: tab.tableId, field: colField, orientation: orientation });
  }

  onTabScroll(tab) {
    this.tableScroll.emit({ tableId: tab.tableId });
  }

  onTabScrollUp(tab) {
    this.tableScrollUp.emit({ tableId: tab.tableId });
  }

  filterByColumn(event: any, tab, colField, col?) {
    const searchValue = event.target?.value || event;
    this.tableFilter.emit({
      tableId: tab.tableId,
      field: colField,
      searchValue: searchValue,
      filter: col ? col.filter : null,
    });
  }

  toggleEdition(tab) {
    tab.settings.isEditing = !tab.settings.isEditing;
    if (tab.settings.isEditing) {
      this.bulkOpened.emit({ tableId: tab.tableId });
    } else {
      this.bulkCanceled.emit({ tableId: tab.tableId });
    }
  }

  saveBulk(tableId) {
    this.bulkSaved.emit({ tableId: tableId });
  }

  onRowSelected(tab, i, rowData) {
    this.selectedElements.index = i;
    this.selectedElements.tab = tab.tableId;
    tab.dataTable.forEach((r) => {
      delete r.hover;
    });
    rowData.hover = true;
    const rowEmitted = clone(rowData);
    delete rowEmitted.hover;
    this.rowSelected.emit({ tableId: tab.tableId, i, rowData: rowEmitted });
  }

  onSelectedTab(tab, index) {
    this.tabChange.emit({ tab: tab, index: index });
  }

  onDropListDropped(tab, cdkDropEvent: CdkDragDrop<string[]>) {
    this.dropListDropped.emit({ tableId: tab.tableId, cdkDropEvent });
  }

  onDragStarted(data) {
    this.dragStarted.emit();
    this.draggedRow = data;
  }

  onDragDropped() {
    this.droppedRow.emit();
    setTimeout(() => {
      this.draggedRow = undefined;
    });
  }

  onInputChange(inputEvent, tableId, col, tableRow, rowIndex) {
    inputEvent.stopPropagation();
    tableRow[col.field] = inputEvent.target.value;
    this.rowInputChange.emit({
      inputEvent: inputEvent,
      tableId: tableId,
      col: col,
      tableRow: tableRow,
      rowIndex: rowIndex,
    });
  }

  onPageChanged(event) {
    this.pageChange.emit(event);
  }

  changeLimit(count) {
    this.limitChange.emit({ limit: count });
  }

  onRowMouseUp(event, tableRow) {
    this.rowMouseUp.emit({ event, tableRow });
  }

  newElementList() {
    this.newElement.emit();
  }

  getTextDropdown(data, col): string {
    if (col.showitemSelected != undefined) {
      return col.showitemSelected
        ? col.objType
          ? data[col.field].label
          : data[col.field] || ''
        : '';
    }
    return col.objType ? data[col.field].label : data[col.field] || '';
  }

  openDropdown($event, i) {
    $('#dropdown' + i).toggleClass('open');
    $event.stopPropagation();
  }

  public onFilterSelected(event, tab, colField, col) {
    const filter: any = this.dropdwonModel.map((dr) => dr.id).toString();
    this.filterByColumn(filter, tab, colField, col);
  }

  public onFilterDeselected(event, tab, colField, col) {
    const filter: any = this.dropdwonModel.map((dr) => dr.id).toString();
    this.filterByColumn(filter, tab, colField, col);
  }

  public onDropdownSelected(event, model, field, settings) {
    // This is to only select one
    if (settings?.singleSelection) {
      this.modelFilterDropdown[model] = [event];
    }
    this.filterDropdown({
      searchValue: this.modelFilterDropdown[model].map((tr) => tr.id).toString(),
      field: field,
    });
  }

  public onDropdownDeselected(event, model, field) {
    this.filterDropdown({
      searchValue: this.modelFilterDropdown[model].map((tr) => tr.id).toString(),
      field: field,
    });
  }

  filterDropdown(event: any) {
    this.tableFilter.emit({ field: event.field, searchValue: event.searchValue });
  }

  public hasOriginalFile(files: any) {
    return !files.original?.toLowerCase().includes('.mp3') || false;
  }

  public onHover(rowData) {
    rowData.hovered = true;
  }

  public onHoverOut(rowData) {
    rowData.hovered = false;
  }
}
