import { Injectable, signal } from '@angular/core';
import { CompanyService } from 'sostereo-services';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalCompanyStateService {
  private currentCompany = signal<any>(null);

  constructor(private companyService: CompanyService, private trackingService: TrackingService) {}

  // Fetches the global company and updates the signal
  fetchGlobalCompany(companySlug: string): void {
    this.companyService.getBySlug(companySlug).subscribe({
      next: (cRes) => {
        if (cRes.data) {
          this.currentCompany.set(cRes.data);
        } else {
          this.currentCompany.set({ error: 'No company found' });
        }
      },
      error: (error) => {
        this.trackingService.track('Error getting company', error);
        this.currentCompany.set(error);
      },
    });
  }

  // Getter to access the signal value
  get currentCompanySignal() {
    return this.currentCompany;
  }
}
