import { Component, OnInit, Inject, Renderer2, ElementRef, input } from '@angular/core';

@Component({
  selector: 'app-newsletter-form',
  standalone: true,
  imports: [],
  templateUrl: './newsletter-form.component.html',
  styleUrl: './newsletter-form.component.scss',
})
export class NewsletterFormComponent implements OnInit {
  email = input.required<string>();
  constructor(
    @Inject('environment') private environment,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.loadScript();
  }

  loadScript(): void {
    // Create a script element for the external HubSpot script
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.charset = 'utf-8';

    // Append the script to the component's element
    this.renderer.appendChild(this.el.nativeElement, script);

    // Listen for the script load event
    script.onload = () => {
      // Create another script element for the inline form creation script
      const inlineScript = this.renderer.createElement('script');
      inlineScript.type = 'text/javascript';
      inlineScript.text = `
        hbspt.forms.create({
          region: "na1",
          portalId: "46320836",
          formId: "77bf4eec-746d-4d78-a8d8-d17a15fa1a4c",
          target: "#hubspotFormContainer"
        });
      `;
      this.renderer.appendChild(this.el.nativeElement, inlineScript);

      setTimeout(() => {
        const inputElement = document.querySelector('#hubspotFormContainer input'); // Adjust the selector to target the specific input you need
        console.log('inputElement>>> ', inputElement);
        if (inputElement) {
          this.renderer.setProperty(inputElement, 'value', this.email());

          // Trigger the input event to notify HubSpot of the email input change
          const eventChange = new Event('input', { bubbles: true });
          inputElement.dispatchEvent(eventChange);

          this.renderer.listen(inputElement, 'keydown', (event: KeyboardEvent) => {
            event.stopPropagation();
          });
        }
      }, 500);
    };
  }
}
