import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BtnComponent } from 'src/app/components/btn/btn.component';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { RouterLinkWithHref } from '@angular/router';
import { BtnLinkComponent } from '../../../../components/btn/btn-link/btn-link.component';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { AuthenticationService } from 'sostereo-services';
import { NewsletterModalComponent } from 'src/app/angular/modules/modals/components/newsletter-modal/newsletter-modal.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    FormsModule,
    BtnComponent,
    LottieComponent,
    RouterLinkWithHref,
    BtnLinkComponent,
    NewsletterModalComponent,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @ViewChild('newslettertModalComponent', { static: true })
  newslettertModal: NewsletterModalComponent;

  private trackingService = inject(TrackingService);
  private authenticationService = inject(AuthenticationService);
  email: string = '';
  currentYear: number = new Date().getFullYear();
  currentUser = this.authenticationService.getCurrentUser();
  options: AnimationOptions = {
    path: '/assets/animations/lottie/Record(Symbol)/Record(Symbol).json',
    autoplay: false,
    loop: false,
  };

  styles: Partial<CSSStyleDeclaration> = {
    width: '100%',
    height: '100%',
  };

  subscribe() {
    if (this.email !== ' ') {
      this.newslettertModal.showModal(this.email);
    }
  }

  private animationItem: any;

  animationCreated(animationItem: any): void {
    this.animationItem = animationItem;
  }

  playAnimation(): void {
    if (this.animationItem) {
      this.animationItem.goToAndStop(0, true);
      this.animationItem.play();
    }
  }

  public trackEvent(eventName, action, gaParams) {
    this.trackingService.track(
      eventName,
      {
        action: action,
        params: gaParams,
      },
      {
        ...gaParams,
      },
    );
  }
}
