<div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
  <div class="w-full flex justify-center lg:justify-end relative z-10">
    <app-btn
      (click)="hideModal()"
      class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
      [classGroup]="'squaredHover'"
    >
      <i class="material-icons hover:!text-primary"> close </i>
    </app-btn>
  </div>
  <div class="modal-content" id="genericModal">
    <div class="modal-body">
      <div class="row text-center">
        <div class="col-md-12">
          <img
            *ngIf="dataInfo?.img"
            src="./assets/images/{{ dataInfo.img }}"
            alt="{{ dataInfo.img }}"
            style="height: 150px"
            class="mb-2.5"
          />
          <h3 class="modal-title text-center" *ngIf="dataInfo.cssClass || dataInfo.googleIcon">
            <span style="font-size: 70px" class="{{ dataInfo.cssClass }}">{{
              dataInfo.googleIcon || ''
            }}</span>
          </h3>
          <h3 class="mb-4 mt-2" [ngClass]="{ 'large-section-title': dataInfo.largeSection }">
            {{ dataInfo.title }}
          </h3>
        </div>
      </div>
      <p *ngIf="dataInfo.largeSection" class="large-section-p">
        {{ dataInfo.message | hideExtension }}
      </p>
      <div class="row text-center">
        <div class="col-md-12">
          <p *ngIf="!dataInfo.largeSection">{{ dataInfo.message | hideExtension }}</p>
          <p
            *ngIf="!dataInfo.isCopyUrl && dataInfo.auxMessage"
            class="{{ dataInfo.cssAuxClass }}"
            style="display: inline-block"
          >
            {{ dataInfo.auxMessage }}
          </p>
          <span
            *ngIf="dataInfo.auxIcon"
            class="material-icons"
            [ngStyle]="dataInfo.auxIconStyles || {}"
            [tooltip]="dataInfo.auxIconMessage || ''"
            [attr.data-icon]="dataInfo.auxIcon"
          >
          </span>
          <div
            *ngIf="dataInfo.isCopyUrl"
            class="input-group center mt-2.5"
            (click)="copyToClipboard(dataInfo.auxMessage)"
          >
            <input
              data-hj-allow
              type="text"
              class="form-control"
              id="basic-url"
              value="{{ dataInfo.auxMessage }}"
              disabled
            />
            <span class="input-group-addon" id="copyBtn">{{ textCopied ? 'Copied' : 'Copy' }}</span>
          </div>
          <br /><br />
          <button
            class="btn mr-2.5 {{ btn.cssClass }}"
            *ngFor="let btn of dataInfo.btns"
            (click)="getBtnAction(btn)"
          >
            {{ btn.title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
