import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { Router } from '@angular/router';
import { AuthenticationService, SignUpService } from 'sostereo-services';
import { isEmpty, each } from 'lodash-es';
import { GenericModalService } from 'src/app/angular/shared/services/generic-modal.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BtnComponent } from 'src/app/components/btn/btn.component';
import { InputFieldComponent } from 'src/app/components/input-field/input-field.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-user-sign-up-modal',
  templateUrl: './user-sign-up-modal.component.html',
  styleUrls: ['./user-sign-up-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, BtnComponent, InputFieldComponent],
})
export class UserSignUpModalComponent implements OnInit {
  @ViewChild('userSignUpModal', { static: true }) modalTemplate: TemplateRef<any>;
  private modalRef: MatDialogRef<any, any>;
  private token: string;
  private userInfo: any;
  signUpForm: FormGroup;
  signUpLoading: boolean;
  errors: any;
  generalError = '';

  constructor(
    private modalService: MatDialog,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private signUpService: SignUpService,
    private trackingService: TrackingService,
    private router: Router,
    private genericModalService: GenericModalService,
  ) {
    this.createForm();
    this.signUpLoading = false;
  }

  ngOnInit() {}

  private createForm() {
    this.signUpForm = this.formBuilder.group({
      email: new FormControl(null),
      password: new FormControl(null),
      confirmation: new FormControl(null),
    });
  }

  public signUp() {
    this.signUpLoading = true;
    const data = {
      token: this.token,
      userId: this.userInfo.id,
      username: this.signUpForm.controls.email.value,
      password: this.signUpForm.controls.password.value,
    };
    this.errors = this.validateSignUp(this.signUpForm.value);
    this.generalError = '';
    if (isEmpty(this.errors)) {
      this.signUpService.userSignUp(data).subscribe(
        (sRes) => {
          const credentials = {
            username: data.username,
            password: data.password,
          };
          const userData = sRes.data;
          if (userData.redirect && !!userData.redirect.url && userData.redirect.token) {
            window.location.href = `${userData.redirect.url}/auth/${userData.redirect.token}`;
            return;
          }
          this.authenticationService.login(credentials).subscribe(
            (res) => {
              this.authenticationService.setCurrentUser(res.data);
              this.hideModal();
              if (res.redirect && res.redirect === 'main.views.admin.admin-artists-edition') {
                this.router.navigateByUrl('/artists/list');
              } else {
                this.router.navigateByUrl('/');
              }
            },
            (lError) => {
              this.trackingService.track('User sign up invitation Error login existing user', {
                error: lError,
                userData: data,
              });
              this.signUpLoading = false;
              this.generalError =
                lError?.error?.message || lError?.message || 'There was an error, try again';
            },
          );
        },
        (err) => {
          this.trackingService.track('Error user sign up invitation', {
            error: err,
            userData: data,
          });
          this.signUpLoading = false;
          this.generalError =
            err?.error?.message || err?.message || 'There was an error, try again';
        },
      );
    } else {
      this.signUpLoading = false;
    }
  }

  validateSignUp(params) {
    const errors: any = {},
      fields = [
        {
          field: 'password',
          fieldVal: 'Password',
        },
        {
          field: 'confirmation',
          fieldVal: 'Password Confirmation',
        },
      ];
    if (!params) {
      each(fields, function (key) {
        if (!errors[key.field]) {
          errors[key.field] = [];
        }
        errors[key.field].push('Please enter the ' + key.fieldVal);
      });
    } else {
      each(fields, function (key) {
        if (!params[key.field] || isEmpty(params[key.field])) {
          if (!errors[key.field]) {
            errors[key.field] = [];
          }
          errors[key.field].push('Please enter the ' + key.fieldVal);
        }
      });
      if (params.password !== params.confirmation) {
        errors.confirmation = [];
        errors.confirmation.push("Password and Password Confirmation don't match.");
      }
    }
    return errors;
  }

  public showModal(token) {
    this.token = token;
    this.signUpService.getUserBySignUpToken(this.token).subscribe({
      next: (res) => {
        console.log(res);
        this.userInfo = res.data;
        this.signUpForm.controls.email.setValue(this.userInfo.email);
        this.signUpForm.controls.email.disable();
        this.modalRef = this.modalService.open(this.modalTemplate, {
          panelClass: ['modal', 'material'],
          position: { top: '10vh' },
          disableClose: true,
          maxWidth: 'min(90vw, 400px)',
          role: 'dialog',
          closeOnNavigation: true,
          ariaModal: true,
        });
      },
      error: () => {
        const dataInfo = {
          title: 'Invalid Token',
          message: 'Contact us at hello@sostereo.com to send you another invitation.',
        };
        this.genericModalService.showModal(dataInfo);
        this.hideModal();
      },
    });
  }

  public hideModal() {
    this.signUpForm.reset();
    if (this.modalRef) {
      this.modalRef.close('closed');
    }
  }

  getControl(name): FormControl {
    return this.signUpForm.get(name) as FormControl;
  }
}
