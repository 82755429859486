<div class="text-primary">
  @if(showProgressBar()){
  <div>
    @for (progress of getProgressBars(); track $index) {
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        [ngStyle]="{ width: progressBars[progress] + '%' }"
      ></div>
    </div>
    }
  </div>
  }
  <div>
    @if(!uploadFolder){
    <div class="center-block">
      <div
        ngfDrop
        selectable="1"
        (click)="onOpenedFolder()"
        (drop)="onDroppedFile()"
        [(validDrag)]="validComboDrag"
        [(files)]="files"
        [(lastInvalids)]="lastInvalids"
        accept="{{ fileAccept }}"
        class="well my-drop-zone"
        [class.invalid-drag]="validComboDrag === false"
        [class.valid-drag]="validComboDrag"
        (filesChange)="getFiles(files)"
      >
        @if (showDescription) {
        <p class="mb-0">Drag & Drop</p>
        <p class="mb-0">
          Drag and drop any {{ fileText }} or
          <a class="text-secondary hover:text-primary">click here to Upload</a>
        </p>
        }@else {
        <p>
          Drag and drop or <a class="text-secondary hover:text-primary">click here to Upload</a>
        </p>
        }
      </div>
    </div>
    }@else {
    <div class="center-block">
      <div class="well my-drop-zone" (click)="folderInput.click()">
        <input
          style="display: none"
          #folderInput
          type="file"
          (change)="getFiles(folderInput.files)"
          webkitDirectory
          mozdirectory
        />
        @if(showDescription){
        <p class="mb-0">
          Click <a class="underline hover:underline">here</a> to upload a folder that contains WAV,
          AIF or MP3 files
        </p>
        <p class="mb-0">(Make sure is a folder and not a file)</p>
        }
      </div>
    </div>
    }
  </div>
</div>
