import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { SostereoServicesModule } from 'sostereo-services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieConsentComponent } from './angular/layout/components/cookie-consent/cookie-consent.component';
import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/http-config.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { SpacesHeaderComponent } from './angular/layout/components/spaces-header/spaces-header.component';
import { PlayerWidgetComponent } from './angular/layout/components/player-widget/player-widget.component';
import { CurrencyPipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { TechnicalIssuesComponent } from './components/technical-issues/technical-issues.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SessionButtonsComponent } from './angular/layout/components/session-buttons/session-buttons.component';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import packageJson from 'package.json';
import { DeviceDetectorService } from 'ngx-device-detector';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AppRouteReuseStrategy } from './url-handling/app-route-reuse-strategy';
import { UserSignUpModalComponent } from './angular/modules/modals/components/user-sign-up-modal/user-sign-up-modal.component';
import { SessionExpiredModalComponent } from './angular/modules/modals/components/session-expired-modal/session-expired-modal.component';
import { WavesurferTimeFormatPipe } from './angular/shared/pipes/wavesurfer-time-format.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SidenavComponent } from './angular/modules/shared-layout/components/sidenav/sidenav.component';
import { SearchSidebarComponent } from './angular/modules/shared-layout/components/search-sidebar/search-sidebar.component';
import { provideLottieOptions } from 'ngx-lottie';
import { MobileFooterComponent } from './angular/layout/mobile-footer/mobile-footer.component';

const version: string = packageJson?.version;

Sentry.init({
  dsn: environment.sentryDsn,
  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  environment: environment.production ? 'production' : 'staging',
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  release: version,
});

export function appInitializerFactory(): () => void {
  return () => {
    const loader = document.getElementById('init-sostereo-app');
    if (loader) {
      loader.style.display = 'none';
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    SostereoServicesModule.forRoot(environment),
    ModalModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    HttpClientJsonpModule,
    TooltipModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      registrationStrategy: 'registerImmediately',
    }),
    CookieConsentComponent,
    SpacesHeaderComponent,
    PlayerWidgetComponent,
    SessionButtonsComponent,
    TechnicalIssuesComponent,
    UserSignUpModalComponent,
    SessionExpiredModalComponent,
    BsDatepickerModule.forRoot(),
    SidenavComponent,
    SearchSidebarComponent,
    MobileFooterComponent,
  ],
  providers: [
    DeviceDetectorService,
    CurrencyPipe,
    TitleCasePipe,
    WavesurferTimeFormatPipe,
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: false,
        filter: (req) => !req.url.includes('api'),
      }),
    ),
    provideHttpClient(withFetch()),
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor(trace: TraceService) {}
}
