import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'msToTimeFormat',
  standalone: true,
})
export class MsToTimeFormatPipe implements PipeTransform {
  transform(milliseconds: number): string {
    if (isNaN(milliseconds) || milliseconds < 0) {
      return '00:00';
    }

    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  private pad(num: number): string {
    return num.toString().padStart(2, '0');
  }
}
