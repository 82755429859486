<ng-template #newslettertModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="sos-generic-modal-container sos-scrollbar-none">
      <app-newsletter-form [email]="email"></app-newsletter-form>
    </div>
  </div>
</ng-template>
