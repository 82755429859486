<ng-template #songStatusModal>
  <div class="modal-body">
    <div class="row ml-0 mr-0">
      <div class="col-md-12">
        <h3 class="modal-title">SONG STATUS CHECK</h3>
      </div>
    </div>
    <div class="row ml-0 mr-0 mt-8">
      <div class="col-md-12">
        <p style="font-size: 16px">
          <strong
            >Please check the song status before you continue with the license or playlist
            process</strong
          >
        </p>
      </div>
    </div>
    <div class="container-songs">
      <ng-container *ngFor="let song of elementsStatus; let artistsIndex = index">
        <div
          class="mt-4"
          [ngClass]="{
            'multiple-song': elementsStatus.length > 1,
            'single-song': elementsStatus.length === 1
          }"
        >
          <h5 class="row ml-0 mr-0 pl-4 pr-4">{{ tracks[artistsIndex].name }}</h5>
          <div
            class="row ml-0 mr-0 mt-4"
            [ngStyle]="{
              display: tracks[artistsIndex]?.restrictions?.length > 8 ? 'block' : 'flex'
            }"
            style="flex-wrap: wrap"
          >
            <ng-container *ngFor="let element of song">
              <div
                *ngIf="element.show"
                class="col-xs-12 flex items-center mb-5"
                [ngClass]="{
                  'col-md-6': elementsStatus.length === 1,
                  'col-md-4': elementsStatus.length > 1
                }"
              >
                <span
                  class="material-icons-outlined mr-2.5"
                  [attr.data-icon]="element.googleIcon"
                ></span>
                <div class="col-xs-12 pr-0">
                  <h4
                    class="item-title"
                    [ngStyle]="{
                      'text-transform': element.textTransform ? element.textTransform : 'capitalize'
                    }"
                  >
                    {{ element.title }}
                  </h4>
                  <ng-container *ngIf="element.field">
                    <div *ngIf="element.field === 'notes' && tracks[artistsIndex]?.notes">
                      {{ tracks[artistsIndex]?.notes }}
                    </div>
                    <ng-container *ngIf="element.field === 'restrictions'">
                      <div
                        *ngIf="
                          tracks[artistsIndex]?.restriction &&
                          !(tracks[artistsIndex]?.restrictions?.length > 0)
                        "
                      >
                        {{ tracks[artistsIndex]?.restriction }}
                      </div>
                      <ul
                        *ngIf="
                          tracks[artistsIndex]?.restrictions &&
                          tracks[artistsIndex]?.restrictions?.length > 0
                        "
                        class="pl-4"
                      >
                        <li
                          class="list-disc"
                          *ngFor="let restriction of tracks[artistsIndex].restrictions"
                        >
                          <p class="fw500">{{ restriction.name }}</p>
                          <p *ngIf="restriction.expirationDate">
                            Restricted until: {{ restriction.expirationDate | truncate : [10, ''] }}
                          </p>
                          <p *ngIf="restriction.details">Details: {{ restriction.details }}</p>
                        </li>
                      </ul>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row ml-0 mr-0">
      <div class="col-md-12">
        <button
          type="button"
          class="btn primary-btn mt-2.5 mb-2.5 ml-4 pull-right agree-btn"
          (click)="agreeRestrictions()"
        >
          Agree
        </button>
        <button type="button" class="btn cancel-btn mt-2.5 mb-2.5 pull-right" (click)="hideModal()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>
