<ng-template #accountRequestModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="sos-account-request-container sos-scrollbar-none !text-primary">
      @if (!requestSuccess) {
      <div class="overflow-auto bg-default relative p-[20px]" [@inOutAnimation]>
        <div>
          @if (showMusicForm) {
          <h4 class="text-4xl lg:text-2xl">Submit your Music</h4>
          <p class="mt-4 mb-1">
            (Only if you don't already have an account manager, if you do, please submit directly to
            them)
          </p>
          <p class="mb-6">
            Please fill in the form below and we'll get back to you once we review internally.
          </p>
          } @else {
          <h3 class="text-4xl lg:text-2xl mb-4">Thank you for your interest in SoStereo.</h3>
          <p>
            Right now we're not able to accept any new music submissions until June. We thank you
            for your interest in us.
          </p>
          <p class="mt-2.5">Best regards from the Music Team</p>
          }
        </div>
        @if (showMusicForm) {
        <form [formGroup]="accountRequestForm" class="mt-0">
          <div class="w-full">
            <div class="flex w-full gap-5">
              <app-input-field
                class="w-1/2"
                [label]="'First Name'"
                [control]="getControl('firstName')"
                [required]="true"
                [errors]="accountRequestForm.controls['firstName'].errors"
              ></app-input-field>
              <app-input-field
                class="w-1/2"
                [label]="'Last Name'"
                [control]="getControl('lastName')"
                [required]="true"
                [errors]="accountRequestForm.controls['lastName'].errors"
              ></app-input-field>
            </div>
            <app-input-field
              [label]="'Artist Name'"
              [control]="getControl('artistName')"
              [required]="true"
              [errors]="accountRequestForm.controls['artistName'].errors"
            ></app-input-field>
            <app-input-field
              [label]="'Your email'"
              [control]="getControl('email')"
              [required]="true"
              type="email"
              [errors]="accountRequestForm.controls['email'].errors"
            ></app-input-field>
            <app-input-field
              [label]="'Music Genre/Style'"
              [control]="getControl('musicGenre')"
              [required]="true"
              [errors]="accountRequestForm.controls['musicGenre'].errors"
            ></app-input-field>
            <app-input-field
              [label]="'Music link (Spotify, SoundCloud, etc.)'"
              [control]="getControl('musicLink')"
              [required]="true"
              [errors]="accountRequestForm.controls['musicLink'].errors"
            ></app-input-field>

            <div class="gap-4 flex flex-col mb-10">
              <div class="flex items-center">
                <input
                  style="margin: 3px 5px; float: left"
                  type="checkbox"
                  formControlName="labelAgreement"
                />
                <label
                  class="font-secondary text-[14px] lg:text-base text-primary normal-case m-0 pl-2"
                  >Do you have a current agreement with a record label?</label
                >
              </div>
              <div class="flex items-center">
                <input
                  style="margin: 3px 5px; float: left"
                  type="checkbox"
                  formControlName="labelAgreement"
                />
                <label
                  class="font-secondary text-[14px] lg:text-base text-primary normal-case m-0 pl-2"
                  >Are you a member of a PRO/collecting society?</label
                >
              </div>
              <div class="flex items-center">
                <input
                  style="margin: 3px 5px; float: left"
                  type="checkbox"
                  formControlName="companyAgreement"
                />
                <label
                  class="font-secondary text-[14px] lg:text-base text-primary normal-case m-0 pl-2"
                  >Do you have a current agreement with a publishing company?</label
                >
              </div>
            </div>
            <div class="flex flex-col mb-12">
              <label
                class="font-secondary text-[14px] lg:text-base text-primary normal-case mb-[15px]"
                >Role</label
              >
              <select class="text-[14px] lg:text-base" formControlName="role">
                <option value="" disabled selected>Choose your Role</option>
                <option value="manager">Manager</option>
                <option value="artist">Artist</option>
                <option value="publisher">Publisher</option>
                <option value="songwriter">Songwriter</option>
                <option value="labelManager">Label Manager</option>
              </select>
            </div>
            <app-text-area
              [label]="'How did you hear about us?'"
              [required]="true"
              [control]="getControl('referrer')"
              [rows]="4"
              [errors]="accountRequestForm.controls['referrer'].errors"
            ></app-text-area>
            <app-text-area
              [label]="'Any further information you\'d like to share?'"
              [control]="getControl('message')"
              [rows]="4"
              [errors]="accountRequestForm.controls['message'].errors"
            ></app-text-area>
          </div>
          <div class="flex justify-center mt-5">
            <app-btn
              (click)="sendForm()"
              [disabled]="
                loadingRequest || accountRequestForm.untouched || accountRequestForm.invalid
              "
              >Submit</app-btn
            >
          </div>
        </form>
        } @for (alert of alerts; let index = $index; track index) {
        <div class="mt-4">
          <alert
            [type]="alert.type"
            [dismissOnTimeout]="alert.timeout"
            (onClosed)="onAlertClosed(alert)"
            >{{ alert.message }}</alert
          >
        </div>
        }
      </div>
      } @else {
      <div class="flex flex-col w-full p-[20px]" [@inOutAnimation]>
        <div class="text-center">
          <h2 class="text-4xl lg:text-2xl">Thank you for submitting your music!</h2>
        </div>
        <p class="mt-5 text-center">
          Our A&R team will review. If we hear something we like and works well for use in
          Ads/TV/Film we'll get back to you.
        </p>
        <div class="flex justify-center mt-5">
          <app-btn (click)="hideModal()">Ok</app-btn>
        </div>
      </div>
      }
    </div>
  </div>
</ng-template>
