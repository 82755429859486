import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ViewChild,
  HostListener,
  PLATFORM_ID,
  AfterViewInit,
  AfterContentInit,
  signal,
  computed,
} from '@angular/core';
import { SeoService } from './angular/shared/services/seo.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TrackingService } from './angular/shared/services/tracking.service';
import { clone, isEmpty } from 'lodash-es';
import { options } from './angular/configs/main-layout.config';
import { ErrorHandlingService } from './angular/shared/services/error-handling.service';
import { AuthenticationService, IdentityService } from 'sostereo-services';
import { UserSignUpModalComponent } from './angular/modules/modals/components/user-sign-up-modal/user-sign-up-modal.component';
import { SessionExpiredModalComponent } from './angular/modules/modals/components/session-expired-modal/session-expired-modal.component';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { CommonService } from './angular/shared/services/common.service';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';
import { PlayerService } from './angular/shared/services/player.service';
import { ThemeService } from './angular/shared/services/theme.service';
import { finalize } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as Sentry from '@sentry/browser';
import { GenericModalService } from './angular/shared/services/generic-modal.service';
import { isPlatformBrowser } from '@angular/common';
import { GlobalCompanyStateService } from './angular/shared/services/global-company-state.service';
import { CurrentUrlService } from './angular/shared/services/current.url.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  @HostListener('window:storage')
  onStorageChange() {
    if (
      this.authenticationService.isAccessAccount !== !!localStorage.getItem('SOSTEREO.originalUser')
    ) {
      window.location.reload();
    }

    if (this.loggedUser() && this.authenticationService.getCurrentUser() === null) {
      window.location.reload();
    }
  }

  @ViewChild('userSignUpModalComponent', { static: true })
  userSignUpModal: UserSignUpModalComponent;

  @ViewChild('sessionExpiredModal', { static: true })
  sessionExpiredModal: SessionExpiredModalComponent;

  private subscription;
  public showPage = signal<boolean>(true);
  public showLayout = true;
  public currentUrl = signal<string>('');
  public loggedUser = signal<any>(null);
  public previewUser: any;
  public loggedAs: boolean;
  public caret: boolean;
  public cookieConsent: boolean;
  public layoutOptions = options[this.environment.name];
  public userSignUpToken: string;
  public isSessionExpired: Subject<boolean> = this.errorHandlingService.isSessionExpired;
  public showSearchHeader = false;
  public getLandingSliders = false;
  public technicalIssues = false; // Change it to true when it's needed to have a banner displaying that the app is down (See README)
  private magicTokenRedirect: any = {};
  public scopes = this.authenticationService.getCurrentUser()?.scopes || [];
  private showExtendedSearchSidebar: BehaviorSubject<boolean> =
    this.playerService.showExtendedSearchSidebar;

  private $versionUpdatesSW: Subscription;
  isBrowser: boolean;

  public isMobileOrTablet =
    this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();

  public modalRoutes = [
    '/',
    '/#our-work',
    '/#learn-more',
    '/submit-brief',
    '/submit-music',
    '/session-expired',
  ];

  public notSidenavRoutes = [
    '/our-work',
    '/who-we-are',
    '/services',
    '/wrapup',
    '/login',
    '/signup',
    '/pitch',
  ];

  public allowedSidenavRoutes = [
    '/discover',
    '/search',
    '/music-sync-licensing',
    '/artists-list',
    '/my-playlists',
  ];

  constructor(
    private _seoService: SeoService,
    private authenticationService: AuthenticationService,
    private identityService: IdentityService,
    private errorHandlingService: ErrorHandlingService,
    private commonService: CommonService,
    public playerService: PlayerService,
    private themeService: ThemeService,
    private router: Router,
    private route: ActivatedRoute,
    private trackingService: TrackingService,
    private swUpdate: SwUpdate,
    private toastr: ToastrService,
    public deviceDetectorService: DeviceDetectorService,
    public genericModalService: GenericModalService,
    private globalCompanyService: GlobalCompanyStateService,
    private currentUrlService: CurrentUrlService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject('environment') private environment,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    console.log('app constructor', this.isBrowser);
    this.authenticationService.isAccessAccount = !!localStorage.getItem('SOSTEREO.originalUser');
    console.log('esta es la url', this.router.url);
    if (this.authenticationService.getCurrentUser()?.token) {
      this.getScopes();
    }
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // This is to update page's title and desc with de default one
        this._seoService.updateBasicSeo({
          pageTitle: this.commonService.pageTitle.getValue() || null,
          pageDescription: this.commonService.pageDescription.getValue() || null,
        });
        this._seoService.updateImageSeo({
          pageBackground:
            'https://img-sostereo.sostereo.com/sostereo-images-production/seo_banner.png',
        });
        this.currentUrl.set(this.router.url.split('?')[0]);
        this.currentUrlService.setValue(this.router.url.split('?')[0]);
        if (this.isBrowser) {
          this.magicLink(this.currentUrl());
        }
        this.showLayout =
          (!this.currentUrl().includes('/login') &&
            !this.currentUrl().includes('/signup') &&
            !this.currentUrl().includes('/invite') &&
            !this.currentUrl().includes('/transfer/download') &&
            !this.currentUrl().includes('/loading')) ||
          this.isMobileOrTablet;
        if (!this.authenticationService.getCurrentUser()?.email.includes('sostereo')) {
          trackingService.track('Page View', { url: this.currentUrl() });
        }
        // Check service worker update
        this.checkForUpdate();
        //This is to redirect artists and labels to dashboard when they're already logged in
        if (
          this.scopes?.length > 0 &&
          this.commonService.isAllowed(
            ['GetArtist', 'UpdateArtist', 'ListArtists'],
            ['*', 'UpdateSearchSettings', 'GetAgreement'],
          ) &&
          this.currentUrl() === '/' &&
          this.environment.name === 'sostereo'
        ) {
          const subdomain = this.commonService.getSubdomain();
          if (!subdomain) {
            this.router.navigate(['/artists/dashboard']);
          }
        } else if (this.currentUrl() === '/artists/dashboard') {
          const subdomain = this.commonService.getSubdomain();
          if (subdomain || this.environment.name !== 'sostereo') {
            this.router.navigate(['/discover']);
          }
        }
        //This is to redirect desktop user to mobile playlists view and viceversa
        if (this.currentUrl().startsWith('/my-playlists') && !this.isMobileOrTablet) {
          const urlSplit = event.url.split('/');
          this.router.navigateByUrl(
            `/music-sync-licensing/edit-playlist${urlSplit[2] ? '/' + urlSplit[2] : ''}`,
          );
        }
        if (
          (this.currentUrl().startsWith('/music-sync-licensing/playlist') ||
            this.currentUrl().startsWith('/music-sync-licensing/edit-playlist')) &&
          this.isMobileOrTablet
        ) {
          const urlSplit = event.url?.split('/')[3]?.split('?')
            ? event.url?.split('/')[3]?.split('?')[0]
            : event.url?.split('/')[3];
          this.router.navigateByUrl(`/my-playlists/${urlSplit || ''}`);
        }
      }
    });
    this.caret = false;
    if (this.isBrowser) {
      this.cookieConsent = JSON.parse(localStorage.getItem('SOSTEREO.cookieConsent'));
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const url = val.url;
        if (url.includes('/user-sign-up/')) {
          this.userSignUpToken = url.replace('/user-sign-up/', '');
          this.userSignUpModal.showModal(this.userSignUpToken);
        }
        this.showSearchHeader = url.includes('music-sync-licensing');
        this.playerService.showSearchSidebar =
          (url.includes('music-sync-licensing') ||
            url.includes('discover') ||
            url.includes('search') ||
            url.includes('artists-list')) &&
          !url.includes('analytics');
        if (!this.playerService.showSearchSidebar) {
          this.showExtendedSearchSidebar.next(false);
          this.playerService.showLyrics.next(false);
        }
      }
    });
  }

  appendStyle(name) {
    let style = document.createElement('link');
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = name;
    document.head.appendChild(style);
  }

  ngAfterContentInit(): void {
    if (this.isBrowser) {
      this.appendStyle('last_style.css');
    }
  }

  // swUpdate triggers subscribe to know when a change has been made in the app
  public checkForUpdate() {
    if (!this.$versionUpdatesSW) {
      this.$versionUpdatesSW = this.swUpdate.versionUpdates.subscribe((value: VersionEvent) => {
        if (value.type === 'VERSION_DETECTED') {
          console.log('swUpdate >>>> ', value);
          const toast = this.toastr.info('Tap here to reload', 'Update Available', {
            closeButton: false,
            disableTimeOut: true,
          });
          toast.onTap.subscribe((action) => {
            window.location.reload();
            console.log('toast.onTap >>> ', action, toast.toastId);
          });
        }
      });
    }

    if (this.isBrowser && 'serviceWorker' in navigator) {
      this.swUpdate.checkForUpdate();
    }
  }

  ngOnInit() {
    console.log('app ngOnInit()');
    localStorage.removeItem('SOSTEREO.slidersSegment');
    const subdomain = this.commonService.getSubdomain();
    this.globalCompanyService.fetchGlobalCompany(subdomain || 'sostereo');
    if (subdomain && this.isBrowser) {
      this.commonService.getCompany(subdomain, (company) => {
        if (company) {
          if (company.theme) {
            this.themeService.setTheme(company.theme); // This will change the theme if it needs to
          }
          const seoData = subdomain
            ? {
                pageTitle: company.theme?.pageTitle || company.name || this.environment.title,
                pageDescription: company.theme?.pageDescription || this.environment.seoDescription,
                bg: `https://img-sostereo.sostereo.com/sostereo-images-production/${subdomain}.png`,
                tenantId: subdomain,
              }
            : null;
          this._seoService.createTags(seoData);
        } else {
          this._seoService.createTags();
          if (this.environment.redirectAppUrl)
            window.location.href = this.environment.redirectAppUrl;
        }
      });
    } else {
      this._seoService.createTags({}, window.location.href);
    }
    // This is to know when to display the session expired modal
    this.isSessionExpired.subscribe((isExpired) => {
      if (isExpired) {
        this.sessionExpiredModal.showModal();
        this.isSessionExpired.next(false);
      }
    });
    if (this.isBrowser) {
      this.loggedUser.set(this.authenticationService.getCurrentUser());
    }
    this.loggedAs = false;
    if (this.isBrowser) {
      this.authenticationService.accessData.subscribe((user) => {
        this.loggedUser.set(user);
        if (!this.loggedUser()) {
          this.loggedAs = false;
          this.previewUser = null;
        } else if (this.loggedUser()?.cookieConsent) {
          this.cookieConsent = true;
          if (!isEmpty(this.magicTokenRedirect)) {
            this.router.navigate(
              [decodeURIComponent(this.magicTokenRedirect.url)],
              this.magicTokenRedirect.query,
            );
            this.magicTokenRedirect = {};
          }
        }
      });
    }
    if (
      localStorage.getItem('SOSTEREO.userToken') &&
      localStorage.getItem('SOSTEREO.originalUser')
    ) {
      this.previewUser = JSON.parse(localStorage.getItem('SOSTEREO.currentUser'));
      this.loggedAs = true;
    }
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      setTimeout(() => {
        this.loadGoogleTagManager('GTM-M5DPHP2');
      });
    }
  }

  loadGoogleTagManager(gtmId: string) {
    // Inject GTM script dynamically
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s) as HTMLScriptElement, // Cast to HTMLScriptElement
        dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmId);
  }

  sideNavToggle() {
    this.caret = !this.caret;
  }

  setCookieConsent() {
    if (this.isBrowser) {
      this.cookieConsent = true;
      if (this.loggedUser()) {
        this.authenticationService.setCurrentUser(
          JSON.parse(localStorage.getItem('SOSTEREO.currentUser')),
        );
      }
    }
  }

  private getScopes() {
    this.identityService.getScopes().subscribe({
      next: (res) => {
        console.log('Response identities/me >>>> ', res);
        const userData = { ...this.authenticationService.getCurrentUser(), ...res.data };
        if (userData.redirect && !!userData.redirect.url && userData.redirect.token) {
          this.userReturnUrl(userData);
        } else {
          this.scopes = res.data?.scopes || [];
          localStorage.setItem('SOSTEREO.currentUser', JSON.stringify(userData));
          this.setSentryUser();
        }
      },
      error: (httpErr) => {
        console.log('httpErr identities/me', httpErr);
        this.trackingService.track('identities/me error', {
          httpErr: httpErr,
          user: this.authenticationService.getCurrentUser(),
        });
      },
    });
  }

  private setSentryUser() {
    Sentry.configureScope((scope) => {
      scope.setUser({
        username: this.loggedUser()?.username,
        email: this.loggedUser()?.email,
        id: this.loggedUser()?._id,
      });
    });
  }

  private userReturnUrl(userData) {
    // The split('?')[0] is to remove the queryParams and only leave the url
    const redirect = clone(this.router.routerState.snapshot.url).split('?')[0];
    let queryParams = this.route.snapshot.queryParams;
    let paramsString = '';
    if (queryParams) {
      paramsString = Object.keys(queryParams)
        .map((key) => {
          return `${key}=${queryParams[key]}`;
        })
        .join('&');
    }
    if (this.authenticationService.getCurrentUser()) {
      // logs out current user since they don't have an account anymore in that domain. this is before redirecting
      this.authenticationService.logout();
    }
    window.location.href = `${userData.redirect.url}/auth/${userData.redirect.token}${
      redirect ? '?returnUrl=' + redirect : ''
    }${!isEmpty(queryParams) ? '&' + paramsString : ''}`;
    return;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  magicLink(urlInit: string) {
    const { magicToken, returnUrl } = this.router.routerState.snapshot.root.queryParams;
    if (magicToken) {
      this.authenticationService
        .postMagiclogin(magicToken)
        .pipe(finalize(() => this.showPage.set(true)))
        .subscribe(
          (data) => {
            const userData = data.data;
            const domain = window.location.hostname;
            if (
              userData.redirect &&
              !domain.includes(userData.redirect?.url) &&
              userData.redirect.token
            ) {
              this.domainChange(userData);
              return;
            }
            this.authenticationService.setCurrentUser(userData);
            this.trackingService.setIdentity(userData, 'login', true);
            this.trackingService.track(
              'Magic Login Success',
              {
                userId: userData?.id,
                userEmail: userData?.email,
                userData: userData,
              },
              {
                event_action: 'Magic login',
                event_type: 'Authentication',
                event_user_email: userData?.email,
              },
            );
            let queryParams = clone(this.route.snapshot.queryParams);
            delete queryParams.goTo;
            delete queryParams.magicToken;
            if (returnUrl) {
              delete queryParams.returnUrl;
              this.router.navigate([decodeURIComponent(returnUrl)], { queryParams });
              this.magicTokenRedirect = { url: returnUrl, query: queryParams };
            } else {
              const url = urlInit.split('?')[0];
              this.router.navigate([url], { queryParams });
            }
          },
          (err) => {
            let queryParams = clone(this.route.snapshot.queryParams);
            delete queryParams.goTo;
            delete queryParams.magicToken;
            const url = urlInit.split('?')[0];
            this.router.navigate([url], { queryParams });
            console.log(err.error);
            if (!this.authenticationService.getCurrentUser()) {
              setTimeout(
                () =>
                  this.showGenericModal({
                    title: 'Ooops!',
                    message:
                      'This link expired. You can request a new link for a password-free sign-in or use the normal login.',
                    cssIcon: 'fa fa-exclamation-triangle',
                  }),
                1000,
              );
            }
            this.trackingService.track('Login Magic Token Error', { error: err.error });
          },
        );
    } else {
      this.showPage.set(true);
    }
  }

  showGenericModal({ title, message, cssIcon }) {
    this.genericModalService.showModal({
      title,
      message,
      auxMessage: '',
      cssClass: cssIcon,
      size: 'modal-sm',
      btns: [{ kind: 'close', title: 'Login', cssClass: 'primary-btn' }],
    });
  }

  private domainChange(userData) {
    const urlOld = this.router.url;
    const urlNavigate = urlOld ? decodeURIComponent(urlOld) : '';
    const oldParams = urlNavigate.split('?');
    let params = null;
    if (oldParams.length > 1) {
      params = oldParams[1]
        .split('&')
        .filter((param) => !param.includes('magicToken'))
        .join('&');
    }
    const url = `${userData.redirect.url}/auth/${userData.redirect.token}`;
    const queryParams = `${urlNavigate ? '?goTo=' + oldParams[0] : ''}${
      params ? '&' + params : ''
    }`;
    this.changeExternalLocation(`${url}${queryParams}`);
  }

  public changeExternalLocation(url: string): void {
    window.location.href = url;
  }

  showSidenav = computed(() => {
    const loggedUser = this.loggedUser();
    return (
      (loggedUser || this.showSidenavRoutes(this.currentUrlService.value())) &&
      !this.isPitch(this.currentUrlService.value())
    );
  });

  private showSidenavRoutes(currentUrl: string) {
    return (
      !this.modalRoutes.some((route) => route !== '/' && currentUrl.includes(route)) &&
      !this.notSidenavRoutes.some((route) => currentUrl.includes(route)) &&
      currentUrl !== '/' &&
      this.allowedSidenavRoutes.some((route) => currentUrl.includes(route))
    );
  }

  private isPitch(currentUrl: string) {
    return currentUrl.includes('/pitch') && !currentUrl.includes('/pitch/edit/');
  }
}
