@if(showLayout && showPage()){ @if (showSearchHeader) {
<app-session-buttons></app-session-buttons>
} @else {
<div ui-view="header" *ngIf="!layoutOptions?.spacesHeader">
  @if (isBrowser && cookieConsent === null) {
  <app-cookie-consent (cookieConsent)="setCookieConsent()"> </app-cookie-consent>
  }
</div>
<div *ngIf="layoutOptions?.spacesHeader && !this.currentUrl().includes('/spaces/submission/')">
  <app-spaces-header> </app-spaces-header>
</div>
} @if (showSidenav()) {
<app-sidenav [scopes]="scopes"></app-sidenav>
} @if (playerService.showSearchSidebar) {
<app-search-sidebar></app-search-sidebar>
} } @if (showPage()) {
<main class="page-content">
  @if (technicalIssues) {
  <app-technical-issues></app-technical-issues>
  }
  <div class="{{ showSidenav() && showLayout && showPage() ? 'lg:pl-sidenavWidth' : 'lg:pl-0' }}">
    <router-outlet></router-outlet>
  </div>
</main>
}
<app-user-sign-up-modal #userSignUpModalComponent></app-user-sign-up-modal>
<app-session-expired-modal #sessionExpiredModal></app-session-expired-modal>
@defer (when currentUrl() !== '/') {
<app-player-widget [hasBottomMenu]="this.isMobileOrTablet && showSidenav()"></app-player-widget>
} @if (this.isMobileOrTablet && showSidenav()) {
<app-mobile-footer></app-mobile-footer>
}
