import { Component, OnInit, TemplateRef, ViewChild, Inject } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NotificationService } from 'sostereo-services';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { clone } from 'lodash-es';
import { CommonService } from '../../../../shared/services/common.service';
import { InlineErrorsComponent } from '../../../../shared/components/inline-errors/inline-errors.component';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InlineErrorsComponent],
})
export class ContactUsModalComponent implements OnInit {
  @ViewChild('contactUsModal', { static: true }) modalTemplate: TemplateRef<any>;
  public modalRef: BsModalRef;
  contactUsForm: FormGroup;
  disableBtn: boolean;
  requestSuccess: boolean;
  requestError: boolean;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private trackingService: TrackingService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    @Inject('environment') private environment,
  ) {
    this.disableBtn = false;
    this.createForm();
  }

  ngOnInit() {}

  private createForm() {
    this.contactUsForm = this.formBuilder.group({
      fullName: new FormControl(null, { validators: Validators.required }),
      artistCompanyName: new FormControl(null, { validators: Validators.required }),
      contactEmail: new FormControl(null, {
        validators: [Validators.pattern(this.commonService.emailRegex), Validators.required],
      }),
      subject: new FormControl(null, { validators: Validators.required }),
      messageContact: new FormControl(null, {
        validators: Validators.required,
      }),
    });
  }

  sendEmail() {
    this.disableBtn = true;
    const form = clone(this.contactUsForm.value);
    if (this.environment.name && this.environment.name === 'stereospaces') {
      form.subject = `${form.subject} - (StereoSpaces)`;
    }
    const notification = {
      email: 'salo+stereospaces@sostereo.com',
      subject: 'Stereospaces Demo Requested!',
      title: 'Stereospaces Demo Requested!',
      body: `<div class="padding-x-template"><p>Our creative team  will contact you as soon as possible to help with your project.
      Feel free to call us at <b>+1 (305) 702-0180</b> or send us an email to <b>hello@sostereo.com</b> with more details about your search.</p>
      <p>Cheers!</p><br>
      <p><b>Your Contact Email: </b>${form.contactEmail}</p>
      <p><b>Full Name: </b>${form.fullName}</p>
      <p><b>Artist or company: </b>${form.artistCompanyName}</p>
      <p><b>Subject: </b>${form.subject}</p>
      <p><b>Message: </b>${form.messageContact}</p>
        </div>`,
      bodyTitle: 'Help is on the way!',
    };

    this.notificationService.post(notification).subscribe({
      next: () => {
        this.trackingService.track(
          'Submission Success',
          { email: form.contactEmail },
          {
            event_action: 'Contact us submission success',
            event_type: 'Submission',
            event_user_email: form?.contactEmail,
          },
        );
        this.disableBtn = false;
        this.requestSuccess = true;
        setTimeout(() => {
          this.hideModal();
          this.requestSuccess = false;
        }, 3000);
      },
      error: () => {
        this.requestError = true;
        this.disableBtn = false;
        this.requestSuccess = false;
      },
    });
  }

  public showModal() {
    this.modalRef = this.modalService.show(this.modalTemplate, { class: 'modal-md' });
    this.trackingService.track('Contact Us', {
      action: 'Closed',
    });
  }

  public hideModal() {
    this.contactUsForm.reset();
    this.modalRef.hide();
    this.trackingService.track('Contact Us', {
      action: 'Closed',
    });
  }
}
