import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { CommentsService } from 'sostereo-services';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PlayerWidgetService } from '../../../services/player-widget.service';
import { Router } from '@angular/router';
import { WavesurferTimeFormatPipe } from '../../../../shared/pipes/wavesurfer-time-format.pipe';
import { FormsModule } from '@angular/forms';
import { NgIf, NgClass } from '@angular/common';

declare let $: any;

@Component({
  selector: 'app-new-comment',
  templateUrl: './new-comment.component.html',
  styleUrls: ['./new-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FormsModule, NgClass, WavesurferTimeFormatPipe],
})
export class NewCommentComponent implements OnInit, OnDestroy {
  @Input() loggedUser;
  @Input() track;
  @Input() control;
  @Output() updateCommentRequest = new EventEmitter();
  @Output() loginRequest = new EventEmitter();
  @Output() commentPopUpOpened = new EventEmitter();
  comment: {
    startTime: number;
    endTime: number;
  };

  public showNewCommentPopUp: boolean;
  public message: string;
  public offsetLeft: number;
  private newTrack$: Subscription = new Subscription();

  constructor(
    private commentsService: CommentsService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private playerWidgetService: PlayerWidgetService,
    public router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.showNewCommentPopUp = false;
    this.offsetLeft = 0;
  }

  ngOnInit() {
    this.newTrack$ = this.playerWidgetService.newTrack.subscribe(() => {
      this.hidePopUpAndCommentArea();
    });
  }

  ngOnDestroy() {
    this.newTrack$.unsubscribe();
  }

  toggleCommentIcon(event?) {
    const icon = document.getElementsByClassName('commentIconContent')[0];
    if (event) {
      this.renderer.setStyle(icon, 'left', `${(event.offsetX - 7).toString()}px`);
      this.renderer.setStyle(icon, 'display', 'block');
    } else {
      this.renderer.setStyle(icon, 'display', 'none');
    }
    this.changeDetectorRef.detectChanges();
  }

  addNewComment(event) {
    const commentSection = document.getElementsByClassName('commentSection')[0];
    this.renderer.setStyle(commentSection, 'display', 'block');
    this.renderer.setStyle(commentSection, 'z-index', '4');
    const waveformContainer = $('#waveform-container');
    let xPosition;
    if (event.offsetX > waveformContainer.width() * 0.92) {
      xPosition = waveformContainer.width() * 0.92;
    } else {
      xPosition = event.offsetX;
    }
    this.offsetLeft = xPosition;
    const div = $(`<div id="resizableComment">
    <span class="material-icons-outlined arrows ui-resizable-e" style="cursor: e-resize;
  width: 7px;
  right: 3px;
  top: 20px;
  height: 100%;">swap_horiz</span>
    <span class="material-icons-outlined arrows ui-resizable-w" style="cursor: w-resize;
  width: 7px;
  left: -9px;
  top: 20px;
  height: 100%;">swap_horiz</span>
    </div>`).css({
      position: 'absolute',
      left: xPosition,
      bottom: 0,
      'z-index': 5,
    });
    waveformContainer.append(div);
    $('#resizableComment').resizable({
      containment: '#waveform-container',
      maxHeight: 70,
      minHeight: 70,
      minWidth: 40,
      handles: { e: '.ui-resizable-e', w: '.ui-resizable-w' },
    });
    document.getElementById('resizableComment').addEventListener('mouseup', () => {
      this.updateCommentSection();
    });
    this.comment = {
      startTime: (xPosition * this.control.duration) / waveformContainer.width(),
      endTime:
        (($('#resizableComment').width() + xPosition) * this.control.duration) /
        waveformContainer.width(),
    };
    this.showNewCommentPopUp = true;
    this.commentPopUpOpened.emit();
    this.changeDetectorRef.detectChanges();
  }

  updateCommentSection() {
    const waveformContainer = document.getElementById('waveform-container');
    const resizable = document.getElementById('resizableComment');
    this.comment = {
      startTime: (resizable.offsetLeft * this.control.duration) / waveformContainer.offsetWidth,
      endTime:
        ((resizable.offsetWidth + resizable.offsetLeft) * this.control.duration) /
        waveformContainer.offsetWidth,
    };
    this.changeDetectorRef.detectChanges();
  }

  saveComment() {
    const comment = {
      startTime: this.comment.startTime,
      endTime: this.comment.endTime,
      userNickname: this.loggedUser ? this.loggedUser.nickname : 'me',
      playListId: this.track.tabId || this.track.objectData.playlistId,
      trackId: this.track.trackId,
      message: this.message,
    };
    if (this.track.local) {
      let localComments = {};
      const newComment = {
        startTime: comment.startTime,
        endTime: comment.endTime,
        comments: [
          {
            message: comment.message,
            userNickname: comment.userNickname,
            id: comment.startTime + comment.endTime + '0',
          },
        ],
      };
      if (!localStorage.getItem('SOSTEREO.submissionComments')) {
        localComments[comment.trackId] = {
          times: [newComment],
        };
      } else {
        localComments = JSON.parse(localStorage.getItem('SOSTEREO.submissionComments'));
        if (localComments.hasOwnProperty(comment.trackId)) {
          localComments[comment.trackId].times.push(newComment);
        } else {
          localComments[comment.trackId] = {
            times: [newComment],
          };
        }
      }
      localStorage.setItem('SOSTEREO.submissionComments', JSON.stringify(localComments));
      this.hidePopUpAndCommentArea();
      this.updateCommentRequest.emit();
    } else {
      this.commentsService.post(comment).subscribe(
        () => {
          this.toastr.success('comment submitted successfully');
          this.message = '';
          this.hidePopUpAndCommentArea();
          this.updateCommentRequest.emit();
        },
        (err) => {
          console.log(err);
          this.toastr.error(err?.error?.message, 'There was an error submitting your comment. ');
        },
      );
    }
    this.changeDetectorRef.detectChanges();
  }

  hidePopUpAndCommentArea() {
    this.comment = {
      startTime: null,
      endTime: null,
    };
    const waveformContainer = document.getElementById('waveform-container');
    const resizable = document.getElementById('resizableComment');
    const commentSection = document.getElementsByClassName('commentSection')[0];
    if (waveformContainer && resizable && commentSection) {
      this.renderer.removeChild(waveformContainer, resizable);
      this.renderer.setStyle(commentSection, 'display', 'none');
      this.showNewCommentPopUp = false;
      this.message = '';
    }
    this.changeDetectorRef.detectChanges();
  }

  login() {
    this.loginRequest.emit();
  }

  onKeydown(event) {
    event.stopPropagation();
  }
}
