import { Component, EventEmitter, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { UserService, AuthenticationService } from 'sostereo-services';
import { BtnComponent } from 'src/app/components/btn/btn.component';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  standalone: true,
  imports: [RouterLink, BtnComponent],
})
export class CookieConsentComponent {
  @Output() cookieConsent = new EventEmitter();

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
  ) {}

  addCookieConsent(consent: boolean) {
    const loggedUser = this.authenticationService.getCurrentUser();
    if (loggedUser) {
      loggedUser.cookieConsent = consent;
      this.userService.update(loggedUser._id, { cookieConsent: true }).subscribe(
        () => {
          localStorage.setItem('SOSTEREO.cookieConsent', 'true');
          this.cookieConsent.emit();
        },
        (err) => {
          console.log(
            'Err at adding cookieConsent to user. userId: ' + loggedUser._id + '. Err: ',
            err,
          );
        },
      );
    } else {
      localStorage.setItem('SOSTEREO.cookieConsent', 'true');
      this.cookieConsent.emit();
    }
  }
}
