import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from 'src/app/angular/layout/components/footer/footer.component';
import { GetStartedComponent } from 'src/app/angular/layout/components/get-started/get-started.component';
import { MainNavComponent } from 'src/app/angular/layout/components/main-nav/main-nav.component';
import { NgClass } from '@angular/common';
import { CurrentUrlService } from '../../services/current.url.service';
@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [MainNavComponent, RouterModule, FooterComponent, GetStartedComponent, NgClass],
  templateUrl: './main-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent {
  currentUrl = computed(() => this.currentUrlService.value());
  blueShapeRoutes = [
    '/music-sync-licensing/search?artists=',
    '/music-sync-licensing/artist',
    '/our-work',
  ];

  blueBackgroundRoutes = [
    '/music-sync-licensing/search?artists=',
    '/music-sync-licensing/artist',
    '/our-work/detail',
  ];

  blueHeaderRoues = ['/', 'who-we-are', '/services/sonic-analysis', '/services/licensed-music'];

  shapeColor = computed(() => this.setShapeColor());

  backgroundColor = computed(() => this.setBackgroundColor());

  constructor(private currentUrlService: CurrentUrlService) {}

  setBackgroundColor() {
    if (
      this.currentUrl().includes('/music-sync-licensing/artist') ||
      this.currentUrl().includes('/music-sync-licensing/search?artists=') ||
      this.currentUrl().includes('/our-work')
    ) {
      return 'bg-light';
    }
    if (this.currentUrl().includes('/who-we-are')) {
      return 'bg-secondary';
    }
    return 'bg-default';
  }

  setShapeColor() {
    if (
      this.currentUrl().includes('/our-work') ||
      this.currentUrl().includes('/music-sync-licensing/artist') ||
      this.currentUrl().includes('/music-sync-licensing/search?artists=')
    ) {
      return 'secondary';
    }
    return 'light';
  }
}
