<div class="flex flex-col mb-1">
  <div class="flex">
    <label class="font-secondary text-[14px] lg:text-base text-primary normal-case mb-[5px]"
      >{{ label() }}
      @if (required()) {
      <span>*</span>
      } @if (tooltipMessage() !== '') {
      <span
        class="material-icons text-dark !text-[15px] ml-[5px]"
        [attr.data-icon]="'info'"
        [matTooltip]="tooltipMessage()"
        matTooltipClass="bg-tooltip"
        [matTooltipPosition]="'right'"
      >
      </span>
      }
    </label>
  </div>
  <div class="relative flex items-center">
    <input
      [type]="type()"
      [formControl]="control()"
      [placeholder]="placeholder()"
      [step]="step()"
      [min]="min()"
      [max]="max()"
      (change)="changeEmit()"
      (keyup)="keyUpEmit()"
      class="font-secondary w-full py-[5px] pl-5 rounded-2xl border h-[45px] lg:h-[50px]
      text-[16px] placeholder:text-[16px] placeholder:font-secondary {{ dynamicStyles() }}"
    />
    @if (icon()) { @switch (icon()) { @case ('search'){
    <img
      class="absolute right-5 cursor-pointer"
      src="/assets/images/components/search-S.svg"
      alt="search image"
      (click)="iconInteraction()"
    />
    }@default {
    <span
      class="material-icons-outlined absolute text-primary right-5 text-xl cursor-pointer"
      (click)="iconInteraction()"
    >
      {{ icon() }}
    </span>
    } } }
  </div>
  @if(!hideErrors()) {
  <app-chip
    type="error"
    [ngClass]="(control().touched || touched()) && errorMessage() ? 'opacity-100' : 'opacity-0'"
    >{{ errorMessage() + '.' }}</app-chip
  >
  }
</div>
