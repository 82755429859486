<ng-template #briefSubmissionModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="cancel()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        classGroup="squaredHover"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="sos-brief-modal-container sos-scrollbar-none !text-primary">
      <div class="flex flex-col lg:flex-row ml-0 mr-0">
        <div class="pl-12 pr-10 w-full lg:w-[50%]">
          <h1 class="text-center mt-0 pb-4 text-4xl lg:text-2xl">
            Discover the SoStereo <br />Advantage
          </h1>
          <div
            class="flex justify-center pb-5 [&>img]:h-[45px] [&>img]:lg:h-[70px] [&>img]:rounded-full [&>img]:relative"
            [ngClass]="{ hidden: environment.name !== 'sostereo' }"
          >
            <div
              class="flex ml-[75px] [&>img]:h-[45px] [&>img]:lg:h-[70px] [&>img]:rounded-full [&>img]:relative"
            >
              <img
                class="right-[15px]"
                src="./assets/images/about_us/team-pics/beto_azout.webp"
                alt="Beto Azout"
              />
              <img
                class="right-[30px] flip-img-h"
                src="./assets/images/about_us/team-pics/lindsey_camerota.webp"
                alt="Lindsey Camerota"
              />
              <img
                class="right-[45px] flip-img-h"
                src="./assets/images/about_us/team-pics/martin_gutierrez.webp"
                alt="Martin Gutierrez"
              />
              <img
                class="right-[60px] flip-img-h"
                src="./assets/images/about_us/team-pics/elaine_sir.webp"
                alt="Elaine Sir"
              />
              <img
                class="right-[75px]"
                src="./assets/images/about_us/team-pics/elijah_torn.webp"
                alt="Elijah Torn"
              />
            </div>
          </div>
          <div class="hidden lg:!block">
            <p>
              SoStereo is known for our dependable service and dedication to excellence in providing
              music solution for brands, agencies, films and more.
              <br />
              <br />
              We work with award-winning record-label artists to find just the right sonic fit for
              your marketing.
              <br />
              <br />
              From thousands of pre-cleared songs to custom music to scalable enterprise solutions,
              real artists can do it all.
            </p>
            <h3 class="mt-8 mb-0 font-semibold text-xl">A preferred minority-owned vendor for:</h3>
            <div class="flex justify-between items-center grayscale mt-2.5 [&>img]:w-[60px]">
              <img src="./assets/images/license_logos/wpp.webp" alt="WPP" />
              <img src="./assets/images/license_logos/publicis-small.webp" alt="Publicis" />
              <img src="./assets/images/license_logos/omnicom-small.webp" alt="Omnicom" />
              <img src="./assets/images/license_logos/ipg.webp" alt="IPG" />
              <img
                src="./assets/images/license_logos/stagwell-logo-small.webp"
                alt="Stagwell Partners"
                style="padding: 12px"
              />
            </div>
          </div>
        </div>
        <div class="pl-12 pr-10 w-full lg:w-[50%]">
          <div class="flex ml-0 mr-0">
            <div class="pl-0 pr-0">
              <div class="mb-[20px] text-center">
                <div class="hidden lg:!block">
                  <h2 class="mb-0 mt-0 ] text-2xl">Submit a brief and</h2>
                  <h2 class="mt-0 mb-[20px] ] text-2xl">
                    {{
                      environment.name !== 'sostereo'
                        ? 'we will send you a Playlist'
                        : 'hear from us within an hour'
                    }}
                  </h2>
                </div>
                <p [innerHTML]="modalDescription" class="text-center"></p>
              </div>
            </div>
          </div>
          <form [formGroup]="briefSubmissionForm" (ngSubmit)="onSubmit()">
            <div>
              <app-input-field
                label="Your email"
                [control]="getControl('email')"
                [required]="true"
                type="email"
                [errors]="briefSubmissionForm.controls['email'].errors"
              ></app-input-field>
              <app-text-area
                label="Tell us about your project"
                [required]="true"
                [control]="getControl('brief')"
                [rows]="4"
                [placeholder]="
                  'Please include details of the spot including: brand/terms/budget/timeline and moods/genres!'
                "
                [errors]="briefSubmissionForm.controls['brief'].errors"
              ></app-text-area>
            </div>
            <div class="clearfix">
              <div class="text-center flex justify-center">
                <app-btn
                  id="submitBriefForm"
                  typeBtn="submit"
                  [disabled]="loadingRequest || briefSubmissionForm.invalid"
                >
                  Send @if (loadingRequest) {
                  <i class="spin medium"></i>
                  }
                </app-btn>
              </div>
            </div>
            <div class="flex justify-center w-full">
              @for (alert of alerts; let index = $index; track index) {
              <alert
                [type]="alert.type"
                [dismissOnTimeout]="alert.timeout"
                (onClosed)="onAlertClosed(alert)"
                >{{ alert.message }}</alert
              >
              }
            </div>
          </form>
          <div class="inline-block w-full mt-[20px] text-center">
            <p>Are you an Artist or represent one? &nbsp;</p>
            <a
              class="hover:text-primary font-normal underline focus:text-primary"
              (click)="hideModal(); accountRequestModal.showModal()"
            >
              Submit your Music</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-account-request-modal #accountRequestModal></app-account-request-modal>
