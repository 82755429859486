import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Inject,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { PlaylistsStereoSpacesService, AuthenticationService } from 'sostereo-services';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { CommonService } from '../../../../shared/services/common.service';
import { transition, trigger, useAnimation } from '@angular/animations';
import { inOutAnimation, outInAnimation } from '../../../../shared/animations/in-out.animation';
import { finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertComponent, AlertModule } from 'ngx-bootstrap/alert';
import { InlineErrorsComponent } from '../../../../shared/components/inline-errors/inline-errors.component';
import { NgIf, NgFor } from '@angular/common';
import { BtnComponent } from 'src/app/components/btn/btn.component';

@Component({
  selector: 'app-copy-playlist-modal',
  templateUrl: './copy-playlist-modal.component.html',
  animations: [
    trigger('inOutAnimation', [transition(':enter', useAnimation(inOutAnimation))]),
    trigger('outInAnimation', [transition(':leave', useAnimation(outInAnimation))]),
  ],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    InlineErrorsComponent,
    NgFor,
    AlertModule,
    BtnComponent,
  ],
})
export class CopyPlaylistModalComponent implements OnInit {
  @ViewChild('copyPlaylistModal', { static: true }) modalTemplate: TemplateRef<any>;
  @Input() modalTitle = 'Copy playlist';
  @Input() showCopyComments = true;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() success = new EventEmitter();
  public modalRef: BsModalRef;
  public playlist: any = {};
  public copyPlaylistForm: FormGroup;
  public loadingRequest: boolean;
  public requestSuccess: boolean;
  public alerts: any[] = [];

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private playlistsService: PlaylistsStereoSpacesService,
    private authenticationService: AuthenticationService,
    private trackingService: TrackingService,
    @Inject('environment') private environment,
  ) {
    this.createForm();
    this.loadingRequest = false;
    this.requestSuccess = false;
  }

  ngOnInit() {}

  private resetComponent() {
    this.copyPlaylistForm.reset();
    this.copyPlaylistForm.get('copyComments').setValue('true');
    this.loadingRequest = false;
    this.requestSuccess = false;
  }

  private createForm() {
    this.copyPlaylistForm = this.formBuilder.group({
      name: new FormControl(null, { validators: Validators.required, updateOn: 'change' }),
      copyComments: new FormControl('true'),
    });
  }

  public onSubmit() {
    this.loadingRequest = true;
    if (this.copyPlaylistForm.valid) {
      const playlistForm = this.copyPlaylistForm.value;
      playlistForm.collaborative = false;
      playlistForm.copyComments = this.showCopyComments
        ? playlistForm.copyComments === 'true'
        : false;
      playlistForm.nickname = this.authenticationService.getCurrentUser()?.nickname;
      this.playlistsService
        .duplicate(this.playlist.id, playlistForm)
        .pipe(finalize(() => (this.loadingRequest = false)))
        .subscribe({
          next: () => {
            this.requestSuccess = true;
            this.success.emit();
            this.commonService.getPlaylistsCount();
          },
          error: (httpErr) => {
            this.addAlert({
              type: 'danger',
              message: "We couldn't copy this playlist, please try again",
            });
            this.trackingService.track('Error duplicating a playlist', {
              error: httpErr,
            });
          },
        });
    }
  }

  private addAlert(alert: any): void {
    this.alerts.push({
      type: alert.type,
      message: alert.message,
      timeout: 5000,
    });
  }

  public onAlertClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }

  public showModal(playlist) {
    this.playlist = playlist;
    this.copyPlaylistForm.get('name').setValue(this.playlist.name);
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
  }

  public hideModal() {
    this.modalRef.hide();
    setTimeout(() => {
      this.resetComponent();
    }, 500);
  }
}
