<ng-template #userSignUpModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="modal-body sos-generic-modal-container sos-scrollbar-none">
      <div class="row">
        <div class="col-md-12">
          <h4 class="modal-title" id="signUpModal">Create an Account</h4>
        </div>
      </div>
      <div class="text-left mt-6">
        <p class="modal-subtitle pl-4 pr-4 lg:!text-base !mb-4">
          Fill out the form below to add your email and password
        </p>
        <div class="w-full">
          <form [formGroup]="signUpForm" class="mt-0">
            <div>
              <app-input-field
                [label]="'Email'"
                placeholder="Enter your Email"
                [control]="getControl('email')"
                [required]="true"
                type="email"
                [errors]="errors?.email"
              >
              </app-input-field>
            </div>
            <div>
              <app-input-field
                [label]="'Password'"
                placeholder="Enter your password"
                [control]="getControl('password')"
                [required]="true"
                type="password"
                [errors]="errors?.password"
              >
              </app-input-field>
            </div>
            <div>
              <app-input-field
                [label]="'Password Confirmation'"
                placeholder="Password confirmation"
                [control]="getControl('confirmation')"
                [required]="true"
                type="password"
                [errors]="
                  errors?.confirmation ? { passwordConfirmation: errors?.confirmation } : null
                "
              >
              </app-input-field>
            </div>
          </form>
          <p class="error-color lg:text-base">{{ errors?.token }}</p>
          <p class="error-color lg:text-base" *ngIf="generalError !== ''">{{ generalError }}</p>
        </div>
      </div>
      <div class="row mt-5 form-group">
        <div class="col-md-12 text-center">
          <p>
            By submitting the form, you agree to our
            <a href="/terms-of-service" target="_blank">Terms of Service</a>,
            <a href="/privacy-policy" target="_blank">Privacy Policy</a> and
            <a href="/cookies-policy" target="_blank">Cookies Policy</a>.
          </p>
          <br />
          <app-btn
            class="flex justify-center"
            typeBtn="submit"
            (click)="signUp()"
            [disabled]="signUpLoading"
          >
            {{ signUpLoading ? 'Submitting' : 'Submit' }}
            @if (signUpLoading) {
            <i class="spin medium loader-margin"></i>
            }
          </app-btn>
        </div>
      </div>
    </div>
  </div>
</ng-template>
