import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-chip',
  standalone: true,
  imports: [],
  templateUrl: './chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  type = input<'error' | 'info' | 'message'>('message');

  colors = {
    error: 'bg-error-10 text-error',
    info: 'bg-secondary-10 text-secondary',
    message: 'bg-primary-10 text-primary',
  };
}
