<ng-template #sessionExpiredModal>
  <div class="modal-content">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <button type="button" class="close" (click)="hideModal()">
            <span class="sr-only">Close</span>
            <span class="ss-icon-close"></span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-offset-1 col-md-8">
          <h4 class="modal-title" id="myModalLabel">Session Expired</h4>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-offset-1 col-md-10">
          <p>
            Sorry, your session is expired. Log in again to access your account, or continue browsing publicly
          </p>
        </div>
      </div>
      <br/>
      <div class="col-md-offset-1 col-md-11 pl-0">
        <button type="submit" class="btn primary-btn" (click)="login()">Log in</button>
      </div>
      <br/>
      <br/>
    </div>
  </div>
</ng-template>
