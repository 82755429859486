<img
  [src]="image()"
  alt="shape"
  class="shaped-image object-cover sos-background-transition {{ additionalClasses() }}"
  [width]="minWidth()"
  [height]="minHeight()"
  [ngStyle]="{
    '-webkit-mask-image': 'url(/assets/images/disco-shapes/' + shape() + '-shape.svg)',
    'mask-image': 'url(/assets/images/disco-shapes/' + shape() + '-shape.svg)',
    'aspect-ratio': aspectRatio(),
    filter: shapeColorFilter(),
    background: background(),
  }"
/>
