import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BtnClasses, ClassGroup } from '../btn.config';
import { RouterLinkWithHref } from '@angular/router';

@Component({
  selector: 'app-btn-link',
  standalone: true,
  imports: [RouterLinkWithHref],
  templateUrl: './btn-link.component.html',
  styleUrl: './btn-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnLinkComponent implements AfterViewInit {
  @ViewChild('link', { static: true }) link: ElementRef;
  classGroup = input<ClassGroup>('primary');
  width = input<'auto' | 'full'>('auto');
  styles = input<string>('');
  isExternal = input(false);
  url = input.required<string>();
  target = input<'_self' | ' _blank' | '_parent' | ' _top'>('_self');
  btnClasses = BtnClasses;
  renderer2 = inject(Renderer2);

  ngAfterViewInit(): void {
    if (this.isExternal()) {
      this.renderer2.setAttribute(this.link.nativeElement, 'href', this.url());
    }
  }
}
