<div
  class="flex flex-col items-center main-container"
  *ngIf="!loader && playlist"
  [@inOutAnimation]
  (mouseup)="onMouseUp()"
  (mouseenter)="onMouseOver()"
  (mouseleave)="onMouseLeave()"
  [ngClass]="{
    'menu-list-border': onDropBorder
  }"
>
  <div class="flex items-center mb-8 pl-info-container pt-4" style="width: 100%">
    <div class="container-playlist-img" *ngIf="playlist.thumbUrl">
      <img
        loading="lazy"
        [src]="playlist.thumbUrl"
        alt="playlist image"
        class="playlist-img"
        (error)="handleMissingImage($event, true)"
      />
    </div>

    <div *ngIf="!playlist.thumbUrl || showMainPlaceholder" class="playlist-img placeholder-img">
      <span class="material-icons" [attr.data-icon]="'playlist_play'"> </span>
    </div>
    <div class="playlist-info">
      <h5 class="clamp-lines text-primary">
        {{ playlist.name }}
      </h5>
      <p class="clamp-lines">
        {{
          (playlist.editorial || !playlistReference.ownerName || !isAdmin
            ? ''
            : playlistReference.ownerName + ' ' + '&middot;'
          ) | titlecase
        }}
        {{ playlist.tracks.paging.totalItems }} tracks
      </p>
      <p class="tags sos-hidden-scrollbar">
        <span *ngFor="let tag of tags"> #{{ tag }} </span>
      </p>
    </div>
  </div>
  <p *ngIf="playlist?.description" class="clamp-lines playlist-description">
    {{ playlist.description }}
  </p>
  <div style="width: 70%; gap: 10px" class="flex flex-col items-center">
    <div class="flex items-center actions">
      <span
        class="material-icons"
        [attr.data-icon]="'content_copy'"
        (click)="
          currentUser ? duplicatePlaylist() : signUp();
          trackEvent('Playlist detail Button clicked', 'Click', {
            event_action: 'Playlist detail Button clicked',
            event_type: 'Button Click',
            event_value: 'Duplicate playlist'
          })
        "
        matTooltip="Duplicate playlist"
        matTooltipClass="bg-tooltip"
        [matTooltipPosition]="'above'"
        [matTooltipDisabled]="deviceDetectorService.isMobile() || deviceDetectorService.isTablet()"
      >
      </span>
      <span
        class="material-icons"
        [attr.data-icon]="'download'"
        (click)="
          currentUser ? downloadPlaylist() : signUp();
          trackEvent('Playlist detail Button clicked', 'Click', {
            event_action: 'Playlist detail Button clicked',
            event_type: 'Button Click',
            event_value: 'Download playlist'
          })
        "
        [ngClass]="{ 'edition-disabled': playlist.tracks.items.length === 0 }"
        matTooltip="Donwload playlist"
        matTooltipClass="bg-tooltip"
        [matTooltipPosition]="'above'"
        [matTooltipDisabled]="deviceDetectorService.isMobile() || deviceDetectorService.isTablet()"
      >
      </span>
      <span
        class="material-icons player-button"
        [attr.data-icon]="'play_circle'"
        (click)="
          playlist.tracks?.items.length > 0
            ? deviceDetectorService.isMobile() || deviceDetectorService.isTablet()
              ? playSong(0)
              : playPL()
            : '';
          trackEvent('Playlist detail Button clicked', 'Click', {
            event_action: 'Playlist detail Button clicked',
            event_type: 'Button Click',
            event_value: 'Play playlist'
          })
        "
        [ngClass]="{ 'edition-disabled': playlist.tracks.items.length === 0 }"
      >
      </span>
      <span
        class="material-icons"
        [attr.data-icon]="'edit'"
        (click)="
          sendEditPlaylist();
          trackEvent('Playlist detail Button clicked', 'Click', {
            event_action: 'Playlist detail Button clicked',
            event_type: 'Button Click',
            event_value: 'Edit playlist'
          })
        "
        [ngClass]="{ 'edition-disabled': !canEdit }"
        matTooltip="Edit playlist"
        matTooltipClass="bg-tooltip"
        [matTooltipPosition]="'above'"
        [matTooltipDisabled]="deviceDetectorService.isMobile() || deviceDetectorService.isTablet()"
      >
      </span>
      <span
        class="material-icons"
        [attr.data-icon]="'share'"
        (click)="
          sharePlaylistModalComponent.showModal();
          trackEvent('Playlist detail Button clicked', 'Click', {
            event_action: 'Playlist detail Button clicked',
            event_type: 'Button Click',
            event_value: 'Share playlist'
          })
        "
        matTooltip="Share playlist"
        matTooltipClass="bg-tooltip"
        [matTooltipPosition]="'above'"
        [matTooltipDisabled]="deviceDetectorService.isMobile() || deviceDetectorService.isTablet()"
      >
      </span>
    </div>
  </div>
  <div class="mt-2.5" style="width: 100%">
    <div
      class="menu-list-songs mt-2.5 sos-hidden-scrollbar"
      #target
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollUpDistance]="9"
      [infiniteScrollThrottle]="100"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
      cdkDropList
      [cdkDropListData]="playlist.tracks.items"
      (cdkDropListDropped)="drop($event)"
      [ngClass]="
        deviceDetectorService.isMobile() || deviceDetectorService.isTablet()
          ? playlist.description && playlist.description !== ''
            ? 'pb-16'
            : 'pb-12'
          : ''
      "
      [ngStyle]="setTableHeight()"
    >
      @if(playlist.tracks.items.length === 0) {
        <h5 class="text-center text-primary text-lg">Playlist empty</h5>
      }
      <div #mainSongs>
        <div
          *ngFor="let song of playlist.tracks.items; let songIndex = index"
          class="flex items-center pt-2.5 pb-2.5 song-row pl-menu-song"
          [ngClass]="{
            'currently-playing-color': song.isPlaying,
            'icebox-song': song.status === 'icebox'
          }"
          cdkDrag
          [cdkDragPreviewClass]="'previewRowLeft'"
          [cdkDragData]="song"
          (cdkDragStarted)="onDragStarted(song)"
          (cdkDragDropped)="onDragDropped()"
          [cdkDragDisabled]="deviceDetectorService.isMobile() || deviceDetectorService.isTablet()"
        >
          <div *ngIf="canEdit">
            <div class="cdk-placeholder" *cdkDragPlaceholder></div>
          </div>
          <div
            *ngIf="deviceDetectorService.isMobile() || deviceDetectorService.isTablet()"
            class="playlist-img-mobile-container"
          >
            <img
              loading="lazy"
              [src]="song?.artists && song.artists[0]?.artistImage"
              class="playlist-img-mobile"
              (error)="handleMissingImage($event)"
            />
            <span
              class="material-icons absolute-center mobile-img-placeholder"
              style="color: #cecece"
              [attr.data-icon]="'playlist_play'"
            >
            </span>
          </div>

          <div style="flex-grow: 1" (click)="playSong(songIndex)">
            <div class="flex items-center">
              <i
                class="material-icons add-icon"
                style="display: none"
                [attr.data-icon]="'add_circle'"
              >
              </i>
              <div style="width: 100%">
                <p
                  class="mb-0 song-name"
                  [ngClass]="{
                    'icebox-color': song.status === 'icebox'
                  }"
                >
                  {{ song.songName ? song.songName : song.fileName ? song.fileName : ''
                  }}{{
                    song.songVersion && song.songVersion !== 'original'
                      ? ' (' + song.songVersion + ')'
                      : ''
                  }}
                  <span
                    *ngIf="song.status === 'icebox' || (song.status === 'deleted' && isAdmin)"
                    class="mb-0"
                  >
                    ({{ song.status | titlecase }})
                  </span>
                </p>
                <p class="artist-link mb-0" style="font-size: 13px; color: #d5d7d8">
                  <ng-container *ngIf="song.artists">
                    <span *ngFor="let artist of song.artists; let i = index"
                      >{{ artist.artistName }} {{ song.artists.length > i + 1 ? ', ' : '' }}</span
                    >
                  </ng-container>
                  <span *ngIf="song.artistName">
                    {{ song.artistName }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="flex justify-center items-center">
            <a
              *ngIf="isAdmin && song?.copyrightWarning?.length > 0"
              (mouseover)="song.showCopyrightWarning = true"
              (mouseleave)="song.showCopyrightWarning = false"
              class="flex mr-4 no-underline"
              ><i
                aria-hidden="true"
                class="fa material-icons-outlined copyright-warning-icon pb-0"
                [attr.data-icon]="'warning'"
              ></i>
              <div *ngIf="song.showCopyrightWarning" class="more-info-tooltip">
                <p>Copyright Warnings:</p>
                <ul class="list-disc">
                  @for (item of song.copyrightWarning; track $index) {
                  <li>{{ item === 'cover' ? 'Possible Cover' : ' Major Label' }}</li>
                  }
                </ul>
              </div>
            </a>
            <a
              *ngIf="
                isAdmin &&
                (song?.isOneStop === false ||
                  song?.tierHasWarning ||
                  song?.isCover ||
                  song?.publicAccess === false)
              "
              (mouseover)="song.showWarning = true"
              (mouseleave)="song.showWarning = false"
              class="flex mr-4 no-underline"
              ><i
                aria-hidden="true"
                class="fa material-icons-outlined player-list-icon warnings-icon pb-0"
                [attr.data-icon]="'report_problem'"
              ></i>
              <div *ngIf="song.showWarning" class="more-info-tooltip">
                <ul>
                  <li *ngIf="song?.isOneStop === false">Not one stop</li>
                  <li *ngIf="song?.tierHasWarning">
                    {{
                      (song.tierName.indexOf('Tier') !== -1
                        ? song.tierName
                        : 'Tier ' + song.tierName) + ' has warning'
                    }}
                  </li>
                  <li *ngIf="song?.isCover">Is cover</li>
                  <li *ngIf="song.publicAccess === false">
                    Private Song, Not Visible to the General Public
                  </li>
                </ul>
              </div>
            </a>
            <span
              class="fa fa-ellipsis-h menu-song text-primary"
              mat-button
              [matMenuTriggerFor]="optionsBySong"
            ></span>
          </div>
          <mat-menu
            #optionsBySong="matMenu"
            MenuPositionX="after"
            class="menu-blue"
            id="dropdown-song-menu"
          >
            <button
              *ngIf="canEdit"
              mat-menu-item
              (click)="removeTrack({ songIndex: songIndex, songMatch: song })"
            >
              <span
                class="material-icons mr-1 vertical-align-icon"
                [attr.data-icon]="'remove_circle'"
              >
              </span
              >Remove from playlist
            </button>
            <button
              mat-menu-item
              (click)="isAuthenticated() ? addSongToPlaylist(song) : signupRequired()"
            >
              <span
                class="material-icons mr-1 vertical-align-icon"
                [attr.data-icon]="'playlist_add'"
              >
              </span
              >Add to a playlist
            </button>
            <button mat-menu-item (click)="goToArtist(song.artists[0].artistSlug)">
              <span class="material-icons mr-1 vertical-align-icon" [attr.data-icon]="'star'"> </span
              >Go to artist
            </button>
            <button mat-menu-item (click)="trigger.closeMenu(); getShareLink(song)">
              <span class="material-icons mr-1 vertical-align-icon" [attr.data-icon]="'share'">
              </span
              >Copy Song Link
            </button>
            <button
              mat-menu-item
              class="show-for-mobile"
              (click)="isAuthenticated ? licenseSong(song) : signupRequired()"
            >
              <i aria-hidden="true" class="info-icon fa fa-check mr-1"></i>License
            </button>
          </mat-menu>
        </div>
      </div>
      <div *ngIf="canEdit && recommendedSongs.length > 0" class="ml-0" style="margin-top: 60px">
        <div
          class="ml-4"
          [ngClass]="{ 'ml-5': deviceDetectorService.isMobile() || deviceDetectorService.isTablet() }"
        >
          <h5 class="font-medium text-primary">Suggestions</h5>
          <div id="filter-suggestion-songs" class="mt-8 mb-4">
            <ul class="flex justify-start pl-0 mr-4 gap-[5px] sos-hidden-scrollbar">
              <ng-container *ngFor="let profile of profiles">
                <li
                  *ngIf="!profile.allowed || commonService.isAllowed(profile.allowed)"
                  class="font-primary"
                  [ngClass]="{ active: profile.active }"
                  (click)="changeProfile(profile.value)"
                >
                  {{ profile.label }}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>

        <div
          *ngFor="let song of recommendedSongs; let songIndex = index"
          class="flex items-center pt-2.5 pb-2.5 song-row"
          [ngClass]="{
            'currently-playing-color': song.isPlaying,
            'icebox-song': song.status === 'icebox'
          }"
        >
          <div
            *ngIf="deviceDetectorService.isMobile() || deviceDetectorService.isTablet()"
            class="playlist-img-mobile-container"
          >
            <img
              loading="lazy"
              [src]="song?.artists && song.artists[0]?.artistImage"
              class="playlist-img-mobile"
              (error)="handleMissingImage($event)"
            />
            <span
              class="material-icons absolute-center mobile-img-placeholder"
              [attr.data-icon]="'playlist_play'"
              style="color: #cecece"
            >
            </span>
          </div>

          <div style="flex-grow: 1" (click)="playRecommendedSong(songIndex)">
            <div class="flex items-center">
              <i
                class="material-icons add-icon"
                style="display: none"
                [attr.data-icon]="'add_circle'"
              >
              </i>
              <div style="width: 100%">
                <p
                  class="mb-0 song-name"
                  [ngClass]="{
                    'icebox-color': song.status === 'icebox'
                  }"
                >
                  {{ song.name
                  }}{{
                    song.songVersion && song.songVersion !== 'original'
                      ? ' (' + song.songVersion + ')'
                      : ''
                  }}
                  <span
                    *ngIf="song.status === 'icebox' || (song.status === 'deleted' && isAdmin)"
                    class="mb-0"
                  >
                    ({{ song.status | titlecase }})
                  </span>
                </p>
                <p class="artist-link mb-0" style="font-size: 13px; color: #d5d7d8">
                  <ng-container *ngIf="song.artists">
                    <span *ngFor="let artist of song.artists; let i = index"
                      >{{ artist.artistName }} {{ song.artists.length > i + 1 ? ', ' : '' }}</span
                    >
                  </ng-container>
                  <span *ngIf="song.artistName">
                    {{ song.artistName }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="flex justify-center items-center">
            <a
              *ngIf="
                isAdmin &&
                (song?.isOneStop === false ||
                  song?.tierHasWarning ||
                  song?.isCover ||
                  song?.publicAccess === false)
              "
              (mouseover)="song.showWarning = true"
              (mouseleave)="song.showWarning = false"
              class="flex mr-4 no-underline"
              ><i
                aria-hidden="true"
                class="fa material-icons-outlined player-list-icon warnings-icon pb-0"
                [attr.data-icon]="'report_problem'"
              ></i>
              <div *ngIf="song.showWarning" class="more-info-tooltip">
                <ul class="list-disc">
                  <li *ngIf="song?.isOneStop === false">Not one stop</li>
                  <li *ngIf="song?.tierHasWarning">
                    {{
                      (song.tierName.indexOf('Tier') !== -1
                        ? song.tierName
                        : 'Tier ' + song.tierName) + ' has warning'
                    }}
                  </li>
                  <li *ngIf="song?.isCover">Is cover</li>
                  <li *ngIf="song.publicAccess === false">
                    Private Song, Not Visible to the General Public
                  </li>
                </ul>
              </div>
            </a>
            <i
              *ngIf="song.load"
              style="font-size: 20px"
              class="fa fa-spinner fa-spin download-spinner mr-0 text-primary"
            ></i>
            <span
              *ngIf="!song.load"
              class="material-icons mr-0 vertical-align-icon text-[18px] text-primary"
              [attr.data-icon]="'playlist_add'"
              (click)="$event.stopPropagation(); addRecommendedSong(song, songIndex)"
            >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loader">
  <div class="loader" align="center" [@inOutAnimation]>
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<app-add-to-playlist-modal
  *ngIf="isAuthenticated()"
  #addToPlaylistModalComponent
  [isGeneric]="true"
  [emitResults]="true"
></app-add-to-playlist-modal>
<app-download-playlist-confirmation-modal
  #downloadPlaylistConfirmationModalComponent
  (processFinished)="onProcessFinished()"
>
</app-download-playlist-confirmation-modal>
<app-share-playlist-modal
  #sharePlaylistModalComponent
  [playlistData]="playlist"
></app-share-playlist-modal>
<app-license-request-modal #licenseRequestModalComponent></app-license-request-modal>
