import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wavesurferTimeFormat',
  standalone: true,
})
export class WavesurferTimeFormatPipe implements PipeTransform {
  transform(value: any): any {
    if (!value || value === 0) {
      return '00:00';
    }

    let minutes = Math.floor(value / 60);
    const seconds = Math.ceil(value) % 60;
    if (seconds === 0) {
      minutes = minutes + 1;
    }
    return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }
}
