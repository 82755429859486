<header
  #navBar
  id="sos-new-header"
  class="w-full flex flex-col p-[15px] rounded-b-[20px] lg:px-7 z-[3] {{
    absoluteScroll() ? 'absolute' : currentUser ? 'fixed lg:pl-[98px]' : 'fixed lg:pl-7'
  }}"
  [ngClass]="mobileMenuOpen() || showScrollMenu() ? 'bg-white' : 'bg-transparent'"
>
  <nav class="w-full flex justify-between items-center">
    <app-logo-lottie
      (click)="
        trackEvent('Header Section Clicked', 'click', {
          event_action: 'Header section clicked',
          event_type: 'Header Click',
          event_value: 'Home'
        })
      "
      [ngClass]="{
        'lg:invisible': currentUrl().includes('/discover') || currentUrl().includes('/search')
      }"
    />
    <ul
      class="hidden lg:flex font-secondary text-primary text-base gap-6 list-none relative top-[-10px]"
    >
      <ng-container *ngTemplateOutlet="linksTemplate"> </ng-container>
    </ul>
    <div class="hidden lg:flex justify-start gap-4">
      <ng-container *ngTemplateOutlet="buttonsTemplate"> </ng-container>
    </div>
    @if(mobileMenuOpen()){
    <app-btn class="block lg:hidden" classGroup="squaredOutline" (clicked)="toggleMenu()">
      <span class="material-icons">close</span>
    </app-btn>
    }@else {
    <app-btn class="block lg:hidden" classGroup="squaredOutline" (clicked)="toggleMenu()">
      <span class="material-icons"> menu </span>
    </app-btn>
    }
  </nav>
  <div
    class="sos-nav-mobile font-secondary text-5xl text-primary flex-col gap-24 pt-24"
    [ngClass]="mobileMenuOpen() ? 'flex' : 'hidden'"
  >
    <ul class="flex flex-col gap-12 list-none">
      <ng-container *ngTemplateOutlet="linksTemplate"> </ng-container>
    </ul>
    <div class="flex justify-start gap-4">
      <ng-container *ngTemplateOutlet="buttonsTemplate"> </ng-container>
    </div>
  </div>
</header>

<ng-template #linksTemplate>
  <a
    [ngClass]="{ 'blue-header': blueHeaderView() }"
    class="sos-menu-item"
    routerLink="/services"
    [class.active]="currentUrl() === '/services'"
    (click)="
      trackEvent('Header Section Clicked', 'click', {
        event_action: 'Header section clicked',
        event_type: 'Header Click',
        event_value: 'Services'
      })
    "
    >Services</a
  >
  <a
    [ngClass]="{ 'blue-header': blueHeaderView() }"
    class="sos-menu-item"
    routerLink="/our-work"
    [class.active]="currentUrl() === '/our-work'"
    (click)="
      trackEvent('Header Section Clicked', 'click', {
        event_action: 'Header section clicked',
        event_type: 'Header Click',
        event_value: 'Our work'
      })
    "
    >Our work</a
  >
  <a
    [ngClass]="{ 'blue-header': blueHeaderView() }"
    class="sos-menu-item"
    routerLink="/who-we-are"
    [class.active]="currentUrl() === '/who-we-are'"
    (click)="
      trackEvent('Header Section Clicked', 'click', {
        event_action: 'Header section clicked',
        event_type: 'Header Click',
        event_value: 'Who we are'
      })
    "
    >Who we are</a
  >
  <a
    [ngClass]="{ 'blue-header': blueHeaderView() }"
    class="sos-menu-item"
    routerLink="/discover"
    [class.active]="currentUrl() === '/discover'"
    (click)="
      trackEvent('Header Section Clicked', 'click', {
        event_action: 'Header section clicked',
        event_type: 'Header Click',
        event_value: 'Discover'
      })
    "
    >Discover</a
  >
</ng-template>

<ng-template #buttonsTemplate>
  <app-btn
    (clicked)="showModal()"
    (click)="
      trackEvent('Talk to creative CallToAction (Header)', 'click', {
        event_type: 'Button Click',
        event_action: 'Talk to creative button clicked (Header)',
        event_value: 'Talk to creative'
      })
    "
    >Talk to a Creative</app-btn
  >
  @if(!currentUser){
  <app-btn classGroup="outline" styles="min-h-[50px]" routerLink="/signup"> Sign in </app-btn>
  }
</ng-template>
<app-brief-submission-modal #briefSubmissionModal></app-brief-submission-modal>
<app-account-request-modal #accountRequestModal></app-account-request-modal>
<app-session-expired-modal #sessionExpiredModal></app-session-expired-modal>
