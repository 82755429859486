import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IdentityService, AgreementService } from 'sostereo-services';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from '../../../../shared/services/common.service';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { ToastrService } from 'ngx-toastr';
import { remove } from 'lodash-es';
import { CommonModule } from '@angular/common';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { RouterModule } from '@angular/router';
import { BtnComponent } from 'src/app/components/btn/btn.component';
import { ChipComponent } from 'src/app/components/chip/chip.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TypeaheadModule,
    RouterModule,
    BtnComponent,
    ChipComponent,
  ],
  selector: 'app-license-assignation-modal',
  templateUrl: './license-assignation-modal.component.html',
  styleUrl: './license-assignation-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseAssignationModalComponent {
  @ViewChild('licenseAssignationModalComponent', { static: true }) modalTemplate: TemplateRef<any>;
  @Output()
  sendAssignationData = new EventEmitter();

  public licenseStatus = [
    { name: 'Quote Pending', slug: 'quote-pending' },
    { name: 'Contract Pending', slug: 'contract-pending' },
    // { name: 'License Approved', slug: 'license-approved' },
  ];

  public ownerData: any = {};
  suggestions$?: Observable<any>;
  form: FormGroup;
  loadingForm = false;

  private modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private identityService: IdentityService,
    private agreementService: AgreementService,
    public commonService: CommonService,
    private trackingService: TrackingService,
    private toastr: ToastrService,
  ) {}

  initForm(): void {
    this.form = this.fb.group({
      owner: ['', Validators.required],
      status: ['contract-pending', Validators.required],
    });
  }

  public showModal() {
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
    });
    this.initForm();
    this.ownerRecommendation();
  }

  public onUserTyped(event) {
    const input = event.target.value;
    const query = { email: new RegExp(this.commonService.regExpEscape(input), '') };
    this.ownerRecommendation(input ? query : undefined);
  }

  ownerRecommendation(query?) {
    this.suggestions$ = new Observable<any[]>((observer) => {
      this.identityService.query(query).subscribe((sRes) => {
        observer.next(sRes.data.map((s) => ({ id: s._id, email: s.email, groups: s.groups })));
        observer.complete();
      });
    });
  }

  public onUserSelected(event) {
    this.ownerData = event.item;
    if (this.ownerData?.groups.length > 0) {
      this.loadingForm = true;
      this.getAgreement(this.ownerData.groups);
    }
  }

  private getAgreement(groups) {
    remove(groups, (gr: any) => {
      gr.includes('-resources');
    });
    this.agreementService
      .query({ groups: groups[0] })
      .pipe(finalize(() => (this.loadingForm = false)))
      .subscribe({
        next: (sRes) => {
          if (sRes?.data?.items.length > 0) {
            this.ownerData.agreement = sRes.data.items[0]._id;
          }
        },
        error: (err) => {
          this.toastr.error(
            `There was an error trying to retrieve the user's agreement. Try again later`,
          );
          this.trackingService.track('Error getting agreement', {
            ownerData: this.ownerData,
            error: err,
          });
        },
      });
  }

  onSubmit() {
    if (this.form.valid) {
      let assignationData = {
        initOwnerUid: this.ownerData.id,
        ownerName: this.form.value.owner,
        initGroups: this.ownerData.groups || [],
        currentState: this.form.value.status,
        agreement: this.ownerData.agreement,
      };
      this.sendAssignation(assignationData);
    } else {
      // Form is invalid, display validation errors
      this.form.markAllAsTouched();
    }
  }

  sendAssignation(assignationData) {
    this.sendAssignationData.emit(assignationData);
    this.modalRef.hide();
  }

  public hideModal() {
    this.modalRef.hide();
  }
}
