import {
  ChangeDetectionStrategy,
  Component,
  input,
  computed,
  Output,
  EventEmitter,
} from '@angular/core';
import { ChipComponent } from '../chip/chip.component';
import { NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  standalone: true,
  imports: [ChipComponent, NgClass, ReactiveFormsModule],
  templateUrl: './text-area.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaComponent {
  rows = input<number>(3);
  label = input<string>();
  placeholder = input<string>('');
  required = input<boolean>(false);
  errors = input<ValidationErrors>();
  hideErrors = input(false, {
    transform: (value: boolean | string) => (typeof value === 'string' ? value === '' : value),
  });

  control = input.required<FormControl>();
  @Output() iconClick = new EventEmitter<void>();

  errorsMessages = {
    required: 'This field is required',
  };

  messageError = computed(() => {
    if (this.errors()) {
      return this.errorsMessages[Object.keys(this.errors() as ValidationErrors)[0]];
    }
    return '';
  });

  dynamicStyles = computed(() => {
    const border =
      this.control().touched && this.errors() ? 'border-error text-error' : 'border-primary';
    return `${border}`;
  });

  iconInteraction() {
    this.iconClick.emit();
  }
}
