@if (col.dataType === 'link') {
<a
  [routerLink]="[col.urlLink, data[col.urlField]]"
  class="font-secondary text-secondary hover:underline"
>
  {{ col.linkTitle || data[col.field] }}
</a>
} @if(col.icon){
<a class="icon-container font-secondary text-secondary hover:underline">
  @if (col.iconText) {
  <span (click)="getRowAction(tabID, col, data)">
    {{ col.iconText }}
  </span>
  } @if( !col.hasOwnProperty('displayIf') || (col.hasOwnProperty('displayIf') && (data[col.field]
  === col.displayIf || (col.displayIf && col.displayIf.indexOf(data[col.field]) !== -1)))){
  <i
    class="{{ col.icon }} table-icon text-secondary"
    disabled="true"
    (click)="getRowAction(tabID, col, data)"
    >{{ col.iconMaterialText || '' }}</i
  >
  }
</a>
}

<div [ngClass]="{ 'inline-block': col.tooltipInfo }">
  @if(col.dataType === 'text' && col.oppBody){
  <p class="!pl-0">{{ data[col.field] }}</p>
  }
</div>

@if(col.dataType === 'object'){
<div [ngClass]="{ 'inline-block': col.tooltipInfo }">
  @for (property of col.objectProperties; track $index) {
  <div [ngClass]="{ 'inline-block': col.tooltipInfo }" class="mt-1 mb-1">
    @if(property.dataType === 'text'){
    <p>
      {{
        data[col.field] &&
        (!property.hasOwnProperty('displayIf') ||
          (data[col.field]['id'] && property.displayIf === data[col.field]['id']))
          ? data[col.field][property.field]
          : ''
      }}
    </p>
    } @if(property.dataType === 'action-link'){
    <a
      class="font-secondary text-secondary hover:underline"
      (click)="getRowAction(tabID, col, data)"
      >{{
        data[col.field] &&
        (!property.hasOwnProperty('displayIf') ||
          (data[col.field]['id'] && property.displayIf === data[col.field]['id']))
          ? data[col.field][property.field]
          : ''
      }}</a
    >
    } @if(property.dataType === 'object'){
    <div>
      @for (subProperty of property.objectProperties; track $index) { @if(subProperty.dataType ===
      'icon'){
      <div>
        <a class="icon-container">
          <i
            class="{{ subProperty.icon }} table-icon"
            disabled="true"
            (click)="getRowAction(tabID, subProperty, data)"
          >
          </i>
        </a>
        <p>{{ subProperty.label }}</p>
      </div>
      } }
    </div>
    }
  </div>
  }
</div>
}
