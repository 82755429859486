import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'sostereo-services';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../shared/services/common.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-session-buttons',
  templateUrl: './session-buttons.component.html',
  styleUrls: ['./session-buttons.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class SessionButtonsComponent implements OnInit {
  public loggedUser: any;
  public subdomain: string;
  public tenantId: string;
  private userUpdate$: Subscription = new Subscription();
  public showButtons = false;

  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private router: Router,
    @Inject('environment') private environment,
  ) {
    this.loggedUser = this.authenticationService.getCurrentUser();
    this.userUpdate$ = this.authenticationService.accessData.subscribe((user) => {
      this.loggedUser = user;
    });
  }

  ngOnInit() {
    this.subdomain = this.commonService.getSubdomain();
    if (this.subdomain) {
      this.commonService.getCompany(this.subdomain, (company) => {
        console.log(company);
        this.tenantId = this.commonService.tenantId;
        this.showButtons = true;
      });
    } else {
      this.showButtons = true;
    }
  }

  public subdomainLogin() {
    const url = window.location.pathname + window.location.search;
    this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
  }

  public signUp() {
    const url = window.location.pathname + window.location.search;
    this.router.navigate(['/signup'], { queryParams: { returnUrl: url } });
  }

  public login() {
    this.subdomainLogin();
  }
}
