import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgIf, NgFor, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, CurrencyPipe],
})
export class TermsModalComponent implements OnInit {
  @ViewChild('termsModal', { static: true }) modalTemplate: TemplateRef<any>;
  public modalRef: BsModalRef;
  public agreement;
  public subdomain;
  constructor(private modalService: BsModalService) {}

  ngOnInit() {}

  public showModal(data) {
    this.modalRef = this.modalService.show(this.modalTemplate, { class: 'modal-lg' });
    this.agreement = data.agreement;
    this.subdomain = data.subdomain;
  }

  public hideModal() {
    this.modalRef.hide();
  }
}
