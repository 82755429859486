export const BtnClasses = {
  primary: 'rounded-[16px] px-[20px] py-[10px] text-[16px] sos-primary-btn min-h-[50px]',
  secondaryDark: 'sos-secondary-btn-margin sos-primary-btn',
  secondaryLight: 'sos-secondary-btn-margin sos-light-btn',
  outline: 'rounded-[16px] px-[15px] py-[6px] text-[16px] sos-outlined-btn',
  squaredOutline: 'rounded-[16px] px-[10px] py-[10px] text-[16px] sos-outlined-btn',
  secondaryOutlined: 'sos-secondary-btn-margin sos-outlined-btn',
  squared: 'rounded-[16px] px-[10px] py-[10px] text-[16px] sos-primary-btn',
  squaredHover: 'rounded-[16px] px-[10px] py-[10px] text-[16px] sos-squared-hover-btn',
  darkRounded: 'sos-dark-rounded-btn',
  lightRounded: 'sos-light-rounded-btn',
  transparent: 'rounded-[16px] px-[20px] py-[10px] text-[16px] sos-transparent-btn min-h-[50px] ',
  transparentRounded: 'sos-transparent-rounded-btn',
};

export type ClassGroup =
  | 'primary'
  | 'secondaryDark'
  | 'secondaryLight'
  | 'outline'
  | 'squaredOutline'
  | 'secondaryOutlined'
  | 'darkRounded'
  | 'lightRounded'
  | 'transparentRounded'
  | 'squared'
  | 'transparent'
  | 'squaredHover';
