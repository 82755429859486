import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { InquiriesService, PlaylistsStereoSpacesService, SongsService } from 'sostereo-services';
import { cloneDeep, isEmpty, remove, uniq } from 'lodash-es';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DataPlayer } from '../../../playlists/components/playlist.model';
import {
  inquiriesOptions,
  inquiriesDefaultValues,
  currencyArray,
} from './configs/inquiries.config';
import { Router } from '@angular/router';
import { GenericModalService } from 'src/app/angular/shared/services/generic-modal.service';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BtnComponent } from 'src/app/components/btn/btn.component';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    BtnComponent,
    MatTooltip,
  ],
  selector: 'app-catalog-owner-communication-modal',
  templateUrl: './catalog-owner-communication-modal.component.html',
  styleUrls: ['./catalog-owner-communication-modal.component.scss'],
})
export class CatalogOwnerCommunicationModalComponent implements OnInit {
  @ViewChild('catalogOwnerCommunicationModal', { static: true }) modalTemplate: TemplateRef<any>;
  @Input() uniqueSong: any = {};
  @Input() selectedInquiry;
  @Output() inquiryCreated = new EventEmitter();
  public playlistData: DataPlayer;
  public songData: any;
  public modalRef: BsModalRef;
  public noInquiries = false;
  public prevInquiryIdx = 0;
  inquiryForm: FormGroup;
  public loadingRequest = false;
  public inquiriesTypes = cloneDeep(inquiriesOptions);
  public inquiryType: any = this.inquiriesTypes[0];
  private now = new Date().toISOString();
  public deadlineMin = `${this.now.split('T')[0]}`;
  public targetMetadata: any = {};
  public targetType;
  public songTargetId;
  public currencyArray = currencyArray;
  public selectedCurrency = currencyArray[0];
  public adsFields = ['brand', 'project', 'startDate'];
  public filmAndTvFields = [
    'networkOrStudio',
    'tvShowOrFilm',
    'episode',
    'airDate',
    'showSypnosis',
  ];

  public gamingFields = ['company', 'project', 'startDate', 'gameSypnosis'];

  public selectedDropdownnValue = { value: '', field: '' };
  public createdInquiryId;
  public uniqueSongStatus;
  public validSpecialSongFields = true;
  public loading = true;

  public previousVersionValues = { subject: '', message: '' };

  constructor(
    private modalService: BsModalService,
    private trackingService: TrackingService,
    private inquiriesService: InquiriesService,
    private playlistsService: PlaylistsStereoSpacesService,
    private songsService: SongsService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private router: Router,
    private genericModalService: GenericModalService,
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  private setForm() {
    let form = {};
    this.inquiryType.fields.forEach((iq) => {
      if (
        !isEmpty(this.selectedInquiry) &&
        this.selectedInquiry.deadline &&
        this.selectedInquiry.deadline !== ''
      ) {
        this.selectedInquiry.deadline = this.selectedInquiry.deadline.substring(0, 16); // 0 to 16 to include date and time
      }
      form[iq.slug] = new FormControl(
        !isEmpty(this.selectedInquiry) && this.selectedInquiry[iq.slug] && !iq.options
          ? this.selectedInquiry[iq.slug]
          : iq.value,
        iq.required ? { validators: Validators.required } : {},
      );
      if (iq.options) {
        iq.options.forEach((op) => {
          form[op.slug] = new FormControl(
            !isEmpty(this.selectedInquiry) &&
            this.selectedInquiry[iq.slug] &&
            this.selectedInquiry[iq.slug][op.slug]
              ? this.selectedInquiry[iq.slug][op.slug]
              : iq.value,
            op.required ? { validators: Validators.required } : {},
          );
          this.changeDetectorRef.detectChanges();
        });
      }
      this.changeDetectorRef.detectChanges();
    });
    this.inquiryForm = new FormGroup(form);
    if (!isEmpty(this.selectedInquiry) && this.inquiryType.slug === 'exclusivity') {
      this.messageUpdate('exclusivity');
    }
    if (this.inquiryType.slug === 'version') {
      this.removeVersionOption();
    }
    this.changeDetectorRef.detectChanges();
  }

  private getPlaylistData() {
    this.playlistsService
      .get(this.playlistData._id, { limit: this.playlistData.paging.totalItems })
      .subscribe(
        (res) => {
          console.log('Res PL inquiry >>> ', res);
          this.playlistData.items = res.data.tracks.items;
          this.playlistData.paging = res.data.tracks.paging;
          this.mapInquiriesToPlSongs();
        },
        (err) => {
          console.error('Error to get playlistsStereoSpacesService >>>> ', err);
          this.trackingService.track('Error getting all the playlists songs', {
            playlistId: this.playlistData?._id,
            error: err,
          });
          this.mapInquiriesToPlSongs();
        },
      );
  }

  private mapInquiriesToPlSongs() {
    // This is to remove 'stems' in the dropdown if it all the songs have stemsAvailable
    this.removeStemsOption();
    // This is to remove 'lyrics' in the dropdown if it all the songs have lyrics or are instrumental
    this.removeLyricsOption();
    this.inquiriesTypes.forEach((iq) => {
      iq.items = isEmpty(this.uniqueSong)
        ? this.playlistData.items.map((tb) => {
            return { targetId: tb.trackId };
          })
        : [{ targetId: this.uniqueSong.trackId }];
      iq.visible = iq.items.length > 0;
    });
    if (isEmpty(this.selectedInquiry)) {
      this.inquiryType = this.inquiriesTypes.find((iq) => iq.visible);
    }
    setTimeout(() => {
      this.setForm();
    });
    this.noInquiries = !this.inquiriesTypes.every((iq) => iq.visible);
  }

  public removeStemsOption() {
    if (
      this.targetType === 'playlists' &&
      this.playlistData.items.every(
        (s) => s.stemsAvailable === 'uploaded' || s.stemsAvailable === 'no',
      )
    ) {
      remove(this.inquiriesTypes, (inq: any) => inq.slug === 'stems');
    } else if (
      this.targetType === 'songs' &&
      (this.songData.stemsAvailable === 'uploaded' ||
        this.songData.stemsAvailable === 'no' ||
        this.songData.stems?.length > 0)
    ) {
      remove(this.inquiriesTypes, (inq: any) => inq.slug === 'stems');
    }
    this.changeDetectorRef.detectChanges();
  }

  public removeLyricsOption() {
    if (
      this.targetType === 'playlists' &&
      this.playlistData.items.every(
        (s) => (s.vocals && s.hasOwnProperty('lyric') && s.lyric !== '') || !s.vocals,
      )
    ) {
      remove(this.inquiriesTypes, (inq: any) => inq.slug === 'lyrics');
    } else if (
      this.targetType === 'songs' &&
      ((this.songData?.vocals &&
        this.songData.hasOwnProperty('lyric') &&
        this.songData.lyric?.content !== '' &&
        Object.keys(this.songData?.lyric)?.length > 0) ||
        !this.songData?.vocals)
    ) {
      remove(this.inquiriesTypes, (inq: any) => inq.slug === 'lyrics');
    }
    this.changeDetectorRef.detectChanges();
  }

  public removeVersionOption() {
    const possibleVersions = this.inquiryType.fields.find(
      (f) => f.slug === 'version',
    )?.dropdownOptions;
    if (this.targetType === 'playlists') {
      if (
        this.playlistData.items.every(
          (s: any) => Object.values(s.versions?.instrumental || {}).length > 0,
        )
      ) {
        remove(possibleVersions, (version: any) => version.slug === 'instrumental');
      }
      possibleVersions.forEach((v) => {
        if (
          this.playlistData.items.every(
            (song: any) =>
              song.songVersion === v.slug ||
              song?.versions?.others?.find((sv) => sv?.songVersion === v.slug),
          )
        ) {
          remove(possibleVersions, (version: any) => version.slug === v.slug);
        }
      });
    } else if (this.targetType === 'songs') {
      remove(possibleVersions, (version: any) => this.songData?.versions?.includes(version.slug));
    }
    this.changeDetectorRef.detectChanges();
  }

  public showModal(playlist: DataPlayer, selectedInquiry?, songData?, targetType?) {
    this.loading = true;
    this.selectedInquiry = selectedInquiry || {};
    this.uniqueSong = songData || {};
    if (this.uniqueSong?.version || this.uniqueSong?.songVersion) {
      this.uniqueSong.version = this.uniqueSong?.version || this.uniqueSong?.songVersion;
    }
    this.targetType = targetType || 'playlists';
    if (!isEmpty(this.selectedInquiry)) {
      this.inquiryType = this.inquiriesTypes.find((iq) => iq.slug === this.selectedInquiry._id);
    }
    setTimeout(() => {
      this.setForm();
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });

    if (this.targetType === 'playlists') {
      this.playlistData = playlist;
      this.getPlaylist();
      this.targetMetadata = { name: playlist.name, description: playlist.description };
    }
    if (this.targetType === 'songs') {
      this.songTargetId = songData.trackId;
      this.songsService.get(this.songTargetId, {}).subscribe((res) => {
        this.songData = res.data;
        this.songData.versions = songData?.versions;
        this.uniqueSongStatus = songData.status;
        if (playlist) {
          this.playlistData = playlist;
          this.targetMetadata = {
            name: this.playlistData?.name,
            description: this.playlistData?.description,
          };
        } else {
          this.targetMetadata = {
            name: `${songData?.name} (${songData.songVersion})`,
            description: '',
          };
        }
        this.mapInquiriesToPlSongs();
      });
      this.targetMetadata = { name: songData.name, description: '' };
    }
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.trackingService.track('Catalog owner communication', {
      action: 'Open',
    });
  }

  getPlaylist() {
    if (this.playlistData) {
      if (this.playlistData.paging.totalPages === 1) {
        this.mapInquiriesToPlSongs();
      } else {
        this.getPlaylistData();
      }
    }
  }

  public hideModal() {
    this.modalRef.hide();
    this.loading = true;
    this.inquiryForm.patchValue({});
    this.inquiryForm.reset();
    this.inquiriesTypes = cloneDeep(inquiriesOptions);
    this.inquiryType = this.inquiriesTypes[0];
    this.changeDetectorRef.detectChanges();
    this.trackingService.track('Catalog owner communication', {
      action: 'Closed',
    });
  }

  public onSelectChange() {
    if (this.prevInquiryIdx !== -1) {
      this.clearSelectInquiryType(this.inquiriesTypes[this.prevInquiryIdx]);
    }
    this.setForm();
  }

  private clearSelectInquiryType(inquiry) {
    // This is to clear all the fields in case the user filled them out
    inquiry.fields.forEach((iq) => {
      iq.value = inquiriesDefaultValues[inquiry.slug][iq.slug] || '';
      if (iq.options) {
        iq.options.forEach((op) => {
          op.value = '';
        });
      }
    });
  }

  getCurrentInquiryIdx(inquiry) {
    this.prevInquiryIdx = this.inquiriesTypes.findIndex((iq) => inquiry.slug === iq.slug);
  }

  createInquiry(inquiry: any) {
    this.inquiriesService
      .post(inquiry)
      .pipe(finalize(() => (this.loadingRequest = false)))
      .subscribe(
        (res) => {
          console.log('Post inquiry >>> ', res);
          this.toastr.success('Inquiry was created', '');
          if (res.data) {
            res.data.forEach((dt) => {
              if (dt.status === 'not-send' || dt.status === 'error') {
                this.toastr.error(
                  `The email wasn't sent ${
                    dt.ccRecipients?.length > 0 ? 'to' : ''
                  } ${dt.ccRecipients.toString()}`,
                  dt.statusMessage || 'Error',
                );
              }
            });
            if (this.inquiryType.slug === 'placement') {
              this.createdInquiryId = res.data[0]?._id;
              this.showCompletedModal();
            }
          }
          this.hideModal();
          this.inquiryCreated.emit(inquiry.inquiryType);
        },
        (err) => {
          console.log('Inquiries error >>> ', err);
          this.trackingService.track('Error creating inquiry', {
            inquiry: inquiry,
            playlistId: this.playlistData?._id,
            error: err,
          });
          this.toastr.error(err?.error?.message, 'There was a problem creating the inquiry');
        },
      );
  }

  public onSubmit() {
    this.removeSongsOnStatus();
    // This is to remove songs that already have stems or if they don't exist
    if (this.inquiryType.slug === 'stems' && this.targetType === 'playlists') {
      this.removeSongsWithStems();
    }
    // This is to remove songs that already have lyrics or are inst
    if (this.inquiryType.slug === 'lyrics' && this.targetType === 'playlists') {
      this.removeSongsLyricsOrInst();
    }
    //This is to remove songs that already have the selected version
    if (this.inquiryType.slug === 'version' && this.targetType === 'playlists') {
      this.removeSongsWithSelectedVersion();
    }
    let inquiry: any = this.mapInquiry();
    if (this.targetType === 'songs') {
      inquiry.items = [{ targetId: this.songTargetId }];
      if (
        !this.playlistData ||
        (this.playlistData && Object.keys(this.playlistData)?.length === 0)
      ) {
        inquiry.targetId = this.songTargetId;
        inquiry.targetType = 'songs';
      } else {
        inquiry.targetType = 'playlists';
      }
    }
    if (this.inquiryType.slug === 'placement') {
      inquiry.projectDetails.fee = {
        total: inquiry.projectDetails?.total?.replace(/,/g, ''),
        otherCost: inquiry.projectDetails?.otherCost?.replace(/,/g, ''),
        nonRecoupableCost: inquiry.projectDetails?.nonRecoupableCost?.replace(/,/g, ''),
        currency: this.selectedCurrency.slug,
        commissionPercentage: inquiry.projectDetails?.commissionPercentage?.replace(/%/g, ''),
      };
      if (
        inquiry[this.selectedDropdownnValue.field] &&
        inquiry[this.selectedDropdownnValue.field] !== ''
      ) {
        inquiry[this.selectedDropdownnValue.field] = this.selectedDropdownnValue.value;
      }
      if (+inquiry.projectDetails.fee.otherCost > +inquiry.projectDetails.fee.total) {
        this.toastr.warning('Recoupable Costs cannot be greater than Total Placement Fee');
        return;
      }
      delete inquiry.projectDetails.total;
      delete inquiry.projectDetails.otherCost;
      delete inquiry.projectDetails?.nonRecoupableCost;
      delete inquiry.projectDetails.currency;
      delete inquiry.projectDetails?.commissionPercentage;
    }
    if (this.inquiryType.slug === 'version') {
      inquiry.projectDetails = { songVersion: this.selectedDropdownnValue.value };
    }
    this.loadingRequest = true;
    if (this.validSpecialSongFields) {
      this.createInquiry(inquiry);
    } else {
      this.loadingRequest = false;
    }
  }

  private mapInquiry() {
    return this.inquiryType.fields.reduce((acc, item) => {
      acc.inquiryType = this.inquiryType.slug;
      acc.targetId = this.playlistData?._id;
      acc.items = this.inquiryType.items;
      acc.targetMetadata = this.targetMetadata;
      if (item.options) {
        acc[item.slug] = item.options.reduce((acc2, item2) => {
          if (item2.value || this.inquiryForm.value[item2.slug]) {
            acc2[item2.slug] = this.inquiryForm.value[item2.slug] || item2.value;
          }
          return acc2;
        }, {});
      } else if (item.value || this.inquiryForm.value[item.slug]) {
        if (item.slug === 'deadline' && item.value !== '') {
          var date = new Date(item.value);
          acc[item.slug] = date.toISOString();
        } else {
          acc[item.slug] = this.inquiryForm.value[item.slug] || item.value;
        }
      }
      return acc;
    }, {});
  }

  private removeSongsWithStems() {
    this.playlistData.items.forEach((s) => {
      if (s.stemsAvailable === 'uploaded' || s.stemsAvailable === 'no') {
        remove(this.inquiryType.items, (i: any) => i.targetId === s.trackId);
      }
    });
  }

  private removeSongsLyricsOrInst() {
    this.playlistData.items.forEach((s) => {
      if ((s.vocals && s.hasOwnProperty('lyric') && s.lyric !== '') || !s.vocals) {
        remove(this.inquiryType.items, (i: any) => i.targetId === s.trackId);
      }
    });
  }

  private removeSongsWithSelectedVersion() {
    const selectedVersion = this.inquiryType.fields.find((f) => f.slug === 'version').value;
    remove(
      cloneDeep(this.playlistData.items),
      (s: any) =>
        s.songVersion === selectedVersion ||
        s.versions?.others?.some((v) => v.songVersion === selectedVersion) ||
        (selectedVersion === 'instrumental' && Object.keys(s.versions?.instrumental)?.length > 0),
    );
  }

  // Removes songs from sending the inquiry if they have status icebox or deleted
  private removeSongsOnStatus() {
    if (this.targetType === 'playlists') {
      this.playlistData.items.forEach((s) => {
        if (s.status === 'icebox' || s.status === 'deleted') {
          if (this.inquiryType.items.length < 2) {
            this.toastr.warning(
              "You can't create Artist Communications for songs with icebox or deleted status",
            );
          }
          remove(this.inquiryType.items, (i: any) => i.targetId === s.trackId);
        }
      });
    } else if (this.targetType === 'songs') {
      if (this.uniqueSongStatus === 'icebox' || this.uniqueSongStatus === 'deleted') {
        this.toastr.warning(
          "You can't create Artist Communications for songs with icebox or deleted status",
        );
        this.validSpecialSongFields = false;
      }
    }
  }

  public messageUpdate(inqType: string, subjectField?: string) {
    if (inqType === 'exclusivity') {
      const messageIdx = this.inquiryType.fields.findIndex((iq) => iq.slug === 'message');
      if (messageIdx !== -1 && this.inquiryForm.controls.exclusivity?.value !== null) {
        const category = this.inquiryForm.controls.exclusivity.value;
        this.inquiryType.fields[messageIdx].value = this.inquiryType.fields[
          messageIdx
        ].value.replaceAll(/"([^"]+)"/g, `"${category}"`);
      }
    } else {
      const messageIdx = this.inquiryType.fields.findIndex((iq) => iq.slug === 'subject');
      if (messageIdx !== -1 && this.inquiryForm.controls.brands?.value !== null) {
        if (subjectField === 'refNumber') {
          const refNumber = this.inquiryForm.controls.refNumber.value;
          this.inquiryType.fields[messageIdx].value = this.inquiryType.fields[
            messageIdx
          ].value.replaceAll(/#\w+/g, `#${refNumber || 'refNumber'}`);
        } else {
          const brandField =
            this.inquiryForm.controls.terms.value === 'ads'
              ? this.inquiryForm.controls.brand.value
              : this.inquiryForm.controls.terms.value === 'gaming'
              ? this.inquiryForm.controls.company.value
              : this.inquiryForm.controls.networkOrStudio.value;
          this.inquiryType.fields[messageIdx].value = this.inquiryType.fields[
            messageIdx
          ].value.replaceAll(/"([^"]+)"/g, `"${brandField || 'Brand'}"`);
        }
      }
    }
  }

  public onCurrencyChange(event) {
    const currency = event.target.value;
    this.selectedCurrency = currencyArray.find((c) => c.slug === currency);
    this.changeDetectorRef.detectChanges();
  }

  public formatCurrency(event) {
    //Allow numbers only
    const inputElement = event.target as HTMLInputElement;
    let newValue = inputElement.value.replace(/[^0-9]/g, '');
    if (newValue === '') {
      inputElement.value = '';
      return;
    }
    const rawValue = parseInt(newValue, 10);
    const formattedValue = new Intl.NumberFormat().format(rawValue);
    inputElement.value = formattedValue;
  }

  preventNonNumericCharacters(event: KeyboardEvent) {
    const isNumeric = /^[0-9]$/.test(event.key);
    const controlKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];

    if (!isNumeric && !controlKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  preventDotOrComma(event: KeyboardEvent) {
    if (event.key === '.' || event.key === ',') {
      event.preventDefault();
    }
  }

  disableScroll(event: WheelEvent): void {
    event.preventDefault();
  }

  public onValueChange(event, field) {
    const value = event.target.value;
    this.selectedDropdownnValue.field = field;
    this.selectedDropdownnValue.value = this.inquiryType.fields
      .find((f) => f.slug === field)
      .dropdownOptions.find((option) => option.slug === value).slug;
    if (field === 'terms') {
      const selectedTermFields = this[`${value}Fields`];
      const termFields = uniq([...this.adsFields, ...this.filmAndTvFields, ...this.gamingFields]);
      this.inquiryType.fields
        .find((f) => f.slug === 'projectDetails')
        .options.filter((option) => {
          if (termFields.includes(option.slug)) {
            if (selectedTermFields.includes(option.slug)) {
              option.show = true;
            } else {
              option.show = false;
              option.value = '';
            }
            if (option.slug === selectedTermFields[0]) {
              this.inquiryForm.get(option.slug).addValidators(Validators.required);
              this.inquiryForm.get(option.slug).updateValueAndValidity();
              option.required = true;
            } else {
              this.inquiryForm.get(option.slug).removeValidators(Validators.required);
              this.inquiryForm.get(option.slug).updateValueAndValidity();
              option.required = false;
            }
          }
        });
      this.changeDetectorRef.detectChanges();
    } else {
      this.inquiryType.fields.forEach((f, index) => {
        if (f.slug === 'subject' || f.slug === 'message') {
          this.inquiryType.fields[index].value = f.value.replace(
            f.value.includes('<<SONG VERSION>>')
              ? '<<SONG VERSION>>'
              : this.previousVersionValues[f.slug] === 'Tvmix'
              ? 'TV Mix'
              : this.previousVersionValues[f.slug],
            value === 'mix' ? 'TV Mix' : value.charAt(0).toUpperCase() + value.slice(1),
          );
          this.previousVersionValues[f.slug] = value.charAt(0).toUpperCase() + value.slice(1);
        }
      });
    }
  }

  public onExclusivitySelect(event) {
    const show = event.target.value;
    this.inquiryType.fields
      .find((field) => field.slug === 'projectDetails')
      .options.filter((option) => {
        if (option.slug === 'exclusivity') {
          if (show === 'yes') {
            option.show = true;
          } else {
            option.show = false;
            option.value = '';
          }
        }
      });
  }

  public showCompletedModal() {
    this.genericModalService.showModal(
      {
        title: 'The Artist Communication has been successfully created!',
        message: 'You can take a look at the Draft now or do it later',
        cssClass: 'fa fa-check',
        size: 'modal-sm',
        btns: [
          {
            status: true,
            name: 'gotoOpportunity',
            data: { nameAction: 'gotoOpportunity' },
            cssClass: 'primary-btn',
            title: 'Go to Opportunity',
          },
          { kind: 'close', title: 'Close', cssClass: 'cancel-btn' },
        ],
      },
      this.gotoOpportunity.bind(this),
    );
  }

  public gotoOpportunity(event) {
    if (event && event?.data && event?.data?.nameAction === 'gotoOpportunity') {
      this.router.navigateByUrl(
        this.createdInquiryId ? `/opportunities/${this.createdInquiryId}` : '/opportunities/list',
      );
    }
  }

  public restrictMaxValue(event) {
    const inputElement = event.target as HTMLInputElement;
    const value = parseInt(inputElement.value, 10);
    if (value > 100) {
      inputElement.value = '100';
    }
  }

  isNumberValidation(evt) {
    if (['e', 'E', '+', '-'].includes(evt.key)) {
      evt.preventDefault();
    }
  }
}
