export function sostereoSidenav(
  environmentName,
  subdomain,
  loggedUser?,
  isArtistOrLabel?,
  helpCenterRole?,
) {
  switch (environmentName) {
    case 'sostereo':
      if (!loggedUser) {
        return [
          {
            id: 'discover',
            name: 'Search',
            allowed: ['public'],
            activeRoutes: ['/discover', '/music-sync-licensing/search'],
            disallowed: [],
            route: '/discover',
            googleIcon: 'search',
            hideForMobile: true,
            queryParamsHandling: 'merge',
          },
          {
            id: 'my-playlist',
            name: 'Playlists',
            allowed: ['public'],
            disallowed: [],
            route: '/music-sync-licensing/edit-playlist',
            params: 'firstPlId',
            googleIcon: 'playlist_play',
            badgeField: 'playlistsCount',
            hideForMobile: true,
            queryParamsHandling: 'merge',
          },
          {
            id: 'artists-list',
            name: 'Artists',
            allowed: ['public'],
            disallowed: [],
            route: '/artists-list',
            googleIcon: 'person_search',
            queryParamsHandling: 'merge',
          },
          {
            id: 'our-work',
            name: 'Our Work',
            allowed: ['public'],
            disallowed: [],
            route: '/our-work',
            googleIcon: 'local_fire_department',
            disallowSubdomain: true,
            hideForMobile: true,
          },
          {
            id: 'about-sostereo',
            name: 'About Us',
            allowed: ['public'],
            disallowed: [],
            route: '/landing',
            googleIcon: 'question_mark',
            subdomainOnly: true,
            hideHeader: true,
          },
          {
            id: 'send-brief',
            name: 'Send Brief',
            allowed: ['public'],
            disallowed: [],
            googleIcon: 'quickreply',
            route: '/submit-brief',
            hideHeader: true,
            hideForMobile: subdomain,
          },
          {
            id: 'help-center',
            name: 'Help Center',
            allowed: ['public'],
            disallowed: [],
            googleIcon: 'help',
            externalRoute: `blog/category/help/${helpCenterRole || 'stereobrands'}/`,
            hide: !subdomain,
          },
        ];
      } else {
        return [
          {
            id: 'dashboard',
            name: 'Dashboard',
            allowed: ['GetArtist', 'UpdateArtist', 'ListArtists'],
            disallowed: ['*', 'UpdateSearchSettings', 'GetAgreement'],
            route: '/artists/dashboard',
            googleIcon: 'grid_view',
            disallowSubdomain: true,
            hideForMobile: true,
          },
          {
            id: 'discover',
            name: 'Search',
            allowed: ['public'],
            activeRoutes: ['/discover', '/music-sync-licensing/search'],
            disallowed: [],
            route: '/discover',
            googleIcon: 'search',
            hideForMobile: true,
            queryParamsHandling: 'merge',
          },
          {
            id: 'my-playlist',
            name: 'Playlists',
            allowed: ['*', 'UpdatePlaylist'],
            disallowed: ['UpdateArtistDescription'],
            route: '/music-sync-licensing/edit-playlist',
            params: 'firstPlId',
            googleIcon: 'playlist_play',
            badgeField: 'playlistsCount',
            hideForMobile: true,
            queryParamsHandling: 'merge',
          },
          {
            id: 'sonic-analysis',
            name: 'Sonic Analysis',
            allowed: ['ListTiers'],
            disallowed: [],
            route: '/sonic-analysis',
            googleIcon: 'graphic_eq',
            disallowSubdomain: true,
          },
          {
            id: 'sonic-analysis',
            name: 'Sonic Analysis',
            allowed: ['public'],
            disallowed: [],
            route: '/sonic-analysis',
            googleIcon: 'graphic_eq',
            badgeField: 'trialSection',
            subdomainOnly: true,
          },
          {
            id: 'artists-list',
            name: 'Artists',
            allowed: ['*', 'UpdatePlaylist'],
            disallowed: ['UpdateArtistDescription'],
            route: '/artists-list',
            googleIcon: 'person_search',
            queryParamsHandling: 'merge',
          },
          {
            id: 'spaces',
            name: 'Projects',
            allowed: ['ListSpaces'],
            disallowed: [],
            route: '/spaces/list',
            googleIcon: 'dashboard',
            hideForMobile: true,
          },
          {
            id: 'profile',
            name: 'Profile',
            allowed: ['GetArtist', 'UpdateArtist'],
            disallowed: ['*', 'UpdateSearchSettings', 'ListArtists', 'GetAgreement'],
            route: '/artists/account-setup/me',
            googleIcon: 'portrait',
            badgeField: 'completeProfilePerc',
            hideForMobile: true,
          },
          {
            id: 'people',
            name: 'People',
            allowed: ['*', 'ListIdentities'],
            disallowed: [],
            route: '/iam/list',
            googleIcon: 'groups',
          },
          {
            id: 'companies',
            name: 'Companies',
            allowed: ['*', 'ListCompanies'],
            disallowed: [],
            route: '/companies',
            googleIcon: 'business',
          },
          {
            id: 'artists',
            name: 'Artists List',
            allowed: ['*', 'ListArtists'],
            disallowed: [],
            route: '/artists/list',
            googleIcon: 'stars',
          },
          {
            id: 'songs',
            name: 'Songs',
            allowed: ['*', 'ListSongs'],
            disallowed: [],
            route: '/songs',
            googleIcon: 'audiotrack',
          },
          {
            id: 'ads',
            name: 'Ads',
            allowed: ['*', 'ListAds'],
            disallowed: [],
            route: '/ads',
            googleIcon: 'video_library',
          },
          {
            id: 'ratingQueue',
            name: 'Rating queue',
            allowed: ['GetRatingQueue'],
            disallowed: [],
            route: '/rating/queue',
            googleIcon: 'queue_play_next',
          },
          {
            id: 'albums',
            name: 'Albums',
            allowed: ['*', 'ListAlbums'],
            disallowed: [],
            route: '/albums',
            googleIcon: 'album',
          },
          {
            id: 'restrictions',
            name: 'Restrictions',
            allowed: ['*', 'ListRestrictions'],
            disallowed: [],
            route: '/restrictions',
            googleIcon: 'do_not_disturb_on',
          },
          {
            id: 'analytics',
            name: 'Analytics',
            allowed: ['*', 'GetPlaylistAnalytics', 'UpdateSongRateStatus'],
            disallowed: ['*'],
            route: '/analytics',
            googleIcon: 'insights',
          },
          {
            id: 'tags',
            name: 'Tags',
            allowed: ['*', 'ListTags'],
            disallowed: [],
            route: '/tags',
            googleIcon: 'sell',
          },
          {
            id: 'sliders',
            name: 'Sliders',
            allowed: ['*', 'ListSliders'],
            disallowed: [],
            route: '/sliders/list',
            googleIcon: 'dynamic_feed',
          },
          {
            id: 'agreements',
            name: 'Agreements',
            allowed: ['*', 'ListAgreements'],
            disallowed: [],
            route: '/agreements',
            googleIcon: 'cases',
          },
          {
            id: 'tiers',
            name: 'Tiers',
            allowed: ['*', 'ListTiers'],
            disallowed: [],
            route: '/tiers',
            googleIcon: 'price_change',
          },
          {
            id: 'requests',
            name: 'Requests',
            allowed: ['ListMusicSupervisors', 'ListMusicsupervisors'],
            disallowed: ['*'],
            route: '/my-transactions',
            googleIcon: 'description',
          },
          {
            id: 'analytics',
            name: 'Analytics',
            allowed: ['*', 'GetPlaylistAnalytics'],
            disallowed: [],
            route: '/analytics',
            googleIcon: 'insights',
          },
          {
            id: 'transactions',
            name: 'Licenses',
            allowed: ['ListTransactions'],
            disallowed: [],
            route: '/transactions',
            googleIcon: 'receipt_long',
          },
          {
            id: 'draft',
            name: 'Draft',
            allowed: ['ListTransactions'],
            disallowed: [],
            route: '/transactions/draft',
            googleIcon: 'shopping_cart',
            badgeField: 'licenseDraft',
          },
          {
            id: 'transfer',
            name: 'Transfer',
            allowed: ['ListJobs'],
            disallowed: [],
            route: '/transfer',
            googleIcon: 'bolt',
          },
          {
            id: 'send-brief',
            name: 'Send Brief',
            allowed: ['public', 'CreatePlaylist'],
            disallowed: ['ListSongs'],
            googleIcon: 'quickreply',
            route: '/submit-brief',
            hideHeader: true,
            hideForMobile: subdomain,
          },
          {
            id: 'ingestion',
            name: 'Ingestion',
            allowed: ['*', 'ListIngestions'],
            disallowed: [],
            googleIcon: 'backup',
            route: '/ingestions/list',
          },
          {
            id: 'ddex',
            name: 'DDEX',
            allowed: ['*', 'ListIngestions'],
            disallowed: [],
            route: 'ingestions/ddex/list',
            googleIcon: 'change_circle',
          },
          {
            id: 'opportunities',
            name: 'Opportunities',
            allowed: ['ListInquiries'],
            disallowed: [],
            route: '/opportunities',
            googleIcon: 'campaign',
          },
          {
            id: 'about-sostereo',
            name: 'About Us',
            allowed: ['public'],
            disallowed: [],
            route: '/landing',
            googleIcon: 'question_mark',
            subdomainOnly: true,
            hideHeader: true,
            hideForMobile: true,
          },
          {
            id: 'our-work',
            name: 'Our Work',
            allowed: ['public'],
            disallowed: [],
            route: '/our-work',
            googleIcon: 'local_fire_department',
            disallowSubdomain: true,
            hideForMobile: !isArtistOrLabel,
          },
          {
            id: 'help-center',
            name: 'Help Center',
            allowed: ['public'],
            disallowed: [],
            googleIcon: 'help',
            externalRoute: `blog/category/help/${
              !helpCenterRole || helpCenterRole === 'admin' ? 'general' : helpCenterRole
            }/`,
            hide: !(helpCenterRole === 'ms' || helpCenterRole === 'admin') && !subdomain,
          },
        ];
      }
    case 'stereospaces':
      if (!loggedUser) {
        let options: any = [
          {
            id: 'home',
            name: 'Home',
            allowed: ['public'],
            disallowed: [],
            route: '/',
            activeRoutes: ['/', '/#our-work', '/#learn-more'],
            googleIcon: 'home',
            hideForMobile: true,
          },
        ];
        if (subdomain) {
          options = [
            {
              id: 'search',
              name: 'Search',
              allowed: ['public'],
              disallowed: [],
              route: '/search',
              googleIcon: 'search',
              hideForMobile: true,
              queryParamsHandling: 'merge',
            },
            {
              id: 'send-brief',
              name: 'Send Brief',
              allowed: ['public'],
              disallowed: [],
              googleIcon: 'quickreply',
              modal: 'briefSubmissionModalComponent',
            },
          ];
        }
        return options;
      } else {
        return [
          {
            id: 'search',
            name: 'Search',
            allowed: ['public'],
            disallowed: [],
            route: '/search',
            googleIcon: 'search',
            hideForMobile: true,
            queryParamsHandling: 'merge',
          },
          {
            id: 'my-playlist',
            name: 'Playlists',
            allowed: ['public'],
            disallowed: [],
            route: '/music-sync-licensing/edit-playlist',
            params: 'firstPlId',
            googleIcon: 'playlist_play',
            badgeField: 'playlistsCount',
            hideForMobile: true,
            queryParamsHandling: 'merge',
          },
          {
            id: 'spaces',
            name: 'Projects',
            allowed: ['ListSpaces'],
            disallowed: [],
            route: '/spaces/list',
            googleIcon: 'dashboard',
            hideForMobile: true,
          },
          {
            id: 'profile',
            name: 'Profile',
            allowed: ['GetArtist', 'UpdateArtist'],
            disallowed: ['UpdateSearchSettings', 'ListArtists', 'GetAgreement'],
            route: '/artists/account-setup/me',
            googleIcon: 'portrait',
            badgeField: 'completeProfilePerc',
          },
          {
            id: 'people',
            name: 'People',
            allowed: ['ListIdentities'],
            disallowed: [],
            route: '/iam/list',
            googleIcon: 'groups',
          },
          {
            id: 'companies',
            name: 'Companies',
            allowed: ['ListGroups'],
            disallowed: [],
            route: '/companies',
            googleIcon: 'business',
          },
          {
            id: 'artists',
            name: 'Artists List',
            allowed: ['ListTiers'],
            disallowed: [],
            route: '/artists/list',
            googleIcon: 'stars',
          },
          {
            id: 'songs',
            name: 'Songs',
            allowed: ['ListSongs'],
            disallowed: [],
            route: '/songs',
            googleIcon: 'audiotrack',
          },
          {
            id: 'ratingQueue',
            name: 'Rating queue',
            allowed: ['GetRatingQueue'],
            disallowed: [],
            route: '/rating/queue',
            googleIcon: 'queue_play_next',
          },
          {
            id: 'albums',
            name: 'Albums',
            allowed: ['ListAlbums'],
            disallowed: [],
            route: '/albums',
            googleIcon: 'album',
          },
          {
            id: 'restrictions',
            name: 'Restrictions',
            allowed: ['*', 'ListRestrictions'],
            disallowed: [],
            route: '/restrictions',
            googleIcon: 'do_not_disturb_on',
          },
          {
            id: 'tags',
            name: 'Tags',
            allowed: ['ListTags'],
            disallowed: [],
            route: '/tags',
            googleIcon: 'sell',
          },
          {
            id: 'sliders',
            name: 'Sliders',
            allowed: ['ListSliders'],
            disallowed: [],
            route: '/sliders/list',
            googleIcon: 'dynamic_feed',
          },
          {
            id: 'agreements',
            name: 'Agreements',
            allowed: ['ListAgreements'],
            disallowed: [],
            route: '/agreements',
            googleIcon: 'cases',
          },
          {
            id: 'tiers',
            name: 'Tiers',
            allowed: ['ListTiers'],
            disallowed: [],
            route: '/tiers',
            googleIcon: 'price_change',
          },
          {
            id: 'requests',
            name: 'Requests',
            allowed: ['ListMusicSupervisors', 'ListMusicsupervisors'],
            disallowed: [],
            route: '/my-transactions',
            googleIcon: 'description',
          },
          {
            id: 'transactions',
            name: 'Licenses',
            allowed: ['ListTransactions'],
            disallowed: [],
            route: '/transactions',
            googleIcon: 'receipt_long',
          },
          {
            id: 'draft',
            name: 'Draft',
            allowed: ['ListTransactions'],
            disallowed: [],
            route: '/transactions/draft',
            googleIcon: 'shopping_cart',
            badgeField: 'licenseDraft',
          },
          {
            id: 'transfer',
            name: 'Transfer',
            allowed: ['ListJobs'],
            disallowed: [],
            route: '/transfer',
            googleIcon: 'bolt',
          },
          {
            id: 'analytics',
            name: 'Analytics',
            allowed: ['GetPlaylistAnalytics'],
            disallowed: [],
            route: '/analytics',
            googleIcon: 'insights',
          },
          {
            id: 'send-brief',
            name: 'Send Brief',
            allowed: ['public', 'CreatePlaylist'],
            disallowed: ['ListSongs'],
            googleIcon: 'quickreply',
            modal: 'briefSubmissionModalComponent',
          },
          {
            id: 'ingestion',
            name: 'Ingestion',
            allowed: ['ListIngestions'],
            disallowed: [],
            googleIcon: 'backup',
            route: '/ingestions/list',
          },
          {
            id: 'opportunities',
            name: 'Opportunities',
            allowed: ['ListInquiries'],
            disallowed: [],
            route: '/opportunities',
            googleIcon: 'campaign',
          },
        ];
      }
  }
}
