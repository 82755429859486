<ng-template #licenseAssignationModalComponent>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        classGroup="squaredHover"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="sos-generic-modal-container sos-scrollbar-none !text-primary">
      <div class="modal-body">
        <h4 class="text-left mt-0 text-4xl lg:text-2xl">License Assignation</h4>
        <div>
          <div>
            <p class="!my-5">
              Select the user you wish to assign the license to and specify the desired status for
              the assignment
            </p>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div>
                <label for="owner">License assigned to:</label>
                <input
                  data-hj-allow
                  class="typeahead-input"
                  placeholder="Search user"
                  [typeahead]="suggestions$"
                  [typeaheadAsync]="true"
                  (typeaheadOnSelect)="onUserSelected($event)"
                  (keyup)="onUserTyped($event)"
                  formControlName="owner"
                  [typeaheadOptionField]="'email'"
                  [typeaheadMinLength]="0"
                  [typeaheadWaitMs]="300"
                  [typeaheadOptionsLimit]="4"
                />
                <app-chip
                  type="error"
                  [ngClass]="
                    form.get('owner').invalid && form.get('owner').touched
                      ? 'opacity-100'
                      : 'opacity-0'
                  "
                  >User is required</app-chip
                >
              </div>
              <div>
                <p class="">
                  If the user doesn't exist create one
                  <a [routerLink]="'/iam/list'" target="_blank">here</a>
                </p>
              </div>
              <div class="mt-5">
                <label for="status">Select status:</label>
                <br />
                <select formControlName="status" id="status">
                  <option value="" disabled>Select an option</option>
                  <option *ngFor="let option of licenseStatus" [value]="option.slug">
                    {{ option.name }}
                  </option>
                </select>
                <div *ngIf="form.get('status').invalid && form.get('status').touched">
                  <p style="color: red">Status is required</p>
                </div>
              </div>
              <div class="mt-5">
                <app-btn [disabled]="loadingForm || form.invalid" typeBtn="submit">
                  Accept
                </app-btn>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
