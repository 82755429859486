<div *ngFor="let commentItem of commentItems;let i = index">
  <div class="commentContent commentListSection comment-thumbnail border-radius"
       [style.left.px]="commentItem.left" style="bottom:100px;" *ngIf="commentItem.showComments">
    <a (click)="closeComments(commentItem)">
      <i class="fa fa-times pull-right commentActionBtn" aria-hidden="true"></i>
    </a>
    <!-- Time -->
    <div class="col-xs-12 col-sm-12 mb-2.5 pl-0">
      <div class="col-xs-2 pl-0">
        <span class="ss-icon-play_on text-[25px] text-primary"
              (click)="playSegment(commentItem)"></span>
      </div>
      <div class="col-xs-8 col-sm-8 artist-top-margin pl-1">
        <p class="mb-0 text-primary" style="float:left;margin-right:15px">Play Segment</p>
        <h4 class="now-playing inline-block">{{commentItem.startTime | wavesurferTimeFormat}}
          - {{commentItem.endTime | wavesurferTimeFormat}}</h4>
      </div>
      <div class="col-xs-2 pl-0">
        <!-- Next comment btn -->
        <button class="btn sm-btn primary-btn" style="margin: -5px 0 0 -35px;border-width: 1px;"
          (click)="goToNextComment(i)"> Next
        </button>
      </div>
    </div>
    <!-- Comments -->
    <div class="commentList comment-scroll custom-scroll-bar">
      <!-- Comment -->
      <div class="" *ngFor="let comment of commentItem.comments; let commentIndex = index">
        <div class="row">
          <div class="col-md-10">
            <small class="commentInfo" style="float:left;">&#64;{{comment.userNickname}}</small>
          </div>
          <div class="col-md-2"
               *ngIf="loggedUser && loggedUser.uid === comment.ownerUid || (router.url.indexOf('spaces/submission') !== -1)">
            <a (click)="toggleMoreOptions(comment)">
              <i class="fa fa-ellipsis-h pull-right commentActionBtn" aria-hidden="true"></i>
            </a>
            <a *ngIf="comment.showMoreOptions" style="font-size:12px" class="pull-right text-primary"
               (click)="deleteComment(commentItem, commentIndex)">Delete</a>
          </div>
        </div>
        <p class="text-primary" style="white-space: pre-line">{{comment.message}}</p>
      </div>
    </div>
    <!-- Reply -->
    <div *ngIf="loggedUser || router.url.indexOf('spaces/submission') !== -1" [appAllowedTo]="'CreateComment'">
      <textarea data-hj-allow class="form-control border-radius comment-thumbnail" style="resize: none;"
        type="text" name=""
                    rows="3" cols="35" placeholder="Add a new comment" [(ngModel)]="message"
                    (keydown)="onKeydown($event)"></textarea>
      <span>
        <button class="btn primary-btn mr-2.5 mt-2.5" [ngClass]="{'disabled': message === ''}"
                (click)="saveComment(commentItem)">
              Reply
        </button>
      </span>
    </div>
    <div *ngIf="!loggedUser && router.url.indexOf('spaces/submission') === -1">
      <a (click)="login()" class="btn-login" style="font-weight: bold;">LOGIN to reply</a>
    </div>
    <!-- /Reply -->
  </div>
  <a (click)="toggleComments(commentItem)" *ngIf="commentItem.hasOwnProperty('left') && commentItem.width">
    <i class="commentBubble fa fa-comments" [style.left.px]="commentItem.left">
      <p>{{commentItem?.comments?.length}}</p>
    </i>
    <!-- </div> -->
  </a>
  <div class="timeArea" [style.left.px]="commentItem.left" *ngIf="commentItem.hasOwnProperty('left') && commentItem.width"
       [style.width.px]="commentItem.width" style="position: absolute; z-index: 3; pointer-events: none;"></div>
</div>
