import { Component, Output, EventEmitter, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { BtnClasses, ClassGroup } from './btn.config';

@Component({
  selector: 'app-btn',
  standalone: true,
  imports: [NgClass],
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss'],
})
export class BtnComponent {
  typeBtn = input<HTMLButtonElement['type']>('button');
  classGroup = input<ClassGroup>('primary');
  width = input<'auto' | 'full'>('auto');
  loading = input(false);
  disabled = input(false);
  selected = input<boolean>(false);
  styles = input<string>('');
  btnClasses = BtnClasses;

  @Output() clicked = new EventEmitter();

  onClick() {
    this.clicked.emit();
  }
}
