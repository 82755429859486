import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-logo-lottie',
  standalone: true,
  imports: [LottieComponent, RouterLinkWithHref],
  templateUrl: './logo-lottie.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoLottieComponent {
  private animationItem: any;
  options: AnimationOptions = {
    path: '/assets/animations/lottie/Logo/Logo-header-animation.json',
    autoplay: false,
    loop: false,
  };

  styles: Partial<CSSStyleDeclaration> = {
    width: '100%',
    height: '100%',
  };

  animationCreated(animationItem: any): void {
    this.animationItem = animationItem;
  }

  playAnimation(): void {
    if (this.animationItem) {
      this.animationItem.goToAndStop(0, true);
      this.animationItem.play();
    }
  }
}
