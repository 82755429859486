<div class="flex flex-col">
  <div class="col-xs-12 pl-0 pr-0 flex w100-percent" style="gap: 10px">
    <div class="flex items-center">
      <div class="spinner center-elm-widget" *ngIf="control.loading">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5 hide-line"></div>
        <div class="rect6 hide-line"></div>
        <div class="rect7 hide-line"></div>
      </div>
      <span
        *ngIf="!control.loading"
        class="center-elm-widget text-primary"
        [ngStyle]="{ 'font-size': waveHeight + 'px'}"
        [ngClass]="{ 'ss-icon-stop': control.play, 'ss-icon-play_on': !control.play }"
        (click)="togglePlay()"
      ></span>
    </div>

    <div
      id="waveform-generic"
      [ngClass]="{
        'bottom-time': waveTimeFormat === 'bottom',
        'right-time': waveTimeFormat === 'right',
        'progress-pitch': currentUrl.includes('pitch/')
      }"
      [ngStyle]="{
        height: waveHeight + 10 + 'px'
      }"
    >
      <div
        *ngIf="showSegment"
        id="resizable2"
        style="opacity: 0"
        [ngStyle]="{ height: waveHeight + 4 + 'px' }"
      >
        <span class="material-icons-outlined arrows ui-resizable-e" style="right: -9px"
          >swap_horiz</span
        >
        <span class="material-icons-outlined arrows ui-resizable-w" style="left: -8px"
          >swap_horiz</span
        >
      </div>
      <ng-container *ngFor="let item of segments">
        <div class="segment" [ngStyle]="{ left: item.left + 'px' }">
          <div class="line"></div>
          <span class="title-segment" (click)="toggleModal(item)"
            >{{ item.title }}
            <div *ngIf="item.id === modalId" class="detail flex flex-col">
              <a (click)="$event.stopPropagation(); hideModal()">
                <i class="fa fa-times pull-right" aria-hidden="true"></i>
              </a>
              <div class="flex items-center" style="gap: 10px">
                <span
                  class="ss-icon-play_on text-[25px] text-primary"
                  (click)="playSegment(item)"
                ></span>
                <div class="flex flex-col">
                  <p class="mb-1">Play Segment</p>
                  <h4 class="now-playing">
                    {{ item.start | wavesurferTimeFormat }} -
                    {{ item.end | wavesurferTimeFormat }}
                  </h4>
                </div>
              </div>
              <button class="btn outline-primary-btn" (click)="similarSegment(item)">
                Search similar to this segment
              </button>
            </div></span
          >
        </div>
      </ng-container>
      <div
        *ngIf="waveTimeFormat === 'bottom'"
        class="flex items-center justify-between"
        style="position: absolute; font-size: 12px; width: 100%; bottom: -11px; right: 0px"
      >
        <span [ngStyle]="{ color: currentUrl.includes('pitch/') ? '#000' : textColor }">{{
          control.currentTime | wavesurferTimeFormat
        }}</span>
        <span [ngStyle]="{ color: currentUrl.includes('pitch/') ? '#000' : textColor }">{{ control.duration | wavesurferTimeFormat }}</span>
      </div>
    </div>

    <div *ngIf="waveTimeFormat === 'right'" class="flex items-center">
      <span [ngStyle]="{ color: currentUrl.includes('pitch/') ? '#000' : textColor }">
        {{ control.currentTime | wavesurferTimeFormat }}</span>
      <span [ngStyle]="{ color: currentUrl.includes('pitch/') ? '#000' : textColor }" style="padding-left: 2px; padding-right: 2px">/</span>
      <span [ngStyle]="{ color: currentUrl.includes('pitch/') ? '#000' : textColor }">{{ control.duration | wavesurferTimeFormat }}</span>
    </div>
  </div>
</div>

<div *ngIf="showReferenceText" class="col-sm-12 pl-0 pr-0">
  <h6 class="color-dark mt-8">
    Reference section: {{ segment.start | wavesurferTimeFormat }} -
    {{ segment.duration | wavesurferTimeFormat }}
  </h6>
</div>
