<a
  #link
  class="flex items-center justify-center gap-[5px] sos-btn-font sos-disabled-btn no-underline hover:no-underline focus:no-underline {{
    btnClasses[classGroup()]
  }} {{ styles() }} {{ width() === 'full' ? 'w-full' : 'w-fit' }}"
  [routerLink]="isExternal() ? null : url()"
  [target]="target()"
>
  <ng-content></ng-content>
</a>
