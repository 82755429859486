<ng-template #sharePlaylistModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="cancel()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="modal-content">
      <div class="modal-body">
        <h4 class="modal-title">Share This Playlist</h4>
        <div class="row text-left mt-0">
          <div class="col-md-12 mt-2.5">
            <div class="col-xs-12 col-md-6 pl-0">
              <div class="flex items-center mt-2.5 mb-2.5">
                <input
                  type="checkbox"
                  [(ngModel)]="params[0].show"
                  style="cursor: pointer"
                  (click)="modifyUrl('showLyrics')"
                />
                <span class="ml-1.5" (click)="modifyUrl('showLyrics')" style="cursor: pointer"
                  >Display lyrics</span
                >
                <i
                  aria-hidden="true"
                  style="font-size: 18px"
                  class="material-icons-outlined table-material-icon ml-2"
                  [attr.data-icon]="'error_outline'"
                  tooltip="Check the box if you want the lyrics to be open when sharing the playlist"
                ></i>
              </div>
            </div>
            <div *ngIf="showCatalog" class="col-xs-12 col-md-6 pl-0">
              <div class="flex items-center mt-2.5 mb-2.5">
                <input
                  type="checkbox"
                  [(ngModel)]="params[1].show"
                  (click)="modifyUrl('catalog')"
                  style="cursor: pointer"
                />
                <span class="ml-1.5" (click)="modifyUrl('catalog')" style="cursor: pointer"
                  >Ignore brand agreements</span
                >
                <i
                  aria-hidden="true"
                  style="font-size: 18px"
                  class="material-icons-outlined table-material-icon ml-2"
                  [attr.data-icon]="'error_outline'"
                  tooltip="Clients will see all playlist songs independently of the brand agreement they have"
                ></i>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="input-group mt-2.5">
              <input
                type="text"
                class="form-control rounded-20px !h-[50px] text-[14px] lg:text-base !rounded-bl-2xl !rounded-tl-2xl"
                id="basic-url"
                [(ngModel)]="url"
                disabled
              />
              <span
                class="input-group-addon h-[50px] text-[14px] lg:text-base !rounded-br-2xl !rounded-tr-2xl"
                id="playlistUrlCopyBtn"
                (click)="copyUrlToClipboard()"
                >{{ copied ? 'Copied' : 'Copy' }}</span
              >
            </div>
          </div>
          <div class="w-full flex justify-center">
            <app-btn class="mt-[20px]" (click)="cancel()"> Ok </app-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
