<div class="commentContent" [style.left.px]="offsetLeft" *ngIf="showNewCommentPopUp">
  <div class="row">
    <div class="thumbnail comment-thumbnail border-radius">
      <div class="caption" style="padding-top: 10px;">
        <a (click)="hidePopUpAndCommentArea()">
          <i class="fa fa-times pull-right commentActionBtn" aria-hidden="true"></i>
        </a>
        <small *ngIf="loggedUser">&#64;{{loggedUser.nickname}}</small>
        <h4 style="padding-bottom: 10px;" class="now-playing">{{comment.startTime | wavesurferTimeFormat}}
          - {{comment.endTime | wavesurferTimeFormat}}</h4>
        <div *ngIf="loggedUser || router.url.indexOf('spaces/submission') !== -1">
          <textarea data-hj-allow class="form-control border-radius comment-thumbnail" style="resize: none;"
            type="text" name=""
                    rows="3" cols="35" placeholder="Add a new comment" [(ngModel)]="message" (keydown)="onKeydown($event)"></textarea>
          <span>
            <button class="btn primary-btn mr-2.5" [ngClass]="{'disabled': message === ''}" (click)="saveComment()">
              Comment
            </button>
            <button (click)="hidePopUpAndCommentArea()" class="btn cancel-btn">
              Cancel
            </button>
          </span>
        </div>
        <div *ngIf="!loggedUser && router.url.indexOf('spaces/submission') === -1">
          <a (click)="login()" class="btn-login" style="font-weight: bold;">LOGIN to comment</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="newCommentSection"
     (mousemove)="toggleCommentIcon($event)" (mouseleave)="toggleCommentIcon()"
     (click)="addNewComment($event)" *ngIf="track?.showNewComment && !showNewCommentPopUp">
  <div class="commentIconContent">
    <i class="fa fa-comment commentIcon"></i>
  </div>
</div>
<div class="commentSection" style="width: 100%;display: none;left: 0;"
     (mouseup)="updateCommentSection()"></div>
