<footer class="sos-footer">
  <div class="mx-auto w-[100vw]">
    <div class="md:flex gap-[14px] md:gap-[16px]">
      <div class="md:flex-1 flex flex-col justify-between">
        <h2 class="text-[32px] md:text-[42px] leading-tight md:max-w-md">
          <span
            class="w-[2.5rem] h-[2.5rem] md:w-[2rem] md:h-[2rem] mr-[0.25rem] relative top-[0.25rem]"
            style="display: inline-flex"
            (mouseenter)="playAnimation()"
            ><ng-lottie
              [options]="options"
              [styles]="styles"
              (animationCreated)="animationCreated($event)"
          /></span>
          We offer comprehensive, bespoke music solutions that add dimension to your story.
        </h2>
        <div class="mt-[4vh] md:mt-0 md:max-w-md">
          <h3 class="text-[24px] mb-4">Stay in touch</h3>
          <div class="flex bg-white rounded-[16px] overflow-hidden md:max-w-md text-base">
            <input
              (keydown)="$event.stopPropagation()"
              type="email"
              [(ngModel)]="email"
              placeholder="Your email"
              class="flex-1 px-4 py-3 border-none focus:outline-none text-[16px]"
            />
            <app-btn styles="hover:!bg-secondary" (click)="subscribe()">Subscribe</app-btn>
          </div>
          <div class="absolute bottom-[60px] md:static text-lg mt-[6vh] md:text-sm md:mt-[2vh]">
            Copyright © SoStereo {{ currentYear }}
          </div>
        </div>
      </div>
      <div class="md:flex-1">
        <nav
          class="mt-[4vh] flex flex-col text-[24px] md:text-[42px] md:mt-0 [&>a]:mt-[10px] [&>a]:md:mt-0 sos-route-links"
        >
          <a
            routerLink="/services"
            class="md:py-0 md:mb-4"
            (click)="
              trackEvent('Footer Section Clicked', 'click', {
                event_action: 'Footer section clicked',
                event_type: 'Footer Click',
                event_value: 'Services'
              })
            "
            >Services</a
          >
          <a
            routerLink="/our-work"
            class="md:py-0 md:mb-4"
            (click)="
              trackEvent('Footer Section Clicked', 'click', {
                event_action: 'Footer section clicked',
                event_type: 'Footer Click',
                event_value: 'Our work'
              })
            "
            >Our work</a
          >
          <a
            routerLink="/who-we-are"
            class="md:py-0 md:mb-4"
            (click)="
              trackEvent('Footer Section Clicked', 'click', {
                event_action: 'Footer section clicked',
                event_type: 'Footer Click',
                event_value: 'Who we are'
              })
            "
            >Who we are</a
          >
          <a
            routerLink="/discover"
            class="md:py-0 md:mb-4"
            (click)="
              trackEvent('Footer Section Clicked', 'click', {
                event_action: 'Footer section clicked',
                event_type: 'Footer Click',
                event_value: 'Discover'
              })
            "
            >Discover</a
          >
          <a routerLink="/submit-brief" class="md:py-0">Talk to a creative</a>
        </nav>

        <div class="md:flex md:justify-between md:items-end">
          <div class="w-full">
            <div
              class="mt-[4vh] flex text-[16px] md:text-base md:md:leading-6 [&>a]:hover:text-primary"
            >
              <a
                href="/blog"
                class="hover-underline-animation mr-[36px]"
                (click)="
                  trackEvent('Footer Section Clicked', 'click', {
                    event_action: 'Footer section clicked',
                    event_type: 'Footer Click',
                    event_value: 'News'
                  })
                "
                >News</a
              >
              <a
                href="/contact-us"
                class="hover-underline-animation mr-[36px]"
                (click)="
                  trackEvent('Footer Section Clicked', 'click', {
                    event_action: 'Footer section clicked',
                    event_type: 'Footer Click',
                    event_value: 'Contact'
                  })
                "
                >Contact</a
              >
              @if(!currentUser){
              <a
                routerLink="/signup"
                class="hover-underline-animation"
                (click)="
                  trackEvent('Footer Section Clicked', 'click', {
                    event_action: 'Footer section clicked',
                    event_type: 'Footer Click',
                    event_value: 'Sign In'
                  })
                "
                >Sign In</a
              >
              }
            </div>
            <div class="flex flex-col md:flex-row justify-between">
              <div class="mt-[4vh] md:mt-[6vh] sos-social-icons flex gap-8 md:gap-[1.1rem]">
                <app-btn-link
                  classGroup="darkRounded"
                  url="https://www.facebook.com/sostereomusic/"
                  target=" _blank"
                  [isExternal]="true"
                  (click)="
                    trackEvent('Footer Section Clicked', 'click', {
                      event_action: 'Footer section clicked',
                      event_type: 'Footer Click',
                      event_value: 'Facebook'
                    })
                  "
                >
                  <img src="./assets/images/icons/facebook.svg" alt="Facebook"
                /></app-btn-link>
                <app-btn-link
                  classGroup="darkRounded"
                  url="https://www.instagram.com/sostereomusic/"
                  target=" _blank"
                  [isExternal]="true"
                  (click)="
                    trackEvent('Footer Section Clicked', 'click', {
                      event_action: 'Footer section clicked',
                      event_type: 'Footer Click',
                      event_value: 'Instagram'
                    })
                  "
                >
                  <img src="./assets/images/icons/instagram.svg" alt="Instagram"
                /></app-btn-link>
                <app-btn-link
                  classGroup="darkRounded"
                  url="https://x.com/sostereo"
                  target=" _blank"
                  [isExternal]="true"
                  (click)="
                    trackEvent('Footer Section Clicked', 'click', {
                      event_action: 'Footer section clicked',
                      event_type: 'Footer Click',
                      event_value: 'X'
                    })
                  "
                >
                  <img src="./assets/images/icons/x.svg" alt="X"
                /></app-btn-link>
                <app-btn-link
                  classGroup="darkRounded"
                  url="https://www.youtube.com/channel/UC01lfP_Av3kJ8lpeHYBKN1w"
                  target=" _blank"
                  [isExternal]="true"
                  (click)="
                    trackEvent('Footer Section Clicked', 'click', {
                      event_action: 'Footer section clicked',
                      event_type: 'Footer Click',
                      event_value: 'YouTube'
                    })
                  "
                >
                  <img src="./assets/images/icons/youtube.svg" alt="YouTube"
                /></app-btn-link>
              </div>
              <div
                class="mt-[4vh] md:mt-[6vh] md:leading-6 flex text-[14px] [&>a]:hover:text-primary items-end ml-0 md:ml-6"
              >
                <a
                  href="/privacy-policy"
                  class="hover-underline-animation"
                  (click)="
                    trackEvent('Footer Section Clicked', 'click', {
                      event_action: 'Footer section clicked',
                      event_type: 'Footer Click',
                      event_value: 'Privacy Policy'
                    })
                  "
                  >Privacy Policy</a
                >
                <a
                  href="/terms-of-service"
                  class="hover-underline-animation ml-6 lgPlus:ml-12"
                  (click)="
                    trackEvent('Footer Section Clicked', 'click', {
                      event_action: 'Footer section clicked',
                      event_type: 'Footer Click',
                      event_value: 'Terms of Service'
                    })
                  "
                  >Terms of Service</a
                >
                <a
                  routerLink="/cookies-policy"
                  class="hover-underline-animation ml-6 lgPlus:ml-12"
                  (click)="
                    trackEvent('Footer Section Clicked', 'click', {
                      event_action: 'Footer section clicked',
                      event_type: 'Footer Click',
                      event_value: 'Cookie Policy'
                    })
                  "
                  >Cookie Policy</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<app-newsletter-modal #newslettertModalComponent></app-newsletter-modal>
