import {
  ChangeDetectionStrategy,
  Component,
  input,
  computed,
  Output,
  EventEmitter,
} from '@angular/core';
import { ChipComponent } from '../chip/chip.component';
import { NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-input-field',
  standalone: true,
  imports: [ChipComponent, NgClass, ReactiveFormsModule, MatTooltip],
  templateUrl: './input-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldComponent {
  type = input<HTMLInputElement['type']>('text');
  label = input<string>('');
  placeholder = input<string>('');
  tooltipMessage = input<string>('');
  required = input<boolean>(false);
  errors = input<ValidationErrors>();
  hideErrors = input(false, {
    transform: (value: boolean | string) => (typeof value === 'string' ? value === '' : value),
  });

  step = input<number>();
  min = input<number>();
  max = input<number>();
  touched = input<boolean>(false);

  icon = input<'search' | 'event' | 'visibility' | 'visibility_off' | 'close'>();
  control = input.required<FormControl>();
  @Output() iconClick = new EventEmitter<void>();
  @Output() inputChange = new EventEmitter<void>();
  @Output() inputKeyUp = new EventEmitter<void>();

  errorsMessages = computed(() => {
    return {
      required: 'This field is required',
      email: 'Please enter a valid email address',
      pattern: 'Please enter a valid email address',
      minlength: 'Password must be at least 8 characters long',
      passwordConfirmation: "Password and Password Confirmation don't match",
      min: `min value is ${this.min()}`,
      max: `max value is ${this.max()}`,
    };
  });

  errorMessage = computed(() => {
    if (this.errors()) {
      return this.errorsMessages()[Object.keys(this.errors() as ValidationErrors)[0]];
    }
    return '';
  });

  dynamicStyles = computed(() => {
    const padding = this.icon() ? 'pr-10' : 'pr-5';
    const border =
      this.control().touched && this.errors()
        ? 'border-error text-error'
        : `text-primary ${this.icon() === 'search' ? 'border-primary-20' : 'border-primary'}`;
    return `${padding} ${border}`;
  });

  iconInteraction() {
    this.iconClick.emit();
  }

  changeEmit() {
    this.inputChange.emit();
  }

  keyUpEmit() {
    this.inputKeyUp.emit();
  }
}
