import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'sostereo-services';
import { CommonService } from '../angular/shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class StereospacesAuthGuard {
  constructor(
    private router: Router,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    @Inject('environment') private environment,
  ) {}

  canActivate() {
    if (this.environment.name !== 'sostereo') {
      const subdomain = this.commonService.getSubdomain();
      if (subdomain) {
        const subString = window.location.href.split('returnUrl=');
        this.router.navigateByUrl(
          subString.length > 1 ? decodeURIComponent(subString[1]) : '/discover',
        );
        return false;
      }
      return true;
    }
    return true;
  }
}
