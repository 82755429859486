<ng-template #downloadPlaylistConfirmationModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div
      class="sos-generic-modal-container sos-scrollbar-none"
      [ngClass]="{ 'pitch-style': isPitch }"
    >
      <h4 class="mt-2">Download Playlist</h4>
      <div class="text-left mt-5">
        <div class="w-full">
          <p>Do you really want to download this playlist?</p>
          <h5 class="secondary-color fw400">{{ playlistData.playlistName }}</h5>
          <p class="mt-2">
            We will include all playlist songs in a single zip file. Check your browser downloads to
            see the process status.
          </p>
        </div>
      </div>
      <div class="mt-5">
        <div class="w-full flex justify-end items-center">
          <h5
            type="button"
            class="secondary-link pr-5 mb-0 display-i"
            (click)="cancel()"
            style="cursor: pointer"
          >
            Cancel
          </h5>
          <app-btn (click)="downloadPlaylist()"> Yes, I'm sure </app-btn>
        </div>
      </div>
    </div>
  </div>
</ng-template>
