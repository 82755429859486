import { ChangeDetectionStrategy, Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NewsletterFormComponent } from 'src/app/angular/layout/components/newsletter-form/newsletter-form.component';
import { BtnComponent } from 'src/app/components/btn/btn.component';

@Component({
  selector: 'app-newsletter-modal',
  standalone: true,
  imports: [BtnComponent, NewsletterFormComponent],
  templateUrl: './newsletter-modal.component.html',
  styleUrl: './newsletter-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterModalComponent {
  modalService = inject(BsModalService);
  @ViewChild('newslettertModal', { static: true }) modalTemplate: TemplateRef<any>;
  public modalRef: BsModalRef;
  public email: string;

  public hideModal() {
    this.modalRef.hide();
  }

  public showModal(email) {
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-sm',
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.email = email;
  }
}
