import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideExtension',
  standalone: true,
})
export class HideExtensionPipe implements PipeTransform {
  transform(value: string): string {
    const ext = ['.aiff', '.aif', '.aifc', '.wav', '.mp3'];
    return value && ext.indexOf(value) !== -1 ? value.toString().replace(/\.[^/.]+$/, '') : value;
  }
}
