<ng-template #songInternalNotesModal>
  <div class="w-full lg:px-[44px] pt-[4px] pb-[41px]">
    <div class="w-full flex justify-center lg:justify-end relative z-10">
      <app-btn
        (click)="hideModal()"
        class="relative bottom-[-20px] lg:bottom-auto lg:right-[-40px] hover:!text-primary"
        [classGroup]="'squaredHover'"
      >
        <i class="material-icons hover:!text-primary"> close </i>
      </app-btn>
    </div>
    <div class="modal-content">
      <div class="modal-body mb-8">
        <div>
          <div class="ml-5">
            <h2>Internal notes</h2>
            <h5>
              {{ song.name || song.fileName }}
              {{
                song?.artists?.length > 0
                  ? ' by ' + (song.artists[0].artistName || song.artists[0].name)
                  : ''
              }}
            </h5>
            <br />
          </div>
        </div>
        <div *ngIf="song.stemsAvailable" class="pt-4 ml-5 mr-5">
          <span
            class="material-icons color-primary pull-left mr-1"
            style="position: relative; top: 5px"
            [attr.data-icon]="'water'"
          ></span>
          <h6>Stems available:</h6>
          <p>{{ song.stemsAvailable }}</p>
        </div>
        <div *ngIf="song.customizable" class="pt-4 ml-5 mr-5">
          <span
            class="material-icons color-primary pull-left mr-1"
            style="position: relative; top: 5px"
            [attr.data-icon]="'tune'"
          ></span>
          <h6>Customizable:</h6>
          <p>{{ song.customizable }}</p>
        </div>
        <div *ngIf="song.internalManager" class="pt-4 ml-5 mr-5">
          <span
            class="material-icons color-primary pull-left mr-1"
            style="position: relative; top: 5px"
            [attr.data-icon]="'support_agent'"
          ></span>
          <h6>Internal manager:</h6>
          <p>
            {{
              song.internalManager?.email ||
                (isString(song.internalManager) ? song.internalManager : song.internalManager?.name)
            }}
          </p>
        </div>
        <div *ngIf="song.notes" class="pt-4 ml-5 mr-5">
          <span
            class="material-icons color-primary pull-left mr-1"
            style="position: relative; top: 5px"
            [attr.data-icon]="'description'"
          ></span>
          <h6>Notes:</h6>
          <p>{{ song.notes }}</p>
        </div>
        <div *ngIf="song.restriction" class="pt-4 ml-5 mr-5">
          <span
            class="material-icons color-primary pull-left mr-1"
            style="position: relative; top: 5px"
            [attr.data-icon]="'remove_circle_outline'"
          ></span>
          <h6>Restrictions:</h6>
          <p>{{ song.restriction }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
