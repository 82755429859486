import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';
import { SeoService } from './seo.service';
import { countries, usaStates } from '../configs/location.config';
import {
  AuthenticationService,
  PlaylistsStereoSpacesService,
  CompanyService,
  TiersService,
} from 'sostereo-services';
import { intersection, reduce } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
declare const window;

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public playlistsCount = new BehaviorSubject<number>(0);
  public completeProfilePerc = new Subject<number>();
  public newCompany = new Subject<any>();
  public company: any = null;
  public firstPlaylist = new BehaviorSubject<string>('');
  public tagReport = new BehaviorSubject<boolean>(false);
  public searchScore = new BehaviorSubject<boolean>(false);
  public infoModal = new BehaviorSubject<boolean>(false);
  public waveColor = new BehaviorSubject<string>(null);
  public playerBg = new BehaviorSubject<string>(null);
  public discoverBg = new BehaviorSubject<string>(null);
  public pageTitle = new BehaviorSubject<string>(null);
  public pageDescription = new BehaviorSubject<string>(null);
  public tenantId = 'sostereo';
  public emailRegex = /^([\w-.+]+@([\w-]+\.)+[\w-]{2,15})?$/;
  public workCategories = [
    { name: 'View All', slug: 'view-all', selected: true },
    { name: 'Beauty', slug: 'beauty', selected: false },
    { name: 'Food', slug: 'food', selected: false },
    { name: 'Sports', slug: 'sports', selected: false },
    { name: 'Cars', slug: 'cars', selected: false },
    { name: 'Fashion', slug: 'fashion', selected: false },
    { name: 'Technology', slug: 'technology', selected: false },
    { name: 'Entertainment', slug: 'entertainment', selected: false },
    { name: 'Culture', slug: 'culture', selected: false },
    { name: 'Alcohol', slug: 'alcohol', selected: false },
  ];

  private artistDataWeight = {
    description: 15,
    location: 20,
    instagramUrl: 5,
    youtubeUrl: 5,
    spotifyUrl: 10,
    twitterUrl: 5,
    facebookPage: 5,
    backgroundImages: 35,
  };

  isBrowser: boolean;

  constructor(
    private _seoService: SeoService,
    private _authenticationService: AuthenticationService,
    private playlistService: PlaylistsStereoSpacesService,
    private companyService: CompanyService,
    private tiersService: TiersService,
    @Inject(PLATFORM_ID) private platformId,
  ) {}

  getSlugFromProperty(property: string): string {
    return property
      .replace(/[^a-zA-Z0-9]/g, '-')
      .replace(/-+/g, '-')
      .toLowerCase()
      .replace(/^-|-$/g, '');
  }

  updateSeo(data) {
    this._seoService.updateMeta(data, this.tenantId);
  }

  updateImageSeo(data) {
    this._seoService.updateImageSeo(data);
  }

  updateBasicSeo(data) {
    this._seoService.updateBasicSeo(data);
  }

  updateNg7UserToken() {
    this._authenticationService.setCurrentUser(this._authenticationService.getCurrentUser());
  }

  getCountries() {
    return countries;
  }

  getUsaStates() {
    return usaStates;
  }

  isAllowed(allowedScopes, disallowedScopes?) {
    const loggedUser = this._authenticationService.getCurrentUser();
    if (loggedUser) {
      const intersectionDisallow = intersection(loggedUser.scopes, disallowedScopes);
      if (intersectionDisallow.length > 0) {
        return false;
      } else {
        const intersectionAllow = intersection(loggedUser.scopes, allowedScopes);
        return intersectionAllow.length > 0 || allowedScopes.length === 0;
      }
    } else {
      return allowedScopes.indexOf('public') !== -1;
    }
  }

  getUserGroup() {
    const loggedUser = this._authenticationService.getCurrentUser();
    if (loggedUser) {
      return loggedUser.groups[0].replace('resources-', '');
    } else {
      return '';
    }
  }

  getPlaylistsCount() {
    const queryParams: any = { collaborative: false, sort: 'createdAt asc' };
    if (this._authenticationService.getCurrentUser()?.hasOwnProperty('uid')) {
      queryParams.ownerUid = this._authenticationService.getCurrentUser().uid;
    }
    this.playlistService.query(queryParams).subscribe(
      (res) => {
        this.playlistsCount.next(res.data.paging.totalItems);
        if (res.data.paging.totalItems > 0) {
          this.firstPlaylist.next(res.data.items[0]._id);
        }
      },
      (err) => {
        console.error(err);
      },
    );
  }

  getTenantId(): Promise<string> {
    const subdomainData = this.getSubdomain();
    return new Promise((resolve, reject) => {
      if (subdomainData) {
        this.getCompanyData(subdomainData)
          .then(
            (company) => {
              if (!company?.tenantId) reject();
              resolve(company.tenantId.replace('Company-', ''));
            },
            () => reject(),
          )
          .catch(() => reject());
      } else {
        resolve('sostereo');
      }
    });
  }

  getCompanyData(company): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.company) {
        resolve(this.company);
      } else if (company) {
        this.companyService.getBySlug(company).subscribe(
          (cRes) => {
            //console.log('CommonService: Get Company', cRes);
            if (cRes.data) {
              this.tenantId =
                cRes.data.tenantId && !cRes.data.tenantId.includes('sostereo')
                  ? cRes.data.tenantId.replace('Company-', '')
                  : 'sostereo';
              this.company = cRes.data;
              this.newCompany.next(company);
              resolve(cRes.data);
            } else {
              reject();
            }
          },
          (cError) => {
            reject(cError);
            this.setTrackingEvent('Error getting company by slug', {
              error: cError,
              companySlug: company,
            });
          },
        );
      } else {
        reject();
      }
    });
  }

  getSubdomain(hostname?: string): string {
    // Gets full site url
    const domain = hostname ? hostname : window.location.hostname;
    // Validation for Localhost
    if (isPlatformBrowser(this.platformId)) {
      if (domain === '0.0.0.0' || domain === 'localhost') {
        if (window.location.origin.includes('4300')) {
          // return 'gmc';
          return 'jackmorton';
          //return 'ubisoft';
        } else if (window.location.origin.includes('4000')) {
          return 'pushaudio';
        }
        return null;
      }
      // Validates first split position of the full url, discarting 'www','sostereo' & 'qa'
      if (
        domain &&
        domain.split('.')[0] !== 'www' &&
        domain.split('.')[0] !== 'sostereo' &&
        domain.split('.')[0] !== 'qa' &&
        domain.split('.')[0] !== 'stereospaces' &&
        domain.split('.')[0] !== 'dev'
      ) {
        // for example if the url is 'www.sostereo.com' or 'sostereo.com' or 'qa.sostereo.com', will not return yet
        // but if the url is 'brand.sostereo.com', will return 'brand'
        // return null; // Uncomment this one to use ngrok
        return domain.split('.')[0];
      }
    }
    // if none of the above, will return null, which means there is no subdomain
    // for example 'sostereo.com','www.sostereo.com' & 'qa.sostereo.com'
    return null;
  }

  getCompany(company, cb) {
    if (this.company) {
      cb(this.company);
    } else if (company) {
      this.companyService.getBySlug(company).subscribe(
        (cRes) => {
          //console.log('CommonService: Get Company', cRes);
          if (cRes.data) {
            this.tenantId =
              cRes.data.tenantId && !cRes.data.tenantId.includes('sostereo')
                ? cRes.data.tenantId.replace('Company-', '')
                : 'sostereo';
            this.company = cRes.data;
            this.newCompany.next(company);
            cb(cRes.data);
          } else {
            cb();
          }
        },
        (cError) => {
          cb(null, cError);
          this.setTrackingEvent('Error getting company by slug', {
            error: cError,
            companySlug: company,
          });
        },
      );
    } else {
      cb(null);
    }
  }

  checkCompanyDomain(subdomain, email, tenantId, cb) {
    let domain =
      (email.includes(`@${tenantId}.com`)
        ? email.match(/(?:\+)(.*)(?:@)|(?:\@)(.*)/)[0]
        : email.match(/(?:\@)(.*)/)[0]) || '';
    domain = domain.replace(/[\+,@]/g, '').toLowerCase();
    if (subdomain) {
      this.companyService.getBySlug(subdomain).subscribe(
        (cRes) => {
          //console.log(cRes);
          if (cRes.data.domains.includes(domain)) {
            cb(true);
          } else {
            cb(false);
          }
        },
        (cError) => {
          cb(false);
          this.setTrackingEvent('Error getting company by slug', {
            error: cError,
            companySlug: subdomain,
          });
        },
      );
    } else {
      cb(false);
    }
  }

  setCompleteProfilePerc(artist) {
    let percentage = 0;
    Object.keys(this.artistDataWeight).forEach((k) => {
      if (k === 'location' && artist.location) {
        if (
          artist.location.country !== '' &&
          artist.location.country !== null &&
          artist.location.city !== '' &&
          artist.location.city !== null
        ) {
          percentage += this.artistDataWeight[k];
        }
      } else if (k === 'backgroundImages') {
        if (artist.backgroundImages && artist.backgroundImages.length > 0) {
          percentage += this.artistDataWeight[k];
        }
      } else {
        if (artist[k] && artist[k] !== '' && artist[k] !== null) {
          percentage += this.artistDataWeight[k];
        }
      }
    });
    this.completeProfilePerc.next(percentage);
    return percentage;
  }

  public addLetterToLargeNumber(value: number, split: boolean = false) {
    const stringValue = value.toString();
    let newElm;
    let scaleLetter = '';
    if (stringValue.length >= 4 && stringValue.length <= 6) {
      newElm = stringValue.slice(0, stringValue.length - 2);
      scaleLetter = 'K';
    } else if (stringValue.length >= 7 && stringValue.length <= 9) {
      newElm = stringValue.slice(0, stringValue.length - 5);
      scaleLetter = 'M';
    } else {
      return stringValue;
    }
    if (newElm[newElm.length - 1] === '0') {
      newElm = newElm.slice(0, newElm.length - 1);
    } else {
      newElm = newElm.split('');
      newElm.splice(newElm.length - 1, 0, '.');
      newElm = newElm.join('');
    }
    return split ? `${newElm} ${scaleLetter}` : `${newElm}${scaleLetter}`;
  }

  public mapRestrictionsToArray(restrictions) {
    const restrictionsMap = reduce(
      restrictions,
      (acc, item) => {
        let restriction: any = { slug: item.slug, name: item.name, id: item.id };
        if (item.hasOwnProperty('visible')) {
          restriction.visible = item.visible;
        }
        if (item.details) {
          restriction.details = item.details;
        }
        if (item.expirationDate) {
          restriction.expirationDate = item.expirationDate.substring(0, 10);
        }
        acc.push(restriction);
        return acc;
      },
      [],
    );
    return restrictionsMap;
  }

  public mapRestrictionsToObject(restrictions) {
    return restrictions.reduce((acc, item) => {
      acc[item.slug] = {
        slug: item.slug,
        name: item.name,
        id: item._id || item.id,
        visible: item.visible,
      };
      if (item.details) {
        acc[item.slug].details = item.details;
      }
      if (item.expirationDate) {
        acc[item.slug].expirationDate = item.expirationDate;
      }
      return acc;
    }, {});
  }

  public regExpEscape(string) {
    if (string.includes('/')) {
      string = string.replace(/[\-\[\]\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&').trim();
      string = string.replace('/', '(.*)');
      return `(${string})`;
    } else {
      return string.replace(/[\-\[\]\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&').trim();
    }
  }

  public getQueryParamsFromUrl() {
    const url = window.location.href;
    const stringParams = url.split('?')[1] || '';
    if (!stringParams) return {};
    const paramsArray = stringParams.split('&');
    return reduce(
      paramsArray,
      (result, value) => {
        const split = value.split('=');
        if (split[0]) {
          result[split[0]] = decodeURI(split[1]);
        }
        return result;
      },
      {},
    );
  }

  /**
   * @param event
   * validate if keycode contain numeric (0-9) , upper alpha (A-Z), lower alpha (a-z) and ("') value
   * @returns
   */
  public isRegularKeyCode(event: KeyboardEvent): boolean {
    const oldValidation =
      (event?.keyCode > 47 && event?.keyCode < 58) ||
      (event?.keyCode > 64 && event?.keyCode < 91) ||
      (event?.keyCode > 96 && event?.keyCode < 123);

    const regex = /[a-zA-Z\d"']+/g;
    const newValidation = event.key?.length === 1 && event?.key?.match(regex)?.length > 0;
    return oldValidation || newValidation;
  }

  setTrackingEvent(eventName, eventParams) {
    if (window?.mixpanel) {
      window.mixpanel.track(eventName, eventParams);
    }
  }

  getTierList(): Observable<any[]> {
    return new Observable((observer) => {
      const tiersTags = localStorage.getItem('SOSTEREO.Tiers');
      if (tiersTags && tiersTags.includes('hasWarning')) {
        observer.next(JSON.parse(tiersTags));
        observer.complete();
      } else {
        this.tiersService.query({ limit: 25 }).subscribe(
          (rRes) => {
            let tiers = rRes.data.items;
            const tiersTagsList = tiers.map((tier) => ({
              _id: tier._id,
              slug: tier._id,
              label: tier.name,
              category: 'tiers',
              publicAccess: tier.publicAccess,
              hasWarning: tier.hasWarning,
            }));
            localStorage.setItem('SOSTEREO.Tiers', JSON.stringify(tiersTagsList));
            observer.next(tiersTagsList);
            observer.complete();
          },
          (err) => {
            console.error(err);
          },
        );
      }
    });
  }

  public getSize(size: number): string {
    return size > 1000000000
      ? `${(size / 1000000000).toFixed(2)} GB`
      : size > 1000000
      ? `${(size / 1000000).toFixed(2)} MB`
      : size > 1000
      ? `${(size / 1000).toFixed(2)} KB`
      : `${size || 0} Bytes`;
  }

  public bytesToSize(bytes: number) {
    if (bytes === 0) return 'n/a';
    return this.getSize(bytes);
  }

  public getSongAndArtistNames(songName: string, artists: any[]): string {
    const name = songName;
    if (artists?.length > 0) {
      const artistsJoin = artists.map((artist) => artist.name || artist.artistName).join(', ');
      return `${name} by ${artistsJoin}`;
    }
    return `${name}`;
  }

  updatedropDownText(selectedColumns: any) {
    setTimeout(() => {
      let dropdownTitle = document.getElementsByClassName('countplaceholder')[0];
      if (dropdownTitle) {
        dropdownTitle.innerHTML = `Customize columns (${selectedColumns.length})`;
      }
    });
  }

  //'enum': ['only-me', 'team', 'public'],
  canEditPlaylist(playlist: any) {
    if (!this._authenticationService.isAuthenticated()) {
      return false;
    }
    const user = this._authenticationService.getCurrentUser();
    if (playlist?.ownerUid === user.uid) {
      return true;
    }
    if (playlist?.allowTrackAdditions === 'only-me') {
      return false;
    }

    const isTeamPlaylist = (playlist?.groups || []).some((group) => user.groups?.includes(group));
    return isTeamPlaylist;
    //|| playlist.allowTrackAdditions === 'public';
  }

  public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    renderer.appendChild(window.document.body, script);
    return script;
  }

  public handleAllowNumbers(e) {
    if (e.target.type === 'number' && !e.key.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
  }

  mobileAndTabletcheck() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  isRetina() {
    return (
      ((window.matchMedia &&
        (window.matchMedia(
          'only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)',
        ).matches ||
          window.matchMedia(
            'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)',
          ).matches)) ||
        (window.devicePixelRatio && window.devicePixelRatio >= 2)) &&
      /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    );
  }

  isHighDensity() {
    return (
      (window.matchMedia &&
        (window.matchMedia(
          'only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)',
        ).matches ||
          window.matchMedia(
            'only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)',
          ).matches)) ||
      (window.devicePixelRatio && window.devicePixelRatio > 1.3)
    );
  }

  isIphone(): boolean {
    return /iPhone/i.test(navigator.userAgent || navigator.vendor || (window as any).opera);
  }

  formatDateInput(date: string): string {
    try {
      let newDate = new Date(date);
      return newDate.toISOString().split('T')[0];
    } catch (error) {
      return '';
    }
  }

  loadCalendarScript(targetId: string, label: string, calendarUrl: string) {
    const existingScript = document.querySelector(
      'script[src="https://calendar.google.com/calendar/scheduling-button-script.js"]',
    );

    if (!existingScript || true) {
      const script = document.createElement('script');
      script.src = 'https://calendar.google.com/calendar/scheduling-button-script.js';
      script.async = true;
      document.body.appendChild(script);

      const link = document.createElement('link');
      link.href = 'https://calendar.google.com/calendar/scheduling-button-script.css';
      link.rel = 'stylesheet';
      document.head.appendChild(link);

      script.onload = () => {
        this.initializeCalendarButton(targetId, label, calendarUrl);
      };
    }
  }

  initializeCalendarButton(targetId: string, label: string, url: string) {
    (window as any).calendar.schedulingButton.load({
      url: url,
      color: '#263e54',
      label: label,
      target: document.getElementById(targetId),
    });
  }
}
