export const inquiriesDefaultValues = {
  exclusivity: {
    message:
      'We are pitching the below listed songs to a "<<EXCL. CATEGORY>>" company who wants to use it EXCLUSIVELY for the below terms in their specific category (i.e exclusively for "<<EXCL. CATEGORY>>" category). As per our contract with you, we need your final approval to move forward on this and clear the exclusivity for the below:',
    subject: 'Exclusivity Approval Needed for Pitch',
  },
  budget: {
    message:
      "We have received a brief with the below term. It's below what we would usually pitch your music for, but we wanted to offer up the opportunity in case you want us to send your music anyway.",
    subject: 'Lower Budget Approval Needed for Pitch',
  },
  lyrics: {
    message:
      "We recently pitched the below song(s) for a campaign and it is now a finalist. The client is asking for LYRICS and our records indicate we don't have them on hand.",
    subject: 'Lyrics Needed for Pitch',
  },
  custom: {
    message:
      'A client has asked us to submit music for a brief but we are only allowed to pitch songs that are fully customizable. Therefore, we wanted to make sure you/artist/producer still have access to the project & if you/artist/producer would be able to customize & change the song if/as needed. Please note, this is only a pitch at this time.',
    subject: 'Customization Approval Needed for Pitch',
  },
  stems: {
    message:
      "We recently pitched the below song(s) for a campaign, and it is now a finalist. The client is asking for STEMS and our records indicate we don't have them on hand.",
    subject: 'Stems Needed for Pitch',
  },
  placement: {
    message: `Congratulations from SoStereo, you have a new placement. Now you can use the Artist Communication feature to see all the Terms & how to submit your Invoice.`,
    subject: 'Placement Confirmation for "BRAND" - Invoice Required Ref #refNumber',
  },
  version: {
    message:
      "We recently pitched the below song(s) for a campaign, and it is now a finalist. The client is asking for the <<SONG VERSION>> Version and our records indicate we don't have them on hand.",
    subject: '<<SONG VERSION>> Version Needed for Pitch',
  },
};

export const inquiriesOptions: any = [
  {
    slug: 'exclusivity',
    name: 'Exclusivity',
    visible: true,
    fields: [
      {
        slug: 'projectDetails',
        name: 'Project Details',
        type: 'array',
        options: [
          { slug: 'media', name: 'Media', type: 'input', required: true, value: '', show: true },
          {
            slug: 'territory',
            name: 'Territory',
            type: 'input',
            required: true,
            value: '',
            show: true,
          },
          {
            slug: 'exclusivity',
            name: 'Exclusivity Category',
            type: 'input',
            required: true,
            value: '',
            show: true,
          },
          {
            slug: 'renewals',
            name: 'Renewals',
            required: true,
            type: 'input',
            value: '',
            show: true,
          },
        ],
      },
      {
        slug: 'subject',
        name: 'Subject',
        type: 'input',
        required: true,
        value: inquiriesDefaultValues.exclusivity.subject,
      },
      {
        slug: 'message',
        name: 'Message',
        type: 'textArea',
        required: true,
        value: inquiriesDefaultValues.exclusivity.message,
      },
      { slug: 'deadline', name: 'Deadline', type: 'date', value: '' },
      { slug: 'budget', name: 'All-In Fee (per track)', type: 'input', value: '', required: true },
      { slug: 'terms', name: 'Term', type: 'input', value: '', required: true },
    ],
    items: [],
  },
  {
    slug: 'stems',
    name: 'Stems',
    visible: true,
    fields: [
      {
        slug: 'subject',
        name: 'Subject',
        required: true,
        type: 'input',
        value: inquiriesDefaultValues.stems.subject,
      },
      {
        slug: 'message',
        name: 'Message',
        type: 'textArea',
        required: true,
        value: inquiriesDefaultValues.stems.message,
      },
      { slug: 'deadline', name: 'Deadline', type: 'date', value: '' },
    ],
    items: [],
  },
  {
    slug: 'budget',
    name: 'Budget',
    visible: true,
    fields: [
      {
        slug: 'subject',
        name: 'Subject',
        required: true,
        type: 'input',
        value: inquiriesDefaultValues.budget.subject,
      },
      {
        slug: 'message',
        name: 'Message',
        type: 'textArea',
        required: true,
        value: inquiriesDefaultValues.budget.message,
      },
      { slug: 'deadline', name: 'Deadline', type: 'date', value: '' },
      {
        slug: 'projectDetails',
        name: 'Project Details',
        type: 'array',
        options: [
          { slug: 'media', name: 'Media', type: 'input', value: '', required: true, show: true },
          {
            slug: 'territory',
            name: 'Territory',
            type: 'input',
            value: '',
            required: true,
            show: true,
          },
          {
            slug: 'renewals',
            name: 'Renewals',
            type: 'input',
            value: '',
            required: true,
            show: true,
          },
          {
            slug: 'exclusivity',
            name: 'Exclusivity Category',
            type: 'input',
            value: '',
            show: true,
          },
        ],
      },
      { slug: 'budget', name: 'All-In Fee (per track)', type: 'input', value: '', required: true },
      { slug: 'terms', name: 'Term', type: 'input', value: '' },
    ],
    items: [],
  },
  {
    slug: 'custom',
    name: 'Custom',
    visible: true,
    fields: [
      {
        slug: 'subject',
        name: 'Subject',
        required: true,
        type: 'input',
        value: inquiriesDefaultValues.custom.subject,
      },
      {
        slug: 'message',
        name: 'Message',
        type: 'textArea',
        required: true,
        value: inquiriesDefaultValues.custom.message,
      },
      { slug: 'deadline', name: 'Deadline', type: 'date', value: '' },
    ],
    items: [],
  },
  {
    slug: 'lyrics',
    name: 'Lyrics',
    visible: true,
    fields: [
      {
        slug: 'subject',
        name: 'Subject',
        required: true,
        type: 'input',
        value: inquiriesDefaultValues.lyrics.subject,
      },
      {
        slug: 'message',
        name: 'Message',
        type: 'textArea',
        required: true,
        value: inquiriesDefaultValues.lyrics.message,
      },
      { slug: 'deadline', name: 'Deadline', type: 'date', value: '' },
    ],
    items: [],
  },
  {
    slug: 'placement',
    name: 'Placement',
    visible: true,
    fields: [
      {
        slug: 'message',
        name: 'Message',
        type: 'textArea',
        required: true,
        value: inquiriesDefaultValues.placement.message,
      },
      {
        slug: 'terms',
        name: 'Placement Category',
        required: true,
        type: 'select',
        dropdownOptions: [
          {
            name: 'Ads',
            slug: 'ads',
          },
          {
            name: 'TV & Film',
            slug: 'filmAndTv',
          },
          {
            name: 'Gaming',
            slug: 'gaming',
          },
        ],
      },
      {
        slug: 'projectDetails',
        name: 'Project Details',
        type: 'array',
        options: [
          {
            slug: 'refNumber',
            name: 'Ref Number',
            type: 'input',
            required: true,
            value: '',
            show: true,
          },
          { slug: 'brand', name: 'Brand', type: 'input', required: false, value: '', show: false },
          {
            slug: 'company',
            name: 'Company',
            type: 'input',
            required: false,
            value: '',
            show: false,
          },
          {
            slug: 'project',
            name: 'Project',
            type: 'input',
            required: false,
            value: '',
            show: false,
          },
          {
            slug: 'networkOrStudio',
            name: 'Network / Studio',
            type: 'input',
            required: false,
            value: '',
            show: false,
          },
          {
            slug: 'tvShowOrFilm',
            name: 'TV SHow / Film',
            type: 'input',
            required: false,
            value: '',
            show: false,
          },
          {
            slug: 'episode',
            name: 'Episode',
            type: 'input',
            required: false,
            value: '',
            show: false,
          },
          {
            slug: 'showSypnosis',
            name: 'Show/Film Sypnosis',
            type: 'input',
            required: false,
            value: '',
            show: false,
          },
          {
            slug: 'gameSypnosis',
            name: 'Game Genre/Sypnosis',
            type: 'input',
            required: false,
            value: '',
            show: false,
          },
          { slug: 'media', name: 'Media', type: 'input', required: true, value: '', show: true },
          { slug: 'use', name: 'Use', type: 'input', required: true, value: '', show: true },
          {
            slug: 'startDate',
            name: 'Start Date',
            type: 'input',
            required: false,
            value: '',
            show: false,
            isDate: true,
          },
          {
            slug: 'airDate',
            name: 'Air Date',
            type: 'input',
            required: false,
            value: '',
            show: false,
            isDate: true,
          },
          {
            slug: 'term',
            name: 'Term',
            required: true,
            type: 'input',
            value: '',
            show: true,
          },
          {
            slug: 'territory',
            name: 'Territory',
            type: 'input',
            required: true,
            value: '',
            show: true,
          },
          {
            slug: 'exclusivity',
            name: 'Exclusivity Category',
            type: 'input',
            required: false,
            value: '',
            show: false,
            isExclusivity: true,
          },
          {
            slug: 'total',
            name: 'Total Placement Fee',
            type: 'input',
            required: true,
            value: '',
            isCurrency: true,
            isTotal: true,
            show: true,
          },
          {
            slug: 'otherCost',
            name: 'Recoupable Costs',
            type: 'input',
            required: true,
            value: '',
            isCurrency: true,
            show: true,
          },
          {
            slug: 'otherCostDescription',
            name: 'Recoupable Costs Details',
            type: 'input',
            required: false,
            value: '',
            show: true,
          },
          {
            slug: 'nonRecoupableCost',
            name: 'Non Recoupable Costs',
            type: 'input',
            required: false,
            value: '',
            isCurrency: true,
            show: true,
            tooltip: 'This invoiced value is 100% for the artists i.e. Customizations, etc',
          },
          {
            slug: 'commissionPercentage',
            name: '3rd Party Sales Partner (%)',
            type: 'input',
            required: false,
            value: '',
            show: true,
            isNumber: true,
          },
          {
            slug: 'linkToTheSpot',
            name: 'Link to the Spot',
            type: 'input',
            required: false,
            value: '',
            show: true,
          },
          {
            slug: 'comments',
            name: 'Additional Comments',
            type: 'input',
            required: false,
            value: '',
            show: true,
          },
        ],
      },
      {
        slug: 'subject',
        name: 'Subject',
        required: true,
        type: 'input',
        value: inquiriesDefaultValues.placement.subject,
      },
    ],
    items: [],
  },
  {
    slug: 'version',
    name: 'Version',
    visible: true,
    fields: [
      {
        slug: 'version',
        name: 'Version',
        required: true,
        type: 'select',
        dropdownOptions: [
          { name: 'Acapella', slug: 'acapella' },
          { name: 'Original', slug: 'original' },
          { name: 'Clean', slug: 'clean' },
          { name: 'Instrumental', slug: 'instrumental' },
          { name: 'TV Mix', slug: 'mix' },
        ],
      },
      {
        slug: 'subject',
        name: 'Subject',
        required: true,
        type: 'input',
        value: inquiriesDefaultValues.version.subject,
      },
      {
        slug: 'message',
        name: 'Message',
        type: 'textArea',
        required: true,
        value: inquiriesDefaultValues.version.message,
      },
      { slug: 'deadline', name: 'Deadline', type: 'date', value: '' },
    ],
    items: [],
  },
];

export const currencyArray = [
  {
    slug: 'USD',
    symbol: '$',
  },
  {
    slug: 'EUR',
    symbol: '€',
  },
  {
    slug: 'AUD',
    symbol: '$',
  },
  {
    slug: 'GBP',
    symbol: '£',
  },
  {
    slug: 'CAD',
    symbol: '£',
  },
];
