import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { WaveformService } from 'sostereo-services';
import { PlayerWidgetService } from 'src/app/angular/layout/services/player-widget.service';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js';
import { WavesurferTimeFormatPipe } from '../../../../../shared/pipes/wavesurfer-time-format.pipe';
import { NgIf, NgStyle, NgClass, NgFor } from '@angular/common';
import { Router } from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-wave-surfer-generic',
  templateUrl: './wave-surfer-generic.component.html',
  styleUrl: './wave-surfer-generic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgStyle, NgClass, NgFor, WavesurferTimeFormatPipe],
})
export class WaveSurferGenericComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() audioUrl: string;
  @Input() waveHeight: number = 60;
  @Input() textColor: string = 'black';
  @Input() showReferenceText: boolean = true;
  @Input() showSegment: boolean = true;
  @Input() autoPlay: boolean = true;
  @Input() initSegment: { start: number; duration: number };
  @Input() externalSong: boolean = true;
  @Input() selectFullTrack: boolean = false;
  @Input() segmentsGroups: any;
  @Input() showModal: boolean = false;
  @Input() containerId: string = '';
  @Input() waveTimeFormat: 'right' | 'bottom' = 'right';
  @Output() thisReady = new EventEmitter();
  @Output() changeSegment = new EventEmitter();
  @Output() selectedSegment = new EventEmitter();
  @Output() playEventEmit = new EventEmitter<string>();
  public isFirstTime = true;
  public wave: WaveSurfer = null;
  public control = {
    loading: true,
    play: true,
    duration: 0,
    currentTime: 0,
    autoPlay: true,
  };

  public segment: {
    start: number;
    duration: number;
  } = { start: 20, duration: 35 };

  wavePeaks: any;
  private destroyed$ = new Subject<void>();
  public segments: any[] = [];
  public modalId = null;
  public currentUrl = this.router.url;

  constructor(
    @Inject('environment') public environment,
    public commonService: CommonService,
    private changeDetectorRef: ChangeDetectorRef,
    public playerWidgetService: PlayerWidgetService,
    private waveformService: WaveformService,
    private trackingService: TrackingService,
    private router: Router,
  ) {
    this.playerWidgetService.isPlaying.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => {
        if (this.wave) {
          this.wave.pause();
        }
      },
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initWaves();
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.wave) {
      this.wave.unAll();
      this.wave.destroy();
    }
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.audioUrl && !changes.audioUrl.firstChange) {
      const urlSongCurrent = changes.audioUrl.currentValue.split('?')[0];
      const urlSongPrevious = changes.audioUrl.previousValue.split('?')[0];
      if (this.wave && urlSongCurrent !== urlSongPrevious) {
        this.wave.load(changes.audioUrl.currentValue);
      }
    }
    if (
      changes.showSegment &&
      !changes.showSegment.firstChange &&
      changes.showSegment.currentValue
    ) {
      this.isFirstTime = true;
      this.addResizable();
      this.calculateSegmentsGroups();
    }
  }

  ngOnInit(): void {
    this.control.play = this.autoPlay;
    this.control.autoPlay = this.autoPlay;
  }

  initWaves() {
    this.generateWaveform();
  }

  addResizable() {
    setTimeout(() => {
      if (this.selectFullTrack) {
        this.segment = { start: 0, duration: this.control.duration };
      } else {
        this.segment = this.initSegment || { start: 20, duration: 35 };
      }
      this.changeSegment.emit(this.segment);
      const waveformContainer = $('#waveform-generic');
      const initResizable =
        (this.segment.start * waveformContainer.width()) / this.control.duration;
      const widthResizable =
        (Math.floor(this.segment.duration) * waveformContainer.width()) / this.control.duration;
      const resizableElement = $('#resizable2');
      resizableElement.css('left', initResizable);
      resizableElement.width(widthResizable - initResizable);
      resizableElement.css('opacity', 1);
      this.changeDetectorRef.detectChanges();
      if (this.isFirstTime) {
        this.isFirstTime = false;
        resizableElement.resizable({
          resize: (event, ui) => {
            console.log(event, ui);
            this.updateSegmentSection();
          },
          containment: '#waveform-generic',
          maxHeight: 70,
          minHeight: 70,
          minWidth: 50,
          handles: { e: '.ui-resizable-e', w: '.ui-resizable-w' },
        });
      }
    }, 1000);
  }

  updateSegmentSection() {
    const waveformContainer = document.getElementById('waveform-generic');
    const resizable = document.getElementById('resizable2');
    this.segment = {
      start: (resizable.offsetLeft * this.control.duration) / waveformContainer.offsetWidth,
      duration:
        ((resizable.offsetWidth + resizable.offsetLeft) * this.control.duration) /
        waveformContainer.offsetWidth,
    };
    this.changeSegment.emit(this.segment);
    this.changeDetectorRef.detectChanges();
  }

  public togglePlay() {
    this.control.play = !this.control.play;
    if (!this.control.play) {
      this.wave.pause();
      return;
    }

    this.wave.play();
  }

  generateWaveform(): void {
    const containerId = `#${this.containerId} #waveform-generic`;
    let config: WaveSurferOptions = {
      container: containerId,
      backend: 'MediaElement',
      waveColor: this.commonService.waveColor.getValue() || this.environment.waveColor || '#868E8F',
      normalize: true,
      height: this.waveHeight,
      progressColor: this.currentUrl.includes('pitch/') ? '#000' : '#2c8088',
      hideScrollbar: true,
      barGap: 2.5,
      barWidth: 1.5,
    };
    if (!this.externalSong) {
      config = {
        ...config,
        fetchParams: {
          mode: 'no-cors',
        },
      };
    }

    const container = document.querySelector(containerId);
    if (container === null) {
      return;
    }

    this.wave = WaveSurfer.create(config);

    this.wave.on('timeupdate', (currentTime) => {
      this.control.currentTime = currentTime;
      this.changeDetectorRef.markForCheck();
    });

    this.wave.on('play', () => {
      this.playerWidgetService.pauseSong();
      this.control.play = true;
      this.playEventEmit.emit(this.containerId);
      this.changeDetectorRef.detectChanges();
    });

    this.wave.on('pause', () => {
      this.control.play = false;
      this.changeDetectorRef.detectChanges();
    });

    this.wave.on('finish', () => {
      this.control.play = false;
    });

    this.wave.on('loading', () => {
      this.control.loading = true;
    });

    this.wave.on('ready', () => {
      this.control.loading = false;
      this.control.duration = this.wave.getDuration();
      this.thisReady.emit();
      if (this.showSegment) {
        this.addResizable();
        this.segments = [];
        this.calculateSegmentsGroups();
      }

      if (this.control.autoPlay) {
        this.wave.play();
      }

      this.changeDetectorRef.detectChanges();
    });

    this.wave.on('load', (url) => {
      console.log('Load', url);
    });

    /** During audio loading */
    this.wave.on('loading', (percent) => {
      console.log('Loading', percent + '%');
    });

    if (!this.externalSong) {
      this.waveformService.get(this.audioUrl || '', false).subscribe({
        next: (res) => {
          if (res && res.body) {
            const wavePeaks = res.body.peaks;
            this.wavePeaks = wavePeaks;
            this.wave.load(this.audioUrl, this.wavePeaks).catch((error) => {
              console.error(error);
            });
          } else {
            this.wave.load(this.audioUrl, [[]]).catch((error) => {
              console.error(error);
            });
          }
        },
        error: (err) => {
          console.error('Error at getSongWaveform', err);
          this.trackingService.track('Error at getSongWaveform', {
            playlistId: this.audioUrl,
            Error: err,
          });
          this.wave.load(this.audioUrl, [[]]).catch((error) => {
            console.error(error);
          });
        },
      });
    } else {
      this.wave.load(this.audioUrl).catch((error) => {
        console.error(error);
      });
    }
  }

  calculateSegmentsGroups() {
    setTimeout(() => {
      if (this.segments.length === 0) {
        const codeA = 'A'.charCodeAt(0);
        const segments = Object.values(this.segmentsGroups || {});
        const waveformContainer = $('#waveform-generic');
        segments.forEach((segment: any, i) => {
          const segArray = Array.isArray(segment) ? segment : segment.segments;
          segArray.forEach((seg, j) => {
            const floorStart = Math.floor(seg.start);
            this.segments.push({
              id: `g${i}s${j}`,
              seed_groups: i,
              title: String.fromCharCode(codeA + i),
              start: floorStart,
              end: floorStart + seg.duration,
              left: (floorStart * waveformContainer.width()) / this.control.duration,
            });
          });
        });
        this.changeDetectorRef.detectChanges();
      }
    }, 1000);
  }

  public hideModal() {
    this.modalId = null;
    this.changeDetectorRef.detectChanges();
  }

  public toggleModal(item) {
    if (this.showModal) {
      if (this.modalId === item.id) {
        this.modalId = null;
      } else {
        this.modalId = item.id;
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  public playSegment(segment) {
    this.wave.setTime(segment.start);
    if (!this.wave.isPlaying()) {
      this.control.play = true;
      this.wave.play();
    }
  }

  public similarSegment(segment) {
    this.selectedSegment.emit(segment);
    this.hideModal();
  }

  public stopSong() {
    this.wave.stop();
  }
}
