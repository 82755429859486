import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TrackingService } from '../../../../shared/services/tracking.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'sostereo-services';

@Component({
  selector: 'app-session-expired-modal',
  templateUrl: './session-expired-modal.component.html',
  styleUrls: ['./session-expired-modal.component.scss'],
  standalone: true,
})
export class SessionExpiredModalComponent implements OnInit {
  @ViewChild('sessionExpiredModal', { static: true }) modalTemplate: TemplateRef<any>;
  public modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private trackingService: TrackingService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit() {}

  public showModal() {
    if (this.modalService.getModalsCount() < 1) {
      this.modalRef = this.modalService.show(this.modalTemplate, {
        class: 'modal-sm',
        ignoreBackdropClick: true,
      });
    }
  }

  public hideModal() {
    this.authenticationService.logout(true);
    this.modalRef.hide();
    if (window.location.href.indexOf('session-expired') !== -1) {
      this.router.navigateByUrl('/');
    }
  }

  public login() {
    this.hideModal();
    setTimeout(() => {
      this.router.navigateByUrl('/login');
      document.getElementsByTagName('modal-container')[0]?.setAttribute('class', 'modal fade in');
    }, 1000);
  }
}
