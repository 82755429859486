import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { CommonService } from '../../../../shared/services/common.service';
import { PlayerWidgetService } from '../../../services/player-widget.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgIf, NgClass, NgStyle, NgFor, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-playlists-editorial',
  templateUrl: './playlists-editorial.component.html',
  styleUrls: ['./playlists-editorial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, InfiniteScrollModule, NgClass, NgStyle, NgFor, TitleCasePipe],
})
export class PlaylistsEditorialComponent implements OnChanges, OnInit {
  @Input() playlists: any[] = [];
  @Input() loading: boolean;
  @Output() selectedCard = new EventEmitter();
  @Output() navigateCard = new EventEmitter();
  @Output() contentScroll = new EventEmitter();
  public subdomain;

  constructor(
    private cd: ChangeDetectorRef,
    public deviceDetectorService: DeviceDetectorService,
    private commonService: CommonService,
    private trackingService: TrackingService,
    public playerWidgetService: PlayerWidgetService,
  ) {
    this.subdomain = this.commonService.getSubdomain();
  }

  ngOnInit() {
    this.setMoodTags();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.playlists) {
      this.setMoodTags();
      this.cd.detectChanges();
    }
  }

  selectCard(playlist: any) {
    this.trackingService.track(
      'Editorial Playlist selected',
      {
        playlist: playlist,
      },
      {
        event_action: 'Editorial Playlist selected',
        event_type: 'Playlist Click',
        event_value: playlist?._id || playlist?.slug,
      },
    );
    this.selectedCard.emit({ rowData: playlist });
  }

  navigateToCard(playlist: any) {
    this.trackingService.track(
      'Editorial Playlist played',
      {
        playlist: playlist,
      },
      {
        event_action: 'Editorial Playlist played',
        event_type: 'Playlist Play',
        event_value: playlist?._id || playlist?.slug,
      },
    );
    this.navigateCard.emit({ rowData: playlist });
  }

  onScrollEvent() {
    this.contentScroll.emit();
  }

  handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).style.display = 'none';
    this.cd.detectChanges();
  }

  setMoodTags() {
    this.playlists.forEach((pl) => {
      pl.moodTags = [
        pl.tags[3]?.replace(/-|mood/gi, ' '),
        pl.tags[4]?.replace(/-|mood/gi, ' '),
        pl.tags[5]?.replace(/-|mood/gi, ' '),
        pl.tags[6]?.replace(/-|mood/gi, ' '),
        pl.tags[7]?.replace(/-|mood/gi, ' '),
      ];
      pl.genereTagsFormatted = [
        pl.tags[0]?.replace(/-|genre/gi, ' '),
        pl.tags[1]?.replace(/-|genre/gi, ' '),
        pl.tags[2]?.replace(/-|genre/gi, ' '),
      ];
    });
  }
}
