<div
  class="fixed z-[10] overflow-hidden text-primary bottom-[50px] lg:bottom-[30px] p-[30px] lg:p-0 lg:right-[46px] w-full lg:w-[450px]"
>
  <div class="py-[30px] px-[40px] lg:px-[30px] bg-default font-normal rounded-[20px]">
    <h3 class="mb-[20px] text-[24px] lg:text-2xl font-medium">We respect your privacy</h3>
    <p class="mb-0 text-[16px] lg:text-base leading-[22.4px]">
      We use cookies to enhance your browsing experience, display advertisements or personalized
      content, and analyze our traffic. By clicking "Accept," you consent to our use of cookies.
    </p>
    <div class="flex my-[20px]">
      <app-btn styles="hover:!bg-transparent mr-[10px]" (click)="addCookieConsent(true)"
        >Accept</app-btn
      >
      <app-btn classGroup="transparent" (click)="addCookieConsent(false)">Only necessary</app-btn>
    </div>
    <a
      [routerLink]="'/cookies-policy'"
      class="text-[14px] lg:text-sm underline hover:text-primary focus:text-primary font-normal"
    >
      Read our full Cookie Policy
    </a>
  </div>
</div>
