import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrentUrlService {
  private currentUrl = signal<string>('/'); // Encapsulation of the signal

  // Method to get the current value
  getValue() {
    return this.currentUrl();
  }

  // Method to update the value
  setValue(newPage: string) {
    this.currentUrl.set(newPage);
  }

  // Method to access the signal directly (if you need to work with it in templates)
  get value() {
    return this.currentUrl;
  }
}
